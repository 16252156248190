import { useState, useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import BackgroundColor from "./StylingComponents/BackgroundColor";
import Color from "./StylingComponents/Color";
import Height from "./StylingComponents/Height";
import Width from "./StylingComponents/Width";
import Font from "./StylingComponents/Font";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";


const AnswerSliderOptionsWidget = (props) => {
  console.log("AnswerSliderOptionsWidget props", props);

  // const blockId = props?.blockId;
  // console.log("SectionWidget blockId", blockId);

  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  console.log("currentContentBlock", currentContentBlock);

  const currentBlock = contentBlocks?.find(block => block.contentType === "AnswerSliderOptions");
  const blockId = currentBlock?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log(currentBlockStyles);
  console.log("currentBlock", currentBlock);

  return (
    <div>

      <Color blockId={blockId} name="Options" />
      <Color blockId={blockId} propertyName="selectedOptionColor" name="Selected Option" />
      <BackgroundColor blockId={blockId} />
      <Height blockId={blockId} />
      <Width blockId={blockId} />
      <Font blockId={blockId} />
      <DynamicStylingTool/>
    </div>
  );
}

export default AnswerSliderOptionsWidget;

