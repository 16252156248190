import { useState, useEffect, useContext, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { Slider, LinearProgress, TextField, Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";

import BuilderContext from "../../store/BuilderContext";
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import AuthContext from '../../store/AuthContext';

const Question = props => {

  const builderCtx = useContext(BuilderContext);
  const authCtx    = useContext(AuthContext);

  const { updateContentText, currentContentBlock, updateContentBlocks, contentBlocks, changeCurrentPage, accessToolsById, setStyle } = builderCtx;

  console.log("Question props", props);
  console.log(props.question?.type);
  console.log('currentContentBlock',currentContentBlock)

  const { windowWidth }                     = useWindowDimensions();
  const [showSlider, setShowSlider]         = useState(false);
  const [progress, setProgress]             = useState(props?.currentValue);
  const [sliderWidth, setSliderWidth]       = useState(90);
  const [maxWidth, setMaxWidth]             = useState(props.question?.answers?.length);
  const [runTimeoutFunc, setRunTimeoutFunc] = useState(false);
  const [textAnswer, setTextAnswer]         = useState("");
  const [value, setValue]                   = useState(props?.question?.question);
  const [editorHtml, setEditorHtml]         = useState(props.question?.question);
  
  const editorRef                           = useRef(null);

  const answerSlider = contentBlocks?.find(block => block.contentType === "AnswerSlider");
  const answerSliderOptions = contentBlocks?.find(block => block.contentType === "AnswerSliderOptions");
  console.log("answerSlider", answerSlider);
  const answerSliderStyles = setStyle(answerSlider?.id);
  const answerSliderOptionsStyles = setStyle(answerSliderOptions?.id);
  
  const answerTextarea = contentBlocks?.find(block => block.contentType === "AnswerTextarea");
  const answerTextareaOptions = contentBlocks?.find(block => block.contentType === "AnswerTextareaOptions");
  
  const answerRadio = contentBlocks?.find(block => block.contentType === "AnswerRadio");
  const answerRadioOptions = contentBlocks?.find(block => block.contentType === "AnswerRadioOptions");
  // useEffect(() => {
  //   if (runTimeoutFunc) {
  //     const timer = setTimeout(() => {
  //       if (props.question.answer == 0 || !props.question.answer) {
  //         setShowSlider(false);
  //       }
  //     }, 500);
  //     return () => {
  //       setRunTimeoutFunc(false)
  //       clearTimeout(timer);
  //     }
  //   }
  // }, [props.question?.answer, runTimeoutFunc]);

  useEffect(() => {
    if ((props.question?.answer == 0 || !props.question?.answer) && !progress) {
      setShowSlider(false);
    }
    else if (props.question.answer > 0 || progress > 0) {
      setShowSlider(true);
    }
  }, [props.question?.answer, progress])

  useEffect(() => {
    if (props.question?.answer == 0) setProgress(0);
    else if (props.question?.answer > 0) setProgress(props.question.answer);
  }, [props.question?.answer])

  useEffect(() => {
    switch (props.question?.answers?.length) {
      case 2: setSliderWidth(46); break;
      case 3: setSliderWidth(56); break;
      case 4: setSliderWidth(80); break;
      case 5: setSliderWidth(85); break;
      case 6: setSliderWidth(90); break;
      default: break;
    }

    setMaxWidth(props.question?.answers?.length);


  }, [props, props.question?.question, props.question?.answer])


  // console.log("props.question.answer", props.question.answer);
  // console.log("progress", progress); console.log("showSlider", showSlider);


  const getLeft = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 20;
      case 6: return index * 16;
      default: return index * 20;
    }
  }

  const getLeftPoint = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 20;
      case 6: return index * 16;
      default: return index * 20;
    }
  }

  const getLeftRadio = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 6;
      case 6: return index * 4;
      default: return index * 20;
    }
  }

  const getRadioWidth = (answers) => {

    switch (answers) {
      case 2: return { width: "60%", marginLeft: "auto" };
      case 3: return { width: "100%", marginLeft: "auto" };
      // return { width: "70%" };
      case 5: return { width: "100%", 
        // marginLeft: "2.5%" 
      };
      case 6: return { width: "100%", marginLeft: "0%" };
      default: return { width: "100%" };
    }
    // if(answers === 3) { return {width: "70%", marginLeft: "7.5%"}; }
    // return {width: "50%"};
  }

  const radioOptionWidth = (answers) => {
    switch (answers) {
      case 5: return "20%";
      case 6: return "16%";
      default: return "20%";
    }
  }

  const updateSliderTextHighlight = (progress) => {
    console.log("progress", progress);
    console.log("desktop_text", props?.question?.answers?.find(ans => ans.value === progress)?.desktop_text);
    let activeSlider = null;
    let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktop_text;
    switch (sliderText?.trim()) {
      case "Strongly Agree":    activeSlider = 5; break;
      case "Agree":             activeSlider = 4; break;
      case "Neutral":           activeSlider = 3; break;
      case "Disagree":          activeSlider = 2; break;
      case "Strongly Disagree": activeSlider = 1; break;
      default:                  activeSlider = progress;
    }
    return activeSlider;
  }

  const updateStep = (progressArg) => {
    setShowSlider(true);
    setTimeout(() => { setProgress(progressArg) }, 0);
    // setRunTimeoutFunc(true);
  }

  const updateRadioStep = (progressArg) => {
    setTimeout(() => { setProgress(progressArg) }, 0);
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  const getLabelStyle = () => {
    if (windowWidth > 650) {
      if (props.question?.answers?.length === 5) {
        return { width: "100%",  };
      } else if (props.question?.answers?.length === 4) {
        return { width: "100%",  };
      } else if (props.question?.answers?.length === 3) {
        return { width: "100%",  };
      } else if (props.question?.answers?.length === 2) {
        return { width: "100%",  };
      } else {
        return { width: "150%",  };
      }
      // return { width: "14ch", marginLeft: "-7ch" };
    } else {

      if (props.question?.answers?.length === 6) {
        return { width: "9ch", marginLeft: "-4.5ch", wordBreak: "break-all" };
      }
      else return { width: "12ch", marginLeft: "-6ch" };
    }

  }

  const checkOpacity = (index) => {
    if (index === 0 && showSlider) return "0";
    else return "1";
  }

  const checkSelected = (value) => {
    if (progress === value) return "label active";
    else return "label";
  }

  const updateQuestion = (event, step) => {
    props.question.answerTxt = event.target.value;
    // setState({answerTxt: event.target.value});
    props.textAnswer(event.target.value);
    // return true;
  }

  const updateText = (type) => {
    if (editorRef.current) {
      let html = editorRef.current.getContent();
      let div = document.createElement("div");
      div.innerHTML = html;
      var text = div.textContent || div.innerText || "";
      let payload = {
        id: props?.blockId,
        html: html
      }
      console.log('question property payload',payload)
      console.log('PUT url',process.env.REACT_APP_API_URI + `/v3/admin/assessment/content/${type}`)
      fetch(process.env.REACT_APP_API_URI + `/v3/admin/assessment/content/${type}`, {
        method: 'PUT', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            console.log(type,"success",data)
          }
        }).catch((err) => {
          console.error("Failed to update "+type+" text...", err);
        })
    }
  };

  const handleUpdateTextBlur = () => {
    const camelCase = convertToCamelCase(props?.answerType)
    updateText(camelCase);
  };

  function convertToCamelCase(text) {
    switch(text) {
      case 'AnswerSlider' : return "answerSlider"
    }
  }
  

  return (
    <>
      <div className="question">
        {/* <Editor className="tiny-mce-editor"
          apiKey='hz2zqvulixa2qdtp85x6mth4a6m1zq6e7qmckxi7gof0gds4'
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={value}
          init={{
            setup: (editor) => {
              editor.on('blur', handleUpdateTextBlur);
            },
            menubar: false,
            inline: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime'
            ],
            toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify',
            toolbar_mode: "scrolling",
            content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; }'
          }}
        /> */}
        <p onClick={(e) => accessToolsById(e, props.dataId)} style={setStyle(props.dataId)}>{value}</p>
      </div>

      {
        (() => {
          switch (props.question?.type) {

            case "textarea":
              return <>
                <div className="textarea-field" style={setStyle(answerTextarea?.id)} >
                  <TextField hiddenLabel multiline rows={windowWidth > 550 ? 4 : 6} placeholder="Please type your response here" value={textAnswer} onChange={(e) => setTextAnswer(e.target.value)} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text"  style={setStyle(answerTextareaOptions?.id)}
                  sx={{ "& .MuiInputBase-root": { fontSize: answerTextareaOptions?.styling?.fontSize, color: answerTextareaOptions?.styling?.color } }}
                  />
                </div>
              </>

            case "radio":
              return <>
                <div className="radio-container" style={setStyle(answerRadio?.id)} >
                  <div className="slider-labels" style={{ ...getRadioWidth(props.question?.answers?.length), fontSize: answerRadioOptions?.styling?.fontSize, fontWeight: answerRadioOptions?.styling?.fontWeight }}>
                    {props.question?.answers?.map((data, index) => (
                      <div className={checkSelected(data.value)} key={`labels-${index}`} style={{ 
                        // left: getLeftRadio(index) + '%',
                        width: radioOptionWidth(props?.question?.answers?.length) }} onClick={() => {
                        console.log("data.value", data.value);
                        updateRadioStep(data.value);
                      }}>
                        {/* <div className={checkSelected(data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                        {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                        {/* <Radio checked={data.value === progress} value={progress} /> */}
                        <Radio checked={data.value === progress} value={progress} sx={{
                          "&.MuiRadio-root.Mui-checked": { color: answerRadioOptions?.properties?.selectedRadioColor }
                        }} />
                        <p className="desktop" style={{ ...getLabelStyle(), color: checkSelected(data.value) === "label active" ? answerRadioOptions?.properties?.selectedOptionColor : answerRadioOptions?.styling?.color }}>{data.desktop_text}</p>
                        <p className="mobile" style={{ ...getLabelStyle(), color: checkSelected(data.value) === "label active" ? answerRadioOptions?.properties?.selectedOptionColor : answerRadioOptions?.styling?.color }}>{data.desktop_text}</p>
                      </div>
                    ))}

                  </div>
                </div>
              </>

            default:
              return <>
                <div className="slider-container" 
                onClick={(e) => accessToolsById(e, answerSlider?.id)} 
                // style={setStyle(answerSlider?.id)} 
                style={{ ...answerSliderStyles, width: sliderWidth + '%' }}
                >
                <div className="slider">
                  {/* {showSlider ? <Slider className="custom-slider"
                    key={props.question?.id} valueLabelDisplay="off"
                    step={1} min={1} max={maxWidth}
                    sx={{ 
                      "& .MuiSlider-track": { backgroundColor: `${answerSlider?.properties?.sliderColor} !important` },
                      "& .MuiSlider-thumb": { backgroundColor: `${answerSlider?.properties?.sliderColor} !important` },
                      "& .MuiSlider-thumb:before": { backgroundColor: `${answerSlider?.properties?.sliderColor} !important` },
                  }}
                    // value={updateSliderTextHighlight(progress)}
                    value={progress}
                  /> : <div className="slider-none-adjust"></div>} */}
                  <div className="slider-none-adjust"></div>
                  <div className="slider-points">
                    {props.question?.answers?.map((data, index) => (
                      // <a className="point" key={`points-${index}`} style={{ left: getLeftPoint(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value)}></a>
                      <a className="point" key={`points-${index}`} style={{ left: getLeftPoint(index) + '%'}}></a>
                    ))}
                  </div>
                  <LinearProgress className="custom-bar" variant="determinate" value={0} style={{ backgroundColor: answerSlider?.properties?.sliderBackgroundColor }} />

                </div>
                <div className="slider-labels" onClick={(e) => accessToolsById(e, answerSliderOptions?.id)} 
                  // style={{ ...answerSliderOptionsStyles, border: "none" }} >
                  style={{ border: "none" }} >
                  {props.question?.answers?.map((data, index) => {
                    return (<div className={checkSelected(data.value)} key={`labels-${index}`} style={{ left: (getLeft(index) + '%'), color: checkSelected(data.value) === "label active" && answerSliderOptions?.properties?.selectedOptionColor }} onClick={() => updateStep(data.value)}>
                      <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktop_text}}></p>
                      <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobile_text}} ></p>
                    </div>
                    )
                  })}
                </div>
                </div>
              </>
          }
        }).call(this)
      }
    </>
  );
}

export default Question;
