import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const AlignItems = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  }

  const currentBlockStyles = getCurrentBlockStyles()
  const currentAlignItems = currentBlockStyles?.alignItems;

  const [alignItems, setAlignItems] = useState(currentAlignItems || "flex-start");
  const [onChangeFlag, setOnChangeFlag] = useState(false);

  const alignItemsOptions = [
    { value: 'flex-start', label: 'Flex Start' },
    { value: 'flex-end', label: 'Flex End' },
    { value: 'center', label: 'Center' },
    { value: 'baseline', label: 'Baseline' },
    { value: 'stretch', label: 'Stretch' },
  ];

  const handleAlignItemsChange = (e) => {
    setOnChangeFlag(true);
    setAlignItems(e.target.value);
  };

  const updateAlignItems = (alignItemsValue) => {
    const payload = {
      id: blockId,
      value: alignItemsValue,
      screenType: props?.screenType
    };

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/align-items', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating align items payload", payload);
        }
      }).catch((err) => {
        console.error("Failed to update align items...", err);
      });
  };

  useEffect(() => {
    if (onChangeFlag) {
      updateAlignItems(alignItems);
    }
  }, [alignItems, onChangeFlag]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (alignItems !== currentAlignItems) {
        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    alignItems: alignItems
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    alignItems: alignItems
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    alignItems: alignItems
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    alignItems: alignItems
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }
      }
      setOnChangeFlag(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [blockId, alignItems, currentAlignItems]);

  return (
    <div className="align-items tool">
      {props.deleteModal}
      <TextField
        className="align-items-select styling-select"
        select
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={alignItems}
        onChange={handleAlignItemsChange}
      >
        {alignItemsOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default AlignItems;
