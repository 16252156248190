import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const Height = (props) => {

  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx    = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;

  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  } 

  const currentBlockStyles = getCurrentBlockStyles()
  const currentHeight = currentBlockStyles?.height?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  // console.log("currentHeight", currentHeight);

  console.log('props from height',props)

  const units = [
    { value: 'px',    label: 'px'   },
    { value: 'rem',   label: 'rem'  },
    { value: '%',     label: '%'    },
    { value: 'vh',    label: 'vh'   },
    { value: 'vw',    label: 'vw'   },
  ];

  const [height, setHeight]             = useState(currentHeight?.[0] || "auto");
  const [unit, setUnit]                 = useState(currentHeight?.[1] || "px");
  const [finalValue, setFinalValue]     = useState("");
  
  const [onChnageFlag, setOnChnageFlag] = useState(false)

  if(height !== "auto") localStorage.setItem("prevHeight", height);
  // console.log("localStorage prevHeight", localStorage.getItem("prevHeight"));
  
  const handleUnitChange = (e) => {
    // console.log("unit change event", e.target.value);
    
    setOnChnageFlag(true)

    if (!e.target.value) setHeight("auto");
    else {
      setUnit(e.target.value);
      if(localStorage.getItem("prevHeight")) {
        setHeight(localStorage.getItem("prevHeight"));
      }
    }
  };


  const updateHeight = (heightValue,unit) => {
    let payload = {
      id: blockId,
      value: heightValue != 'auto' ? heightValue+unit : heightValue,
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/height', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating height payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update height...", err);
    });
  }

  useEffect(() => {
    if (height === "auto") {
      setFinalValue("auto");
    } else if (height && height !== "auto" && unit) {
      if (height !== currentHeight?.[0] || unit !== currentHeight?.[1]) {
        setFinalValue(`${height}${unit}`);
      }
    }    
  }, [ currentHeight, height, unit]);

  useEffect(() => {
    let lastFinalVal = "";
    if(!currentHeight?.[1]) lastFinalVal = "auto";
    else lastFinalVal = currentHeight?.[0] + currentHeight?.[1];
    // console.log("lastFinalVal", lastFinalVal);
    // console.log("height unit", height, unit);

    if ( onChnageFlag ) {
      console.log('inside height')
      updateHeight(height,unit) 
    }

    const timer = setTimeout(() => {
      if (finalValue && finalValue !== lastFinalVal) {
        // updateContentBlocks(contentBlocks?.map(block => {
        //   if (block.id === blockId) {
        //     let newStyles = { ...block.styling };
        //     newStyles.height = finalValue;
        //     block.styling = { ...newStyles };
        //     return block;
        //   } else return block;
        // }))
        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    height: finalValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    height: finalValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    height: finalValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    height: finalValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }
      }

      setOnChnageFlag(false);
    }, 500);
    
    
    return () => clearTimeout(timer);

  }, [blockId, finalValue]);


  // console.log("blockId", blockId);
  // console.log("height", height);
  // console.log("type height", typeof height);
  // console.log("unit", unit);
  // console.log("finalValue", finalValue);

  // let testVal = "32px 16% 8vh 4rem 2vw";
  // console.log("REGEX:\n", testVal.match(/[a-zA-Z]+|[0-9]+|[%]/g));

  return (
    <div className="height tool">
      {props.deleteModal}
      <TextField id="height-number" className="height-input styling-input" type="number" variant="filled"
        InputLabelProps={{ shrink: true, }} placeholder={height || ""} value={height} onChange={(e) => {
          if (e.target.value) setHeight(e.target.value);
          else setHeight("auto");
          setOnChnageFlag(true) 
        }} />

      {/* <br /> */}
      <TextField className="height-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }} 
      placeholder={unit} value={unit} onChange={(e) => {
        console.log("targetVal", e.target.value);
        handleUnitChange(e);
      }}
      // label="Select unit" helperText="Select unit"
      >
        {units?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>
  )
}


export default Height;
