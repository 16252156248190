import { useState, useEffect, useContext } from "react";

import { Tabs, Tab, Box, Button } from '@mui/material';

import AuthContext from '../../../store/AuthContext';
import BuilderContext from '../../../store/BuilderContext';

import AddImage from "./AddImage/AddImage";
import BackgroundColor from "./StylingComponents/BackgroundColor";
import Margin from "./StylingComponents/Margin";
import Padding from "./StylingComponents/Padding";
import Width from "./StylingComponents/Width";
import Height from "./StylingComponents/Height";
import MaxWidth from "./StylingComponents/MaxWidth";
import Position from "./StylingComponents/Position";
import BackgroundRepeat from "./StylingComponents/BackgroundRepeat";
import BackgroundPosition from "./StylingComponents/BackgroundPosition";
import BackgroundSize from "./StylingComponents/BackgroundSize";
import UploadVideo from "./AddVideo/UploadVideo";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";



function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, margin: "auto" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ImageTab = (props) => {

  const authCtx = useContext(AuthContext);
  const { currentContentBlock, contentBlocks, startPageImageURL, setLastUploadedMedia } = useContext(BuilderContext);
  const startPageImgVideoBlockID = contentBlocks?.find(block => block?.contentType === "StartPageImgVideo")?.id;

  const updateImage = (blockId) => {

    let payload = { id: blockId, image: startPageImageURL, }
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/content/image', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => {
      if(data.status === "success") {
        console.log("updated image", data); 
      }
    }).catch((err) => {
      console.error("Failed to update image...", err);
    });
  }

  return <>
    {/* <AddImage blockId={currentContentBlock?.id} background={true} /> */}
    <AddImage blockId={startPageImgVideoBlockID} background={true} />
    {startPageImageURL && <div style={{ maxWidth: "310px", margin: "0 auto", textAlign: "center" }}>
      <p>Last Uploaded</p>
      <img src={startPageImageURL} width="310px" height="auto" alt="" />
      <Button className="black-btn" onClick={() => {
        setLastUploadedMedia({ type: "image", url: startPageImageURL });
        updateImage(currentContentBlock.id);
      }}>Use this image</Button>
    </div>}
    {/* <BackgroundRepeat blockId={startPageImgVideoBlockID} />
    <BackgroundPosition blockId={startPageImgVideoBlockID} />
    <BackgroundSize blockId={startPageImgVideoBlockID} />
    <BackgroundColor blockId={startPageImgVideoBlockID} /> */}
    <DynamicStylingTool/>
  </>
}

const VideoTab = (props) => {

  
  const authCtx = useContext(AuthContext);
  const builderCtx  = useContext(BuilderContext);
  const { currentContentBlock, startPageVideoURL, setStartPageVideoURL, setLastUploadedMedia, updateContentBlocks } = builderCtx;

  useEffect(() => {
    const timer = setTimeout(() => {
      updateContentBlocks((prevBlocks) => prevBlocks?.map(block => {
        if (block.id === currentContentBlock.id) {
          console.log(block);
          if (startPageVideoURL?.length) block.properties.video = startPageVideoURL;
          return block
        } else return block;
      }))
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  console.log("startPageVideoURL", startPageVideoURL);
  const updateVideo = (blockId) => {

    let payload = { id: blockId, video: startPageVideoURL, }
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/content/video', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => {
      if(data.status === "success") {
        console.log("updated video", data); 
      }
    }).catch((err) => {
      console.error("Failed to update video...", err);
    });
  }

  console.log(startPageVideoURL);

  return <>
    <UploadVideo blockId={currentContentBlock.id} />
    {startPageVideoURL && <div style={{ maxWidth: "330px", margin: "0 auto", textAlign: "center" }}>
      <p>Last Uploaded</p>
      <video autoPlay={false} controls style={{ maxWidth: "330px", margin: "0 auto" }}><source src={startPageVideoURL} type="video/mp4" /></video>
      <Button className="black-btn" onClick={() => { 
        setLastUploadedMedia({ type: "video", url: startPageVideoURL }); 
        updateVideo(currentContentBlock.id);
      }}>Use this video</Button>
    </div>}
    {/* <BackgroundPosition blockId={currentContentBlock.id} />
    <BackgroundColor blockId={currentContentBlock.id} /> */}
    <DynamicStylingTool/>
  </>
}

const PositioningTab = (props) => {

  const { currentContentBlock } = useContext(BuilderContext);

  return <>
    {/* <Position blockId={currentContentBlock.id} />
    <Margin blockId={currentContentBlock.id} />
    <Padding blockId={currentContentBlock.id} />
    <Width blockId={currentContentBlock.id} /> */}
    {/* <Height blockId={currentContentBlock.id} /> */}
    {/* <MaxWidth blockId={currentContentBlock.id} /> */}
    <DynamicStylingTool/>
  </>;
}


const StartPageImgVideoWidget = () => {

  const builderCtx = useContext(BuilderContext);
  const { currentContentBlock, updateContentBlocks, contentBlocks, setCurrentObjStyles } = builderCtx;
  const { styling } = currentContentBlock;

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  console.log("currentContentBlock", currentContentBlock);


  return (
    <div>
      <>
        {/* onChange={(e) => props?.setCurrentProperty(e, "paddingLeft", builderCtx?.currentContentBlock.id)}  */}
      </>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="image tabs" variant="fullWidth" indicatorColor="none">
            <Tab label="IMAGE" {...a11yProps(0)} />
            <Tab label="VIDEO" {...a11yProps(1)} />
            <Tab label="POSITIONING" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <ImageTab />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <VideoTab />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <PositioningTab />
        </TabPanel>
      </Box>

    </div>
  )
}



export default StartPageImgVideoWidget;


