import React, { useContext, useState } from 'react';
// import Dropzone from 'react-dropzone';
import axios from 'axios';

import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from '../../../../store/AuthContext';

import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { Box, LinearProgress, Typography } from '@mui/material';

function Dropzone({ onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: { 'video/*': ['.mp4', '.mkv', '.mov', '.webm', '.avi', '.wmv', '.flv']},
      onDrop,
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="d-n-d">
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          {isDragActive ? (
            <p className="dropzone-content">
              Release to drop the files here
            </p>
          ) : (
            <div className="dropzone-content">
              <p>Upload Video</p>
              <p>Drag and drop or click to browse</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const UploadVideo = (props) => {

  const { id } = useParams();
  const authCtx = useContext(AuthContext);
  const builderCtx = useContext(BuilderContext);
  const { currentContentBlock } = builderCtx;

  // console.log("UploadVideo props", props);
  

  const [progress, setProgress] = useState(0);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [errorText, setErrorText] = useState("");
  

  const showProgressOrError = (progress) => {
    if (!errorText) return `${progress}% uploaded`;
    else return errorText;
  }

  const handleFileDrop = (acceptedFiles) => {
    setErrorText("");
    const file = acceptedFiles[0];
    console.log("file", file);

    const videoFormData = new FormData();
    videoFormData.append('video', file);
    setProgress(0);

    // const requestData = {
    //   id: props?.blockId,
    //   properties: {
    //     // text: 'test header',
    //     // html: '<h1>Hello World</h1>',
    //     video: file,
    //   },
    // };

    const widget = currentContentBlock?.contentType;

    // axios.put(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/content/' + widget, requestData, {
    axios.put(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/updateVideo', videoFormData, {
      headers: { 
        Authorization: `Bearer ${authCtx.token}`, 
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        console.log("Progress Event:", progressEvent);
        const percentCompleted = Math.round((loaded / total) * 100);
        console.log("percentCompleted:", percentCompleted);
        setProgress(percentCompleted);
      },
    })
    .then((response) => {
      // if (response.status === "success")
      console.log("response", response);
      const uploadedVideoUrl = response.data.videoPath;
      setUploadedVideo(uploadedVideoUrl);
      builderCtx.setStartPageVideoURL(uploadedVideoUrl);
      builderCtx.setLastUploadedMedia({ type: "video", url: uploadedVideoUrl });
    })
    .catch((error) => {
      console.log('Failed to upload video...', error);
      setErrorText("Failed to upload video. Check your internet connection or refresh and try again...");
    });

  };

  return (
    <div className='crop-container' style={{ width: "auto", maxWidth: "270px", height: "320px", margin: "0 auto" }} >
      {/* <Dropzone onDrop={handleFileDrop} accept="video/*"
        maxSize={50 * 1024 * 1024} // max 50MB video file size limit 
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps({ accept: 'video/mp4' })} />
            <div className="text-center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
              {isDragActive ? (
                <p className="dropzone-content">
                  Release to drop the files here
                </p>
              ) : (
                <div className="dropzone-content">
                  <p>Upload Image</p>
                  <p>Drag and drop or click to browse</p>
                </div>
              )}
            </div>
            <p>Drag and drop video files here, or click to select files.</p>
          </div>
        )}
      </Dropzone> */}
      <div className="easy-crop">
        <Dropzone onDrop={handleFileDrop} 
          maxSize={50 * 1024 * 1024} // max 50MB video file size limit 
        />
      </div>
      <div className="progress-bar dropzone-content">
        <div className="progress" style={{ width: `${progress}%` }} />
      </div>
      {(progress > 0) && <p style={{ fontWeight: 700, color: "#212121" }}>{showProgressOrError(progress)}</p>}
      {((progress > 0) && (progress < 100)) && <LinearProgressWithLabel value={progress} style={{ width: "250px" }} />}
      {/* {uploadedVideo && (
        <video controls style={{ maxWidth: "270px", height: "auto", margin: "15px auto 15px" }}>
          <source src={uploadedVideo} type="video/mp4" />
        </video>
      )} */}
    </div>
  );
};

export default UploadVideo;

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value,)}%`}</Typography>
      </Box> */}
    </Box>
  );
}


{/* <div className="d-n-d">
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} {...getRootProps({ className: "dropzone" })}>
    <input className="input-zone" {...getInputProps()} />
    <div className="text-center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      {isDragActive ? (
        <p className="dropzone-content">
          Release to drop the files here
        </p>
      ) : (
        <div className="dropzone-content">
          <p>Upload Image</p>
          <p>Drag and drop or click to browse</p>
        </div>
      )}
    </div>
  </div>
</div> */}