import React, { useState, useEffect, useContext, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import BuilderContext from "../../../store/BuilderContext";
import AuthContext from "../../../store/AuthContext";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

import BoldIcon from "../../../assets/img/bold.svg";
import ItalicIcon from "../../../assets/img/italic1.svg";
import Undo from '@mui/icons-material/Undo';
import Redo from '@mui/icons-material/Redo';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

const HeadingWidget = (props) => {
  const blockId = props?.blockId;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);

  const [toolsVisible, setToolsVisible] = useState(false);
  const [color, setColor] = useState("#000000");
  var clickedOnToolbar = false

  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const currentBlock = contentBlocks?.find(block => block.id === blockId);
  const currentBlockStyles = currentBlock?.styling;

  const toolbarRef = useRef(null);
  const editorRef = useRef(null);
  const [value, setValue] = useState(currentBlock?.properties?.html);
  const [editorHtml, setEditorHtml] = useState(currentBlock?.properties?.html);

  useEffect(() => {
    if (!value && currentBlock?.properties?.html) setValue(currentBlock?.properties?.html);
    if (!editorHtml && currentBlock?.properties?.html) setEditorHtml(currentBlock?.properties?.html);
  }, [currentBlock?.properties?.html, currentBlock?.properties?.text, editorHtml, value]);

  // useEffect(()=>{
  //   console.log("props",props)
  // },[props])

  useEffect(() => {
    const timer = setTimeout(() => {
      updateContentBlocks((prevBlocks) => prevBlocks?.map(block => {
        if (block.id === blockId) {
          block.properties.html = editorHtml;
          block.properties.text = value;
        }
        return block;
      }))
    }, 1000);

    updateText()
    return () => clearTimeout(timer);
  }, [blockId, editorHtml, value]);

  const updateText = () => {
    if (editorRef.current) {
      let html = editorRef.current.innerHTML;
      let div = document.createElement("div");
      div.innerHTML = html;
      var text = div.textContent || div.innerText || "";
      let payload = {
        id: blockId,
        text: text,
        html: html
      }
      console.log('heading widget payload',payload)
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/content/heading', {
        method: 'PUT', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            console.log("success",data)
          }
        }).catch((err) => {
          console.error("Failed to update heading text...", err);
        })
    }
  };

  
  const getToolbarStyle = () => {
    var style = { display:'flex' };
    console.log(toolbarRef.current.getBoundingClientRect().top);
    if(toolbarRef.current.getBoundingClientRect().top < 0) {
      style["bottom"] = '-40px';
    }
    else{
      style["top"] = '-40px';
    }
    if(toolbarRef.current.getBoundingClientRect().right > window.innerWidth){
      style["right"] = '0px'
    }
    return style
  }

  const handleUpdateTextBlur = () => {
    setTimeout(() => {
      if(!clickedOnToolbar){
        setToolsVisible(false)
        updateText()
      }else{
        clickedOnToolbar = false;
      }
    }, 200);
  };

  const handleSelectionChange = () => {
    setToolsVisible(true);
  };

  const formatText = (command, value=null) => {
    if (command === 'foreColor' && value) {
      setColor(value)
      document.execCommand(command, false, value);
    } else if( command === 'undo' || command === 'redo') {
      document.execCommand(command)
    } else {
      document.execCommand('styleWithCSS', null, true);
      document.execCommand(command, false, null);
    }
  };

  if (currentBlock?.properties?.text) {
    return (
      <>
      <div className="custom-text-editor" onBlur={handleUpdateTextBlur}>
        <div className="custom-editor-tools" ref={toolbarRef} style={toolsVisible ? getToolbarStyle() : { display: "none" }} onClick={() => {clickedOnToolbar = true}}>
          <Undo onClick={() => formatText("undo")} />
          <Redo onClick={() => formatText("redo")} />
          <img src={BoldIcon} onClick={() => formatText("bold")} />
          <img src={ItalicIcon} onClick={() => formatText("italic")} />
          <input type="color" id="favcolor" name="favcolor" value={color} onChange={(e) => formatText("foreColor", e.target.value)} style={{ marginLeft: "10px" }} />
          <FormatAlignLeftIcon onClick={() => formatText("justifyLeft")} />
          <FormatAlignCenterIcon onClick={() => formatText("justifyCenter")} />
          <FormatAlignRightIcon onClick={() => formatText("justifyRight")} />
          <FormatAlignJustifyIcon onClick={() => formatText("justifyFull")} />
        </div>
        <div contentEditable="true" onSelect={handleSelectionChange}  suppressContentEditableWarning="true" ref={editorRef} dangerouslySetInnerHTML={{ __html: value }} />
      </div>
    </>
    )
  }

  return null;
}

export default HeadingWidget;
