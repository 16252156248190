import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import ContentEditable from "react-contenteditable";

import { Button } from '@mui/material';
import { Add } from '@material-ui/icons';

import BuilderContext from '../../store/BuilderContext';
import { TextField } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AuthContext from "../../store/AuthContext";


function DialogSelect(props) {
  const { fields, setFields } = props;

  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 420,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 400,
    },
  }));

  const classes = useStyles();
  const authCtx = useContext(AuthContext);
  const [open, setOpen]                 = useState(false);
  const [fieldLabel, setFieldLabel]     = useState("");
  const [placeholder, setPlaceholder]   = useState("");
  const [fieldType, setFieldType]       = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [width, setWidth]               = useState("");
  const [acFieldID, setAcFieldID]       = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFields(prev => [...prev, {label: fieldLabel, placeholder: placeholder, fieldType: fieldType, value: defaultValue, acFieldID: acFieldID, }]);

    // change this endpoint later
    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/newField', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    //   // body: JSON.stringify({users: allUsersDetails}),
    // })
    // .then((response) => response.json())
    // .then((data) => {
    //   console.log(data);
    //   if (data.status === "success") {
    //     console.log("successfully added new field...");
    //   }
    // })
    // .catch((err) => console.error("failed to add new field...", err));
  }

  return (
    <div>
      <Button onClick={handleClickOpen} className="add-input-field-button">+ NEW FIELD</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Please enter details of the new field.</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField required fullWidth label="Field Label" variant="outlined" value={fieldLabel} onChange={(e) => setFieldLabel(e.target.value || '')} />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField required fullWidth label="Placeholder" variant="outlined" value={placeholder} onChange={(e) => setPlaceholder(e.target.value || '')} />
            </FormControl>
           
            <FormControl className={classes.formControl}>
              <TextField fullWidth select value={fieldType} onChange={(e) => setFieldType(e.target.value)} placeholder="Field Type" label="Field Type" variant="outlined" required>
                <MenuItem className="section-name" value='text field'>Text Field</MenuItem>
                <MenuItem className="section-name" value='drop down selection'>Drop Down Selection</MenuItem>
                <MenuItem className="section-name" value='drop down selection with search'>Drop Down Selection With Search</MenuItem>
                <MenuItem className="section-name" value='toggle button'>Toggle Button</MenuItem>
                <MenuItem className="section-name" value='radio button'>Radio Button</MenuItem>
              </TextField>
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField fullWidth label="Default Value" value={defaultValue} onChange={(e) => setDefaultValue(e.target.value)} variant="outlined"  />
            </FormControl>

            {/* <FormControl className={classes.formControl}>
              <TextField fullWidth select value={width} onChange={(e) => setWidth(e.target.value)} placeholder="Width" label="Width" variant="outlined" required >
                <MenuItem className="section-name" value='100'>Fullwidth</MenuItem>
                <MenuItem className="section-name" value='75'>75%</MenuItem>
                <MenuItem className="section-name" value='66'>66%</MenuItem>
                <MenuItem className="section-name" value='50'>50%</MenuItem>
                <MenuItem className="section-name" value='33'>33%</MenuItem>
                <MenuItem className="section-name" value='25'>25%</MenuItem>
              </TextField>
            </FormControl> */}

            <FormControl className={classes.formControl}>
              <TextField fullWidth label="Active Campaign Field ID" placeholder="Active Campaign Field ID" value={acFieldID} onChange={(e) => setAcFieldID(e.target.value)} variant="outlined" required />
            </FormControl>

          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary"> Cancel </Button>
          <Button onClick={(e) => handleSubmit(e)} color="primary" disabled={!fieldLabel || !placeholder || !acFieldID}> Add </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Details = () => {


  const { id } = useParams();
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);

  const { contentBlocks, updateContentBlocks, accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, changeCurrentPage } = builderCtx;

  // const [selectedContentBlockId, setSelectedContentBlockId] = useState(0);

  // const accessToolsById = (e, id) => {
  //   e.stopPropagation();
  //   let contentBlock = builderCtx?.contentBlocks?.find(obj => { return obj.id == id});
  //   setSelectedContentBlockId(contentBlock?.id);
  //   builderCtx?.updateCurrentBlock(contentBlock);
  // }

  // const setStyle = (id) => {
  //   let contentBlock = builderCtx?.contentBlocks?.find(obj => { return obj.id == id});
  //   if(contentBlock && contentBlock.id == selectedContentBlockId) {
  //     let styling = {...contentBlock.styling, border: "solid 1px #7FB6BE"};
  //     return styling;
  //   } else if(contentBlock && contentBlock.styling) {
  //     return contentBlock.styling;
  //   }
  //   return null;
  // }

  // const updateContentText = (e, id) => {
  //   builderCtx.updateContentBlocks(builderCtx.contentBlocks?.map(block => {
  //     if(block.id == id) {
  //       block.properties.text = e.target.value;
  //       return block;
  //     }
  //     return block;
  //   }))
  // }


  // GET v2/assessments/:id/fields 


  const [name, setName]               = useState("");
  const [fieldsArray, setFieldsArray] = useState([
    { order: 1, label: 'Full Name', placeholder: 'Full Name', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 1, label: 'First Name', placeholder: 'First Name', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 2, label: 'Last Name', placeholder: 'Last Name', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 3, label: 'Email', placeholder: 'name@email.com', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50" },
    { order: 4, label: 'Organization', placeholder: 'Organization Name', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 5, label: 'Job Title', placeholder: 'Job Title', value: '', fieldType: 'drop down selection with search', acFieldID: '', showForMembers: true, width: "50", },
    { order: 6, label: 'Country', placeholder: 'United States', value: '', fieldType: 'drop down selection with search', acFieldID: '', showForMembers: true, width: "50", },
    { order: 7, label: 'State', placeholder: 'State', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 8, label: 'ZIP Code', placeholder: '10100', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
  ]);
  
  const [selectedFields, setSelectedFields] = useState([
    { order: 1, label: 'First Name', placeholder: 'First Name', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 2, label: 'Last Name', placeholder: 'Last Name', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 3, label: 'Email', placeholder: 'name@email.com', value: '', fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
  ]);

  useEffect(() => {
    changeCurrentPage("details");
  }, []);

  console.log(fieldsArray);

  return <div className="details">
    <div className="assessment-builder-details-container">
      <h1 className="center bold">Please confirm your details so that we can email you a copy.</h1>
      <div className="details-form">
        {fieldsArray.map(field => {
            return <div className="input-field">
              <p className="input-title">{field.label}</p>
              <TextField hiddenLabel placeholder={field.placeholder} value={field.value} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            </div>
        })}
        <div className="add-input-field">
          <DialogSelect fields={fieldsArray} setFields={setFieldsArray} />
        </div>
        
      </div>
      <Button className="submit-button">Show My Results</Button>
    </div>

  </div>;

}



export default Details;

