import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const FontWeight = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  }

  const currentBlockStyles = getCurrentBlockStyles()
  const currentFontWeight = currentBlockStyles?.fontWeight;

  const [fontWeight, setFontWeight] = useState(currentFontWeight || "400");
  const [onChangeFlag, setOnChangeFlag] = useState(false);

  const fontWeightOptions = [
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '300', label: '300' },
    { value: '400', label: '400' },
    { value: '500', label: '500' },
    { value: '600', label: '600' },
    { value: '700', label: '700' },
    { value: '800', label: '800' },
    { value: '900', label: '900' },
  ];

  const handleFontWeightChange = (e) => {
    setOnChangeFlag(true);
    setFontWeight(e.target.value);
  };

  const updateFontWeight = (fontWeightValue) => {
    const payload = {
      id: blockId,
      value: fontWeightValue,
      screenType: props?.screenType
    };

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/fontWeight', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating font weight payload", payload);
        }
      }).catch((err) => {
        console.error("Failed to update font weight...", err);
      });
  };

  useEffect(() => {
    if (onChangeFlag) {
      updateFontWeight(fontWeight);
    }
  }, [fontWeight, onChangeFlag]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (fontWeight !== currentFontWeight) {
        // updateContentBlocks(contentBlocks?.map(block => {
        //   if (block.id === blockId) {
        //     return {
        //       ...block,
        //       styling: {
        //         ...block.styling,
        //         fontWeight: fontWeight
        //       }
        //     };
        //   }
        //   return block;
        // }));
        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    fontWeight: fontWeight
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    fontWeight: fontWeight
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    fontWeight: fontWeight
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    fontWeight: fontWeight
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }
      }
      setOnChangeFlag(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [blockId, fontWeight, currentFontWeight]);

  return (
    <div className="font-weight tool">
      {props.deleteModal}
      <TextField
        className="font-weight-select styling-select"
        select
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={fontWeight}
        onChange={handleFontWeightChange}
      >
        {fontWeightOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default FontWeight;
