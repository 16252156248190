import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import { ChromePicker } from "react-color";

const Border = (props) => {

  const { blockId, propertyName, name = "", screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch (screenType) {
      case "mobile":
        return currentContentBlock?.mobileStyling;
        break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
        break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
        break;
      default:
        return currentContentBlock?.styling;
        break;
    }
  }

  const [currentBlockStyles, setCurrentBlockStyles] = useState(getCurrentBlockStyles())
  const currentBorderWidth = currentBlockStyles?.borderWidth || "0px";
  const currentBorderStyle = currentBlockStyles?.borderStyle || "solid";
  const currentBorderColor = currentBlockStyles?.borderColor || "#000000";

  const [borderWidth, setBorderWidth] = useState();
  const [borderStyle, setBorderStyle] = useState();
  const [borderColor, setBorderColor] = useState();
  const [colorHex, setColorHex] = useState(currentBlockStyles?.borderColor || "#000000");

  const [pickerOpen, setPickerOpen] = useState(false);

  const handleWidthChange = (e) => setBorderWidth(e.target.value + "px");
  const handleStyleChange = (e) => setBorderStyle(e.target.value);
  const handleColorChange = (e) => setColorHex(e.target.value);

  const handleChangeForColorPicker = (color) => {
    // console.log("color", color);
    setColorHex(color.hex);
    // setBorderColor(color.hex);
  }

  useEffect(() => {

    const timer = setTimeout(() => {
      if (colorHex !== borderColor) {
        setBorderColor(colorHex);
        // setPickerOpen(false);
      }
    }, 2000);
    return () => clearTimeout(timer);

  }, [colorHex, borderColor]);

  useEffect(() => {
    let lastFinalVal = "";
    if (!currentBorderColor) lastFinalVal = "#000000";
    else lastFinalVal = currentBorderColor;
    // console.log("lastFinalVal", lastFinalVal);
    if (borderColor && borderColor !== lastFinalVal) {
      switch (screenType) {
        case "mobile":
          updateContentBlocks(contentBlocks?.map(block => {
            if (block.id === blockId) {
              return {
                ...block,
                mobileStyling: {
                  ...block.mobileStyling,
                  borderWidth, borderStyle, borderColor
                }
              };
            }
            return block;
          }));
          break;
        case "horizontal":
          updateContentBlocks(contentBlocks?.map(block => {
            if (block.id === blockId) {
              return {
                ...block,
                horizontalStyling: {
                  ...block.horizontalStyling,
                  borderWidth, borderStyle, borderColor
                }
              };
            }
            return block;
          }));
          break;
        case "vertical":
          updateContentBlocks(contentBlocks?.map(block => {
            if (block.id === blockId) {
              return {
                ...block,
                verticalStyling: {
                  ...block.verticalStyling,
                  borderWidth, borderStyle, borderColor
                }
              };
            }
            return block;
          }));
          break;
        default:
          updateContentBlocks(contentBlocks?.map(block => {
            if (block.id === blockId) {
              return {
                ...block,
                styling: {
                  ...block.styling,
                  borderWidth, borderStyle, borderColor
                }
              };
            }
            return block;
          }));
          break;
      }
    }
  }, [blockId, borderColor, colorHex, currentBorderColor, borderWidth, borderStyle]);

  useEffect(() => {
    setCurrentBlockStyles(getCurrentBlockStyles())
    // console.log('currentContentBlock',currentContentBlock)

  }, [currentContentBlock])

  useEffect(() => {
    const borderPropertiesArray = currentBlockStyles?.border?.split(" ");

    if (borderPropertiesArray) {
      console.log('borderPropertiesArray', borderPropertiesArray);
      // console.log('currentBlockStyles',currentBlockStyles)

      let tempWidth, tempStyle, tempColor;

      if (borderPropertiesArray.length >= 3) {
        tempWidth = borderPropertiesArray[0];
        tempStyle = borderPropertiesArray[1];
        tempColor = borderPropertiesArray[2];
      }

      if (tempWidth !== undefined) {
        setBorderWidth(tempWidth);
      }
      if (tempStyle !== undefined) {
        setBorderStyle(tempStyle);
      }
      if (tempColor !== undefined) {
        setBorderColor(tempColor);
      }
    }
    
  }, [currentBlockStyles]);

  useEffect(() => {
    console.log('borderWidth, borderStyle, borderColor', borderWidth, borderStyle, borderColor)
  }, [borderWidth, borderStyle, borderColor])




  const borderStyles = [
    { value: 'solid', label: 'solid' },
    { value: 'dotted', label: 'dotted' },
    { value: 'dashed', label: 'dashed' },
    { value: 'double', label: 'double' },
    { value: 'groove', label: 'groove' },
    { value: 'ridge', label: 'ridge' },
    { value: 'inset', label: 'inset' },
    { value: 'outset', label: 'outset' },
    { value: 'none', label: 'none' },
  ];

  return (
    <div className="border-styles tool">
      <p className="styling-text">Border:</p>
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "5px" }}>
        <div>
          <p className="styling-text">Width</p>
          <TextField id="select-border-width" className="border-unit styling-select"
            // label="Width" 
            type="number" value={parseInt(borderWidth?.replace("px", ""))} onChange={(e) => { handleWidthChange(e); }} InputProps={{ inputProps: { min: 0 } }} />
        </div>
        <div>
          <p className="styling-text">Style</p>
          <TextField className="border-unit styling-select" style={{ width: "90px" }} select variant="standard" onChange={(e) => {
            setBorderStyle(e.target.value)
          }} InputProps={{ disableUnderline: true }} placeholder={borderStyle} value={borderStyle}>
            {
              borderStyles?.map((el) => {
                return <MenuItem value={el.value}>{el.value}</MenuItem>
              })
            }
          </TextField>
        </div>
        <div>
          <p className="styling-text" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0" }}>
            {/* {name ? (name + " ") : ""} */}
            <span>Color</span>
            {props.deleteModal}
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField className="bgcolor-input styling-input" type="text" variant="filled"
              InputLabelProps={{ shrink: true, }} placeholder="Type in hex value or select color" value={colorHex}
              onChange={(e) => handleColorChange(e)} />

            <input type="color" className="color-box" value={colorHex} onClick={(e) => {
              e.preventDefault();
              setPickerOpen(!pickerOpen);
            }} style={{ margin: "0" }} onChange={(e) => console.log("onchange", e.target.value)} />
            {
              pickerOpen && <ChromePicker color={colorHex}
                onChange={handleChangeForColorPicker}
              // onChangeComplete={handleChangeForColorPicker} 
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Border;
