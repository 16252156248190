import { useState, useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import BackgroundColor from "./StylingComponents/BackgroundColor";
import Color from "./StylingComponents/Color";
import Height from "./StylingComponents/Height";
import Width from "./StylingComponents/Width";
import Border from "./StylingComponents/Border";
import BorderRadius from "./StylingComponents/BorderRadius";
import Padding from "./StylingComponents/Padding";
import Font from "./StylingComponents/Font";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";


const AnswerTextareaOptionsWidget = (props) => {
  console.log("AnswerTextareaOptionsWidget props", props);

  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  console.log("currentContentBlock", currentContentBlock);

  const currentBlock = contentBlocks?.find(block => block.contentType === "AnswerTextareaOptions");
  const blockId = currentBlock?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log(currentBlockStyles);
  console.log("currentBlock", currentBlock);

  return (
    <div>
      {/* <Width blockId={blockId} /><br />
      <Font blockId={blockId} />
      <Color blockId={blockId} />
      <BackgroundColor blockId={blockId} />
      <Padding blockId={blockId} name="Text Box" />
      <Border blockId={blockId} name="Text Box" />
      <BorderRadius blockId={blockId} name="Text Box" /> */}
      <DynamicStylingTool/>
    </div>
  );
}

export default AnswerTextareaOptionsWidget;

