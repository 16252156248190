import { useState, useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import BackgroundColor from "./StylingComponents/BackgroundColor";
import Color from "./StylingComponents/Color";
import Font from "./StylingComponents/Font";
import Padding from "./StylingComponents/Padding";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const SectionWidget = (props) => {

  console.log("SectionWidget props", props);
  
  // const blockId = props?.blockId;
  // console.log("SectionWidget blockId", blockId);

  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  console.log("currentContentBlock", currentContentBlock);
  
  const currentBlock = contentBlocks?.find(block => block.contentType === "SectionsBar");
  const blockId = contentBlocks?.find(block => block.contentType === "SectionsBar")?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log("currentBlock", currentBlock);

  return (
    <div>
      {/* <p>Sections Widget</p> */}
      {/* <BackgroundColor blockId={blockId} />
      <Color blockId={blockId} />
      <Font blockId={blockId} />
      <Padding blockId={blockId} /> */}
      <DynamicStylingTool/>
    </div>
  );
}

export default SectionWidget;
