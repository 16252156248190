import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import ContentEditable from "react-contenteditable";

import { Box, Button, Checkbox, FormControlLabel, Modal, Switch } from '@mui/material';
import { Add, Delete } from '@material-ui/icons';

import BuilderContext from '../../store/BuilderContext';
import { TextField } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AuthContext from "../../store/AuthContext";
import EditButton from "../../assets/img/builder-images/edit-button.svg";
import CloseButton from "../../assets/img/builder-images/close-modal.svg";
import { styled } from '@mui/material/styles';

import HeadingWidget from "./Tools/HeadingWidget";


const DialogSelect = (props) => {

  const { fields, setFields } = props;

  const useStyles = makeStyles((theme) => ({
    container: { display: 'flex', flexWrap: 'wrap', width: 420, },
    formControl: { margin: theme.spacing(1), minWidth: 400, },
  }));

  const classes = useStyles();
  const authCtx = useContext(AuthContext);
  const [open, setOpen]                             = useState(false);
  const [fieldLabel, setFieldLabel]                 = useState("");
  const [placeholder, setPlaceholder]               = useState("");
  const [fieldType, setFieldType]                   = useState("");
  const [defaultValue, setDefaultValue]             = useState("");
  const [width, setWidth]                           = useState("");
  const [acFieldID, setAcFieldID]                   = useState("");
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFields(prev => [...prev, { label: fieldLabel, placeholder: placeholder, fieldType: fieldType, value: defaultValue, acFieldID: acFieldID, }]);

    // change this endpoint later
    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/newField', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    //   // body: JSON.stringify({users: allUsersDetails}),
    // })
    // .then((response) => response.json())
    // .then((data) => {
    //   console.log(data);
    //   if (data.status === "success") {
    //     console.log("successfully added new field...");
    //   }
    // })
    // .catch((err) => console.error("failed to add new field...", err));
  }

  return (
    <div>
      <Button style={{ backgroundColor: "cornflowerblue", color: "#fff", padding: "7px 15px", fontFamily: "Nunito", fontSize: "16px", fontWeight: 600, marginLeft: "40px" }} onClick={handleClickOpen} className="add-input-field-button">+ ADD NEW FIELD</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Please enter details of the new field.</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField required fullWidth label="Field Label" variant="outlined" value={fieldLabel} onChange={(e) => setFieldLabel(e.target.value || '')} />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField required fullWidth label="Placeholder" variant="outlined" value={placeholder} onChange={(e) => setPlaceholder(e.target.value || '')} />
            </FormControl>
           
            <FormControl className={classes.formControl}>
              <TextField fullWidth select value={fieldType} onChange={(e) => setFieldType(e.target.value)} placeholder="Field Type" label="Field Type" variant="outlined" required>
                <MenuItem className="section-name" value='text field'>Text Field</MenuItem>
                <MenuItem className="section-name" value='drop down selection'>Drop Down Selection</MenuItem>
                <MenuItem className="section-name" value='drop down selection with search'>Drop Down Selection With Search</MenuItem>
                <MenuItem className="section-name" value='toggle button'>Toggle Button</MenuItem>
                <MenuItem className="section-name" value='radio button'>Radio Button</MenuItem>
              </TextField>
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField fullWidth label="Default Value" value={defaultValue} onChange={(e) => setDefaultValue(e.target.value)} variant="outlined"  />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField fullWidth label="Active Campaign Field ID" placeholder="Active Campaign Field ID" value={acFieldID} onChange={(e) => setAcFieldID(e.target.value)} variant="outlined" required />
            </FormControl>

          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary"> Cancel </Button>
          <Button onClick={(e) => handleSubmit(e)} color="primary" disabled={!fieldLabel || !placeholder || !acFieldID}> Add </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const DetailsNew = (props) => {

  const pageId = props.pageId;
  // console.log("pageId", pageId);

  const useStyles = makeStyles((theme) => ({
    container: { display: 'flex', flexWrap: 'wrap', width: 420, },
    formControl: { margin: theme.spacing(1), minWidth: 400, },
  }));

  const modalBoxStyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "40vw",  
    bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, maxHeight: "90vh", overflowY: "auto", padding: "30px 20px",
  };

  const editModalBoxStyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "40vw",  
    bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, maxHeight: "90vh", overflowY: "auto", padding: "30px 20px",
  }

  const classes = useStyles();
  const { id } = useParams();
  
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);

  const { accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, contentBlocks, changeCurrentPage, showStartPageImgVidSection, setShowStartPageImgVidSection, startPageVideoURL, setStartPageVideoURL, startPageImageURL, setStartPageImageURL, lastUploadedMedia, setLastUploadedMedia } = builderCtx;

  const DetailsHeadingBlockID = contentBlocks?.find(block => block?.contentType === "heading" && block?.pageArea === "details")?.id;
  

  // const [selectedContentBlockId, setSelectedContentBlockId] = useState(0);

  // const accessToolsById = (e, id) => {
  //   e.stopPropagation();
  //   let contentBlock = builderCtx?.contentBlocks?.find(obj => { return obj.id == id});
  //   setSelectedContentBlockId(contentBlock?.id);
  //   builderCtx?.updateCurrentBlock(contentBlock);
  // }

  // const setStyle = (id) => {
  //   let contentBlock = builderCtx?.contentBlocks?.find(obj => { return obj.id == id});
  //   if(contentBlock && contentBlock.id == selectedContentBlockId) {
  //     let styling = {...contentBlock.styling, border: "solid 1px #7FB6BE"};
  //     return styling;
  //   } else if(contentBlock && contentBlock.styling) {
  //     return contentBlock.styling;
  //   }
  //   return null;
  // }

  // const updateContentText = (e, id) => {
  //   builderCtx.updateContentBlocks(builderCtx.contentBlocks?.map(block => {
  //     if(block.id == id) {
  //       block.properties.text = e.target.value;
  //       return block;
  //     }
  //     return block;
  //   }))
  // }



  
  const [fieldsArray, setFieldsArray] = useState([
    { order: 1, label: 'Full Name', placeholder: 'Full Name', value: '', fieldName: "full_name", fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 1, label: 'First Name', placeholder: 'First Name', value: '', fieldName: "first_name", fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 2, label: 'Last Name', placeholder: 'Last Name', value: '', fieldName: "last_name", fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 3, label: 'Email', placeholder: 'name@email.com', value: '', fieldName: "email", fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 4, label: 'Organization', placeholder: 'Organization Name', value: '', fieldName: "org_name", fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 5, label: 'Job Title', placeholder: 'Job Title', value: '', fieldName: "job_title", fieldType: 'drop down selection with search', acFieldID: '', showForMembers: true, width: "50", },
    { order: 6, label: 'Country', placeholder: 'United States', value: '', fieldName: "country", fieldType: 'drop down selection with search', acFieldID: '', showForMembers: true, width: "50", },
    { order: 7, label: 'State', placeholder: 'State', value: '', fieldName: "state", fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
    { order: 8, label: 'ZIP Code', placeholder: '10100', value: '', fieldName: "zip_code", fieldType: 'text field', acFieldID: '', showForMembers: true, width: "50", },
  ]);
  
  const [selectedFields, setSelectedFields] = useState([
    { order: 1, label: 'First Name', placeholder: 'First Name', value: '', fieldName: "first_name", fieldType: 'text field', acFieldID: '', showForMembers: true, propagateForMembers:true, width: "50", isEditable: false },
    { order: 2, label: 'Last Name', placeholder: 'Last Name', value: '', fieldName: "last_name", fieldType: 'text field', acFieldID: '', showForMembers: true, propagateForMembers:true, width: "50", isEditable: false },
    { order: 3, label: 'Email', placeholder: 'name@email.com', value: '', fieldName: "email", fieldType: 'text field', acFieldID: '', showForMembers: true, propagateForMembers:true, width: "50", isEditable: false },
    { order: 4, label: "Job Title",  placeholder: "options", value: '',  fieldName: "job title",  fieldType: "drop down selection",  acFieldID: '', showForMembers: true,  propagateForMembers: true, width: "50", isEditable: true }
  ]);

  const [jobTitleValues, setJobTitleValues] = useState([
    {value: "Other", label: "Other"},
    {value: "Administrative Pastor", label: "Administrative Pastor"},
    {value: "Lead Pastor", label: "Lead Pastor"},
    {value: "Campus Pastor", label: "Campus Pastor"},
    {value: "Children's Pastor", label: "Children's Pastor"},
    {value: "College Pastor", label: "College Pastor"},
    {value: "Community Pastor", label: "Community Pastor"},
    {value: "Connections Pastor", label: "Connections Pastor"},
    {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
    {value: "Discipleship Pastor", label: "Discipleship Pastor"},
    {value: "Executive Pastor", label: "Executive Pastor"},
    {value: "Family Pastor", label: "Family Pastor"},
    {value: "High School Pastor", label: "High School Pastor"},
    {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
    {value: "Middle School Pastor", label: "Middle School Pastor"},
    {value: "Missions Pastor", label: "Missions Pastor"},
    {value: "Outreach Pastor", label: "Outreach Pastor"},
    {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
    {value: "Senior Pastor", label: "Senior Pastor"},
    {value: "Small Group Pastor", label: "Small Group Pastor"},
    {value: "Stewardship Pastor", label: "Stewardship Pastor"},
    {value: "Student Pastor", label: "Student Pastor"},
    {value: "Teacher Pastor", label: "Teacher Pastor"},
    {value: "Worship Pastor", label: "Worship Pastor"},
    {value: "Administrative Assistant", label: "Administrative Assistant"},  
    {value: "AV Director", label: "AV Director"},
    {value: "Bookkeeper", label: "Bookkeeper"},
    {value: "Chief Financial Officer", label: "Chief Financial Officer"},
    {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
    {value: "Communications Coordinator", label: "Communications Coordinator"},
    {value: "Communications Director", label: "Communications Director"},
    {value: "Communications Project Manager", label: "Communications Project Manager"},
    {value: "Content Writer", label: "Content Writer"},
    {value: "Digital Media Analyst", label: "Digital Media Analyst"},
    {value: "Events Coordinator", label: "Events Coordinator"},
    {value: "Facility Manager", label: "Facility Manager"},
    {value: "Graphic Designer", label: "Graphic Designer"},
    {value: "Groups Director", label: "Groups Director"},
    {value: "Guest Services Director", label: "Guest Services Director"},
    {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
    {value: "IT Director", label: "IT Director"},
    {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
    {value: "Office Manager", label: "Office Manager"},
    {value: "Online Community Manager", label: "Online Community Manager"},
    {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
    {value: "Preschool Director", label: "Preschool Director"},
    {value: "Production Coordinator", label: "Production Coordinator"},
    {value: "Social Media Manager", label: "Social Media Manager"},
    {value: "Video Producer", label: "Video Producer"},
    {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
    {value: "Web Developer", label: "Web Developer"},
    {value: "Worship Leader", label: "Worship Leader"},
  ]);


  //Edit modal //
  const [openEditModal, setEditModal]                       = useState(false);
  const [order, setOrder]                                   = useState(null);
  const [selectedEditField, setSelcetedEditField]           = useState([]);
  const [editDropDownField, setEditDropDownField]           = useState(false);
  const [editDropDownFieldName, setEditDropDownFieldName]   = useState('');
  const [dropDownItemValue, setDropDownItemValue]           = useState('');
  const [dropDownItemValueIndex, setDropDownItemValueIndex] = useState(null);
  const closeListOfEdits = () => setEditModal(false);
  const openListOfEdits = () => setEditModal(true);

  const editButtonHandler = (e) => {
    e.preventDefault();
    const editFIeld = selectedFields.filter((field) => field.order == +e.target.id);
    setSelcetedEditField(editFIeld);
    // setSelcetedEditFieldHandler();
    setOrder(e.target.id);
    setEditModal(!openEditModal);
  }

  const editDropDownFieldButtonHandler = (e) => {
    const editDropDownFieldName = e.target.alt || e.target.id;
    setEditDropDownFieldName(editDropDownFieldName);
    setEditDropDownField(!editDropDownField);
    
    if(dropDownItemValueIndex) { 
    const newJobTitle = {value: dropDownItemValue, label: dropDownItemValue};
    const newJobTitleValues = jobTitleValues.filter((field, idx) => idx != dropDownItemValueIndex);
    const newJobTitles = newJobTitleValues.splice(dropDownItemValueIndex, 0, newJobTitle);  
    // setJobTitleValues(newJobTitleValues);
    setDropDownItemValue("");
  }
  }

  const dropDownItemValueHandler = (e) => {
    setDropDownItemValue(e.target.value);
    setDropDownItemValueIndex(e.target.id);
  }

  const editNewFieldHandler = (e) => {
    console.log("Edit IDS", e.target.id );
    const editedField = selectedFields[+e.target.id - 1];
    const notEditedFields = selectedFields.filter((field) => field.order != +e.target.id);

    if( inputPlaceHolder ) {
      editedField.placeholder = inputPlaceHolder;
    }

    if( inputLabel ) {
      editedField.label = inputLabel;
    }

    if( fieldType ) {
      editedField.fieldType = fieldType;
    }

    if( fieldWidth ) {
      editedField.width = fieldWidth;
    }

    
    const newSelectedFields = [...notEditedFields, editedField].sort((a,b) => a.order - b.order);
    setSelectedFields(newSelectedFields);
    console.log("Not edited Fields",notEditedFields, newSelectedFields);
      setEditModal(false);
  // }

  // change this endpoint later
  fetch(process.env.REACT_APP_API_URI + '/v2/admin/assessment/field', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    body: JSON.stringify({ assessment_id: id, page_id: pageId.toString(), data: selectedFields }),
  })
  .then((response) => response.json())
  .then((data) => {
    console.log("data", data);
    if (data.status === "success") {
      // setSelectedFields();
      console.log("successfully added new field...");
    }
  })
  .catch((err) => console.error("failed to add new field...", err));
}

  //Delete Field modal //
  const [openDeleteModal, setOpenDelteModal] = useState(false);
  const [fieldOrder, setFieldOrder] = useState(null);

  const openDeleteModalHandler = (id) => {
    console.log("TARGET_open", typeof id);
    setFieldOrder(id);

    setOpenDelteModal(true);
  }

  const closeDeleteModalHandler = (e) => {
    if(e.target.id == "yes") {
      const newSelectedFields = selectedFields.filter((field) => field.order !== fieldOrder);
      setSelectedFields(newSelectedFields);
      console.log("TARGET_fields", selectedFields);

      // DELETE //
      // fetch(process.env.REACT_APP_API_URI + `/v2/admin/assessment/field/:${fieldOrder}`,{
      //   method: "DELETE",
      // } ).then((res) => res.json);

    }
    setOpenDelteModal(false);
  }

  const DeleteModal = () => {
   return ( <div style={{backgroundColor: "rgba(0, 0, 0, 0.10)"}} className="delete-container">
       <Dialog
        open={openDeleteModal}
        onClose={closeDeleteModalHandler}
      >
        <DialogTitle id="deleteField">
          {"Are you sure ?"}
        </DialogTitle>
        
        <DialogActions>
          <Button id="yes" onClick={closeDeleteModalHandler}>Yes</Button>
          <Button id="no" onClick={closeDeleteModalHandler} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
   )
  }
  //Field modal //
  const [openModal, setOpenModal] = useState(false);
  const openListOfFields = () => setOpenModal(true);
  const closeListOfFields = () => setOpenModal(false);

  const [inputLabel, setInputLabel]                         = useState("");
  const [inputPlaceHolder, setInputPlaceHolder]             = useState("");
  const [fieldType, setFieldType]                           = useState("");
  const [fieldWidth, setFieldWidth]                         = useState(""); 
  const [showForMembers, setShowForMembers]                 = useState(true);
  const [propagateForMembers, setPropagateForMembers]       = useState(true);
  
  const [inputLabelMobile, setInputLabelMobile]                                     = useState("");
  const [inputPlaceHolderMobile, setInputPlaceHolderMobile]             = useState("");
  const [fieldTypeMobile, setFieldTypeMobile]                           = useState("");
  const [fieldWidthMobile, setFieldWidthMobile]                         = useState(""); 
  const [showForMembersMobile, setShowForMembersMobile]                 = useState(true);
  const [propagateForMembersMobile, setPropagateForMembersMobile]       = useState(true);

  const [fieldTextColor, setFieldTextColor]                 = useState("#9B9B9B");
  const [addButtonColor, setAddButtonClor]                  = useState("#d1d5db");   

  const inputPlaceHolderHandler = (e) => {
    setInputPlaceHolder(e.target.value);
    setFieldTextColor("#000000");
  }

  const showForMembersHandler = (e) => {
    setShowForMembers(!showForMembers);
  }

  const propagateForMembersHandler = (e) => {
    setPropagateForMembers(!propagateForMembers);
  }

  const inputLabelHandler = (e) => {
    console.log("Input Label", e.target.value);
    setInputLabel(e.target.value);
    setFieldTextColor("#000000");
  }

  const fieldTypeHandler = (e) => {
    console.log("Field Type", e.target.value);
    setFieldType(e.target.value);
  }

  const fieldWidthHandler = (e) => {
    console.log("Field Width", e.target.value);
    setFieldWidth(e.target.value);
  }

  // mobile handlers

  const inputPlaceHolderHandlerMobile = (e) => {
    setInputPlaceHolderMobile(e.target.value);
    setFieldTextColor("#000000");
  }

  const showForMembersHandlerMobile = (e) => {
    setShowForMembers(!showForMembers);
  }

  const propagateForMembersHandlerMobile = (e) => {
    setPropagateForMembers(!propagateForMembers);
  }

  const inputLabelHandlerMobile = (e) => {
    console.log("Input Label", e.target.value);
    setInputLabelMobile(e.target.value);
    setFieldTextColor("#000000");
  }

  const fieldTypeHandlerMobile = (e) => {
    console.log("Field Type", e.target.value);
    setFieldTypeMobile(e.target.value);
  }

  const fieldWidthHandlerMobile = (e) => {
    console.log("Field Width", e.target.value);
    setFieldWidthMobile(e.target.value);
  }

  useEffect(() => {
    if( inputLabel && inputPlaceHolder && fieldType && fieldWidth ) {
      setAddButtonClor("#122845");
      
    }
  },[fieldType, fieldWidth, inputLabel, inputPlaceHolder]);

  const addNewFieldHandler = (e) => {
    e.preventDefault();

    if( inputPlaceHolder && inputLabel && fieldType && fieldWidth ) {
      const newField = {order: selectedFields.length + 1, label: inputLabel, placeholder: inputPlaceHolder, value: '', fieldName: inputLabel.toLowerCase(), fieldType, acFieldID: '', showForMembers, propagateForMembers, width: fieldWidth,  isEditable: true };
      setSelectedFields([...selectedFields, newField]);
      closeListOfFields();

      // change this endpoint later
      fetch(process.env.REACT_APP_API_URI + '/v2/admin/assessment/field', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify({ assessment_id: id, page_id: pageId.toString(), data: selectedFields }),
      })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        if (data.status === "success") {
          // setSelectedFields();
          console.log("successfully added new field...");
        }
      })
      .catch((err) => console.error("failed to add new field...", err));
    }
  }

  const handleSelectedFields = (e, field) => {
    if(selectedFields?.every(fld => fld.label !== field.label)) {
      setSelectedFields((prev) => [...prev, field]);
    } else if(selectedFields?.find(fld => fld.label === field.label)) {
      setSelectedFields(selectedFields.filter(item => item.label !== field.label));
    }
  };

  const handleFieldsOrder = (e, field) => {
    setSelectedFields(selectedFields?.map(fld => {
      if(fld.label === field.label) {
        fld.order = parseInt(e.target.value);
      }
      return fld;
    }))
    
    setFieldsArray(fieldsArray?.map(fld => {
      if(fld.label === field.label) {
        fld.order = parseInt(e.target.value);
      }
      return fld;
    }))
  }

  const handleFieldsShownForMembers = (e, field) => {
    setSelectedFields(selectedFields?.map(fld => {
      if(fld.label === field.label) {
        fld.showForMembers = e.target.checked;
      }
      return fld;
    }))
    
    setFieldsArray(fieldsArray?.map(fld => {
      if(fld.label === field.label) {
        fld.showForMembers = e.target.checked;
      }
      return fld;
    }))
  }
  
  const handleFieldsWidth = (e, field) => {
    setSelectedFields(selectedFields?.map(fld => {
      if(fld.label === field.label) {
        fld.width = e.target.value;
      }
      return fld;
    }))
    
    setFieldsArray(fieldsArray?.map(fld => {
      if(fld.label === field.label) {
        fld.width = e.target.value;
      }
      return fld;
    }))
  }
  
  const handleFieldTypeChange = (e, field) => {
    setSelectedFields(selectedFields?.map(fld => {
      if(fld.label === field.label) {
        fld.fieldType = e.target.value;
      }
      return fld;
    }))
    
    setFieldsArray(fieldsArray?.map(fld => {
      if(fld.label === field.label) {
        fld.fieldType = e.target.value;
      }
      return fld;
    }))
  }
  
  const handleACIdChange = (e, field) => {
    setSelectedFields(selectedFields?.map(fld => {
      if(fld.label === field.label) {
        fld.acFieldID = e.target.value;
      }
      return fld;
    }))
    
    setFieldsArray(fieldsArray?.map(fld => {
      if(fld.label === field.label) {
        fld.acFieldID = e.target.value;
      }
      return fld;
    }))
  }

  const fieldButtonHandler = (e) => {
    closeListOfFields();
  }



  
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#1669D9',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  

  const submitFieldChanges = (e) => {
    e.preventDefault();

    // change this endpoint later
    fetch(process.env.REACT_APP_API_URI + '/v2/admin/assessment/field', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({ assessment_id: id, page_id: pageId.toString(), data: selectedFields }),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log("data", data);
      if (data.status === "success") {
        // setSelectedFields();
        console.log("successfully added new field...");
      }
    })
    .catch((err) => console.error("failed to add new field...", err));

  }

  const showCreateModal = () => {
    return <Modal open={openModal} onClose={closeListOfFields} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={modalBoxStyle}>
            {/* //MINE..//  FIELDS*/}
            <div className="input-field-heading" >
            <h1 className="field-heading">Add New Field</h1>
            <Button onClick={fieldButtonHandler}><img  style={{ paddingLeft: "35px", width: "24px", height: "24px" }} src={CloseButton}></img></Button>
            </div>

            <div className="field-list">
              <div className="field-select-type">
                  <div className="field-type">
                    <TextField onClick={fieldTypeHandler} style={{ width: "270px", border: "1px solid #9B9B9B", borderRadius: "8px" }} select placeholder="Field Type" defaultValue="field">
                          <MenuItem className="section-name" disabled value='field'>Field Type</MenuItem>
                          <MenuItem className="section-name" value='text field'>Text Field</MenuItem>
                          <MenuItem className="section-name" value='drop down selection'>Drop Down Selection</MenuItem>
                          <MenuItem className="section-name" value='drop down selection with search'>Drop Down Selection With Search</MenuItem>
                          <MenuItem className="section-name" value='toggle button'>Toggle Button</MenuItem>
                          <MenuItem className="section-name" value='radio button'>Radio Button</MenuItem>
                    </TextField>
                  </div>
              
                <div className="width-type">
                  <TextField onClick={fieldWidthHandler} style={{width: "270px", border: "1px solid #9B9B9B", borderRadius: "8px" }} select placeholder="Width Type" defaultValue="width">
                        <MenuItem disabled value={'width'}>Width</MenuItem>
                        <MenuItem value={50}>Half Width</MenuItem>
                        <MenuItem value={100}>Full Width</MenuItem>
                  </TextField>
                </div>
              </div>
                <div className="input-labels">
                <TextField onChange={inputLabelHandler} style={{color: fieldTextColor}} hiddenLabel placeholder='Input Label'  variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="input-label-text" />
                <TextField onChange = {inputPlaceHolderHandler} hiddenLabel placeholder='Input Placeholder'  variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="input-placeholder-text" />
                </div>

                <div className="members-button">
                    <FormControlLabel style={{ flexDirection: "row" }} control={
                      // <IOSSwitch checked={true} 
                      <IOSSwitch onClick={showForMembersHandler} sx={{ m: 1 }} defaultChecked = {showForMembers}
                      
                      // onChange={(e) => handleFieldsShownForMembers(e, field)}
                      inputProps={{ 'aria-label': 'controlled' }} />}
                    label="Show for Members" />
                      
                    <FormControlLabel style={{ flexDirection: "row" }} control={
                        <IOSSwitch onClick={propagateForMembersHandler} sx={{ m: 1 }} defaultChecked = {propagateForMembers}
                      // onChange={(e) => handleFieldsShownForMembers(e, field)}
                      inputProps={{ 'aria-label': 'controlled' }} />}
                    label="Propagate to Members" />
                </div>

                <div style={{ display: "flex", justifyContent: "center", margin: "15px auto 5px" }}>
                <Button onClick={addNewFieldHandler} style={{ backgroundColor: addButtonColor, color: "#fff", padding: "7px 15px", fontFamily: "Nunito", fontSize: "16px", fontWeight: 600, width: "80%" }} >ADD NEW FIELD</Button>
              </div>
            </div>


            {/* <div className="list-of-fields">
              <p style={{ maxWidth: "55ch", margin: "15px auto" }}>Select the fields you want in the form. You can change the order in which they appear by changing the order number.</p>
              <p style={{ maxWidth: "55ch", margin: "15px auto" }}>The 'Show for Members' switch button lets you choose if that field should be shown on members/referrals form.</p>
              {fieldsArray?.sort((a, b) => a.order - b.order)?.map((field, idx) => {
                let isSelected = selectedFields?.find((fld) => fld.label === field.label);
                return (<div style={{ display: "flex" }} key={idx}>
                  <Checkbox sx={{ "&.MuiCheckbox-root:hover": { backgroundColor: "#ffffff" } }}
                    checked={selectedFields?.find((fld) => fld.label === field.label) ? true : false} 
                    onChange={(e) => handleSelectedFields(e, field)} inputProps={{ 'aria-label': 'controlled' }} />
                  
                  <div className="container" style={{ display: "flex", flexDirection: "column", border: isSelected ? "2px solid cornflowerblue" : "2px solid gray", borderRadius: "5px", backgroundColor: isSelected ? "#b3ceff11" : "#ffffff", padding: "10px 5px", margin: "10px auto" }}>

                  
                  <div className="section" style={{ width: "100%", display: "flex", padding: "10px" }}>

                    {/* <FormControl className={classes.formControl} style={{ width: "180px", minWidth: "180px" }}> */}
                      {/* <TextField style={{ width: "180px", minWidth: "180px", margin: "8px auto", }} label={field.label} required InputLabelProps={{ shrink: true, }} variant="outlined" value={field.value} placeholder={field.placeholder} />
                    {/* </FormControl> */}

                    {/* <TextField style={{ width: "150px", margin: "8px auto", }} label="Active Campaign ID" placeholder="54" InputLabelProps={{ shrink: true, }} variant="outlined" 
                    onChange={(e) => handleACIdChange(e, field)} value={field.acFieldID} />
                    
                    <TextField style={{ width: "50px", margin: "8px auto", padding: "0 30px 0 0" }} label="Order" type="number" InputLabelProps={{ shrink: true, }} variant="outlined" 
                    onChange={(e) => handleFieldsOrder(e, field)} value={field.order} />
                    
                    <FormControlLabel style={{ flexDirection: "column" }} control={
                      <Switch checked={field.showForMembers} 
                      onChange={(e) => handleFieldsShownForMembers(e, field)}
                      inputProps={{ 'aria-label': 'controlled' }} />}
                    label="Show for Members" /> */}

                  {/* </div> */} 
                  
                  {/* <div className="section" style={{ width: "100%", padding: "0 10px 10px", display: "flex", justifyContent: "flex-start", alignItems: "center", boxSizing: "border-box" }}> */}

                    {/* <Select style={{ width: "45%", marginRight: "8px" }} value={field.width} label="Width" title="Select half width for 2 fields in a row and full width for 1 field in a row" variant="outlined"
                      onChange={(e) => handleFieldsWidth(e, field)} >
                      <MenuItem value={50}>Half Width</MenuItem>
                      <MenuItem value={100}>Full Width</MenuItem>
                    </Select> */}

                    {/* <TextField style={{ width: "100%", maxWidth: "32%", margin: "8px auto",  }} label="Field Name" InputLabelProps={{ shrink: true, }} variant="outlined" 
                    // onChange={(e) => handleFieldsOrder(e, field)} 
                    disabled value={field.fieldName} />

                    <TextField required style={{ width: "100%", maxWidth: "32%", margin: "8px auto",  }} className="form-control-add-question margin-top" select label="Width" placeholder="50%" value={field.width} title="Select half width for 2 fields in a row and full width for 1 field in a row" variant="outlined"
                      onChange={(e) => handleFieldsWidth(e, field)} >
                      <MenuItem value={50}>Half Width</MenuItem>
                      <MenuItem value={100}>Full Width</MenuItem>
                    </TextField> */}

                    {/* <TextField select required style={{ width: "100%", maxWidth: "170px", margin: "8px auto"  }} 
                      className="form-control-add-question margin-top"
                      label="Field Type" placeholder="Field Type" value={field.fieldType}
                      onChange={(e) => handleFieldTypeChange(e, field)} variant="outlined" >
                      
                      <MenuItem className="section-name" value='text field'>Text Field</MenuItem>
                      <MenuItem className="section-name" value='drop down selection'>Drop Down Selection</MenuItem>
                      <MenuItem className="section-name" value='drop down selection with search'>Drop Down Selection With Search</MenuItem>
                      <MenuItem className="section-name" value='toggle button'>Toggle Button</MenuItem>
                      <MenuItem className="section-name" value='radio button'>Radio Button</MenuItem>

                    </TextField>
                  
                  </div>

                  </div>

                </div>);
              })} */}

              {/* <div style={{ display: "flex", justifyContent: "space-between", margin: "15px auto 5px" }}>
                <DialogSelect fields={fieldsArray} setFields={setFieldsArray} />
                <Button style={{ backgroundColor: "#007C89", color: "#fff", padding: "7px 15px", fontFamily: "Nunito", fontSize: "16px", fontWeight: 600 }} onClick={submitFieldChanges}>Save</Button>
              </div> */}
            {/* </div> */} 
          </Box>
        </Modal>
  }

  // console.log(fieldsArray);
  // console.log("selectedFields", selectedFields);
  // <div className="details" style={setSectionStyle("start-text-content")} onClick={(e) => accessToolsByPageArea("start-text-content", e)}>
  return <div style={setSectionStyle("details")} onClick={(e) => accessToolsByPageArea("details", e)}>
    {
      builderCtx.contentBlocks.map((data, index) => {
        if (data.pageArea === "details" && data.parentContainer !== 0) {
          
          if (data.contentType === "heading") {
            return <div key={index} style={setStyle(data.id)} onClick={(e) => accessToolsById(e, data.id)}>
              <HeadingWidget key={data.id} blockId={data.id} className="editable-text" />
            </div>
          } else if (data.contentType === "paragraph") {
            // return <div key={index} style={setStyle(data.id)} onClick={(e) => accessToolsById(e, data.id)} >
            //   <ParagraphWidget key={startPageParagraphBlockID} blockId={startPageParagraphBlockID} className="editable-text" />
            // </div>
          } else if (data.contentType === "form") {
            return <div className="details-form" >
              {
                selectedFields?.sort((a, b) => a.order - b.order)?.map(field => {
                  return <div className={field.width?.toString() === "100" ? "input-field full-width" : field.width?.toString() === "50" ? "input-field half-width" : "input-field"} key={field.label}>
                    <div className="input-section"><p className="input-title">{field.label}</p><div>
                    { openDeleteModal && <DeleteModal/>}
                    { field.isEditable && <button onClick = {() => openDeleteModalHandler(field.order)} className="input-delete-button"><Delete id={field.order}/></button> }
                    <button className="input-edit-button" onClick={editButtonHandler}><img alt="edit-button" id={field.order} style={{ paddingTop: "2px", paddingRight: "2px" }} src={EditButton}></img></button>
                    </div>
                    <Modal open={openEditModal} onClose={closeListOfEdits} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                      <Box sx={editModalBoxStyle}>
                        {/* //MINE..// EDITS */}
                        <div className="input-field-heading" >
                          <h1 className="field-heading">Edit Field</h1>
                          <Button onClick={editButtonHandler}><img  style={{ paddingLeft: "35px", width: "24px", height: "24px" }} src={CloseButton}></img></Button>
                        </div>

                        {
                          selectedEditField.map((field) => {
                            console.log("FIELD", field, selectedEditField);
                            return(
                              <>
                                <div className="field-list desktop-view">
                                  <h2>Desktop View</h2>
                                  <div className="field-select-type">
                                    <div className="field-type">
                                      <TextField onClick={fieldTypeHandler} style={{ width: "270px", border: "1px solid #9B9B9B", borderRadius: "8px" }} select placeholder="Field Type" defaultValue={field.fieldType}>
                                        <MenuItem className="section-name" disabled value='field'>Field Type</MenuItem>
                                        <MenuItem className="section-name" value='text field'>Text Field</MenuItem>
                                        <MenuItem className="section-name" value='drop down selection'>Drop Down Selection</MenuItem>
                                        <MenuItem className="section-name" value='drop down selection with search'>Drop Down Selection With Search</MenuItem>
                                        <MenuItem className="section-name" value='toggle button'>Toggle Button</MenuItem>
                                        <MenuItem className="section-name" value='radio button'>Radio Button</MenuItem>
                                      </TextField>
                                    </div>

                                    <div className="width-type">
                                      <TextField onClick={fieldWidthHandler} style={{width: "270px", border: "1px solid #9B9B9B", borderRadius: "8px" }} select placeholder="Width Type" defaultValue={field.width}>
                                        <MenuItem disabled value={'width'}>Width</MenuItem>
                                        <MenuItem value={50}>Half Width</MenuItem>
                                        <MenuItem value={100}>Full Width</MenuItem>
                                      </TextField>
                                    </div>
                                  </div>

                                  <div className="input-labels">
                                    <TextField defaultValue={field.label} onChange={inputLabelHandler} style={{color: fieldTextColor}} hiddenLabel placeholder='Input Label'  variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="input-label-text" />
                                    <TextField defaultValue={field.placeholder} onChange = {inputPlaceHolderHandler} hiddenLabel placeholder='Input Placeholder'  variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="input-placeholder-text" />
                                  </div>
                                  {
                                    field.fieldType == 'drop down selection' ? <div className="dropdown"> {
                                      jobTitleValues.map((field, idx) => {
                                        return (
                                          <div className="dropdown-item">
                                            { editDropDownField && editDropDownFieldName == field.label ? <TextField id={idx} onChange={dropDownItemValueHandler} placeholder="Edit Option" value={dropDownItemValue} style={{color: fieldTextColor}} hiddenLabel variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="dropdown-item-name-active" /> : <TextField defaultValue={field.label} disabled style={{color: fieldTextColor}} hiddenLabel variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="dropdown-item-name-disabled" /> }
                                            { editDropDownField && editDropDownFieldName == field.label ?  <Button itemID={idx} className="edit-save-button" onClick={editDropDownFieldButtonHandler}>Save</Button> : <Button id={field.label} onClick={editDropDownFieldButtonHandler}><img alt={field.label} src={EditButton}></img></Button> }
                                          </div>
                                        )

                                      })
                                    } </div> : null
                                  }

                                  <div className="members-button">
                                    <FormControlLabel style={{ flexDirection: "row" }} control={<IOSSwitch onClick={showForMembersHandler} sx={{ m: 1 }} defaultChecked = {field.showForMembers} inputProps={{ 'aria-label': 'controlled' }} />} label="Show for Members" />
                                    <FormControlLabel style={{ flexDirection: "row" }} control={<IOSSwitch onClick={propagateForMembersHandler} sx={{ m: 1 }} defaultChecked = {field.propagateForMembers} inputProps={{ 'aria-label': 'controlled' }} />} label="Propagate to Members" />
                                  </div>

                                  <div style={{ display: "flex", justifyContent: "center", margin: "15px auto 5px" }}>
                                    <Button onClick={editNewFieldHandler} id={field.order} style={{ backgroundColor: "#122845", color: "#fff", padding: "7px 15px", fontFamily: "Nunito", fontSize: "16px", fontWeight: 600, width: "80%" }} >EDIT FIELD</Button>
                                  </div>
                                </div>
                                <div className="field-list desktop-view">
                                  <h2>Mobile View</h2>
                                  <div className="field-select-type">
                                    <div className="field-type">
                                      <TextField onClick={fieldTypeHandlerMobile} style={{ width: "270px", border: "1px solid #9B9B9B", borderRadius: "8px" }} select placeholder="Field Type" defaultValue={field.fieldType}>
                                        <MenuItem className="section-name" disabled value='field'>Field Type</MenuItem>
                                        <MenuItem className="section-name" value='text field'>Text Field</MenuItem>
                                        <MenuItem className="section-name" value='drop down selection'>Drop Down Selection</MenuItem>
                                        <MenuItem className="section-name" value='drop down selection with search'>Drop Down Selection With Search</MenuItem>
                                        <MenuItem className="section-name" value='toggle button'>Toggle Button</MenuItem>
                                        <MenuItem className="section-name" value='radio button'>Radio Button</MenuItem>
                                      </TextField>
                                    </div>

                                    <div className="width-type">
                                      <TextField onClick={fieldWidthHandlerMobile} style={{width: "270px", border: "1px solid #9B9B9B", borderRadius: "8px" }} select placeholder="Width Type" defaultValue={field.width}>
                                        <MenuItem disabled value={'width'}>Width</MenuItem>
                                        <MenuItem value={50}>Half Width</MenuItem>
                                        <MenuItem value={100}>Full Width</MenuItem>
                                      </TextField>
                                    </div>
                                  </div>

                                  <div className="input-labels">
                                    <TextField defaultValue={field.label} onChange={inputLabelHandlerMobile} style={{color: fieldTextColor}} hiddenLabel placeholder='Input Label'  variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="input-label-text" />
                                    <TextField defaultValue={field.placeholder} onChange = {inputPlaceHolderHandlerMobile} hiddenLabel placeholder='Input Placeholder'  variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="input-placeholder-text" />
                                  </div>
                                  {
                                    field.fieldType == 'drop down selection' ? <div className="dropdown"> {
                                      jobTitleValues.map((field, idx) => {
                                        return (
                                          <div className="dropdown-item">
                                            { editDropDownField && editDropDownFieldName == field.label ? <TextField id={idx} onChange={dropDownItemValueHandler} placeholder="Edit Option" value={dropDownItemValue} style={{color: fieldTextColor}} hiddenLabel variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="dropdown-item-name-active" /> : <TextField defaultValue={field.label} disabled style={{color: fieldTextColor}} hiddenLabel variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="dropdown-item-name-disabled" /> }
                                            { editDropDownField && editDropDownFieldName == field.label ?  <Button itemID={idx} className="edit-save-button" onClick={editDropDownFieldButtonHandler}>Save</Button> : <Button id={field.label} onClick={editDropDownFieldButtonHandler}><img alt={field.label} src={EditButton}></img></Button> }
                                          </div>
                                        )

                                      })
                                    } </div> : null
                                  }

                                  <div className="members-button">
                                    <FormControlLabel style={{ flexDirection: "row" }} control={<IOSSwitch onClick={showForMembersHandler} sx={{ m: 1 }} defaultChecked = {field.showForMembers} inputProps={{ 'aria-label': 'controlled' }} />} label="Show for Members" />
                                    <FormControlLabel style={{ flexDirection: "row" }} control={<IOSSwitch onClick={propagateForMembersHandler} sx={{ m: 1 }} defaultChecked = {field.propagateForMembers} inputProps={{ 'aria-label': 'controlled' }} />} label="Propagate to Members" />
                                  </div>

                                  <div style={{ display: "flex", justifyContent: "center", margin: "15px auto 5px" }}>
                                    <Button onClick={editNewFieldHandler} id={field.order} style={{ backgroundColor: "#122845", color: "#fff", padding: "7px 15px", fontFamily: "Nunito", fontSize: "16px", fontWeight: 600, width: "80%" }} >EDIT FIELD</Button>
                                  </div>
                                </div>
                              </>
                            )
                          })
                        }
                      </Box>
                    </Modal>
                  </div>
                  <TextField hiddenLabel placeholder={field.placeholder} value={field.value} variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                </div>
                })
              }

              <div className="input-field half-width">
                <Button onClick={(e) => openListOfFields(e)} className="add-input-field-button">+ NEW FIELD</Button>
                {showCreateModal()}
              </div>
              
            </div>
          } else if (data.contentType === "button") {
            
          }
          
        }
      })
    }


    
    <Button className="submit-button">Show My Results</Button>
  </div>;

}



export default DetailsNew;

