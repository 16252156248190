import { createContext, useState } from 'react';

const ModalContext = createContext({
  open: false,
  modal: 'none',
  details: {},
  pageUpdate: {},
  categories: false,
  setDetails: (setDetails) => {},
  setPageUpdate: (setPageUpdate) => {},
  updateCategories: (updateCategories) => {},
  openModal: (openModal) => {},
  closeModal: (closeModal) => {}
});

export function ModalContextProvider(props) {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState('none');
  const [modalData, setModalData] = useState(null);
  const [details, setDetails] = useState({});
  const [pageUpdate, setPageUpdate] = useState({});
  const [categories, setCategories] = useState([]);
  const [refreshPagesAndContent, setRefreshPagesAndContent]         = useState(false);

  function openModalHandler() {
    setOpen(true);
  }

  function closeModalHandler() {
    setModal(null);
    setDetails(null);
    setOpen(false);
  }

  function setDetailsHandler(modal, data) {
    setModal(modal);
    setDetails(data);
  }

  function setPageUpdateHandler(data) {
    setPageUpdate(data);
  }

  // New function to update categories
  function updateCategoriesHandler(newCategories) {
    setCategories(newCategories);
  }

  function toggleModalHandler(modal, user) {
    openModalHandler();
    setDetailsHandler(modal, user);
  };

  const context = {
    open: open,
    modal: modal,
    details: details,
    pageUpdate: pageUpdate,
    categories: categories, // categories
    refreshPagesAndContent: refreshPagesAndContent,
    setRefreshPagesAndContent: setRefreshPagesAndContent,
    setDetails: setDetailsHandler,
    setPageUpdate: setPageUpdateHandler,
    updateCategories: updateCategoriesHandler, //updateCategories function
    modalData: modalData,
    setModalData: setModalData,
    openModal: openModalHandler,
    closeModal: closeModalHandler,
    toggleModalHandler: toggleModalHandler,
  };

  return (
    <ModalContext.Provider value={context}>
      {props.children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
