import { useContext, useState, useCallback } from 'react';
import { Button, CircularProgress, Slider } from '@mui/material';

import Cropper from 'react-easy-crop';
import { Base64 } from 'js-base64';

// import getCroppedImg from './CropImage.js'
import ModalContext from '../../../../store/ModalContext.js';
import AuthContext from '../../../../store/AuthContext.js';


import { CloseRounded } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import BuilderContext from '../../../../store/BuilderContext.js';
import { useEffect } from 'react';
import axios from 'axios';

function Dropzone({ onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: { 'video/*': ['.mkv', '.mp4']},
      onDrop,
    });

  const files = acceptedFiles.map((file) => {
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>}
  );
  return (
    <div className="d-n-d">
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          {isDragActive ? (
            <p className="dropzone-content">
              Release to drop the files here
            </p>
          ) : (
            <div className="dropzone-content">
              <p>Upload Video</p>
              <p>Drag and drop or click to browse</p>
            </div>
          )}
        </div> 
      </div>
    </div>
  );
}

const AddVideo = ({ id }) => {

  const authCtx = useContext(AuthContext);
  const [video, setVideo] = useState();
    // this gets a file object which is passed to firebase
  const baseToFile = (base) => {    
    if (base) {
      let arr = base?.split(",")    
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]
  
      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)
    
      while(n--)  {
        dataArr[n] = dataStr.charCodeAt(n)
      }
    
      let file = new File([dataArr], `start_video_${Date.now()}.mp4`, {type: mime})
      return file
    } else {
      return null
    }
  }
           
  const [vid, setVid] = useState(null);
   // puts image into a state
   const uploadVid = () => {
    
    setVid(baseToFile(video));
    if(vid) {
      // setFinalImage(img);
      console.log(vid);
      var formdata = new FormData();
      formdata.append("file", vid);
      console.log(formdata);

      // fetch(process.env.REACT_APP_API_URI + '/v1/accounts/' + id + '/uploadVideo', {
      //   method: 'POST',
      //   headers: { 'Authorization': 'Bearer ' + authCtx.token },
      //   body: formdata,
      //   redirect: 'follow'
      // }).then(response => {
      //   return response.json();
      // }).then(data => {
      //   console.log(data);
      //   // if(data.status == "success") {
      //   //   drawerCtx.setPageUpdate({page: "products"});
      //   //   drawerCtx.closeDrawer();
      //   // }
      // }).catch(error => {
      //   console.log('error', error);
      // });

      axios.post(process.env.REACT_APP_API_URI + '/v1/accounts/' + id + '/uploadVideo', formdata, {onUploadProgress: (data) => {
        console.log(data.loaded, data.total);
      },
      }).then(success => {
        console.log('success');
      }).catch(err=> {
        console.log(err);
      })

    } else {
      console.log(vid);
      console.log("no vid");
    }

  }
  // console.log(video);
  useEffect(() => {
    uploadVid();
  
  }, [video])
  
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setVideo(e.target.result);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []); 
  
  // uploadVid();

  return(
    <div>
      {vid ? <div>
        {vid.name}
      </div> :
      <Dropzone onDrop={onDrop} />}
    </div>
  );
}

export default AddVideo;