import { useState, useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import BackgroundColor from "./StylingComponents/BackgroundColor";
import Border from "./StylingComponents/Border";
import BorderRadius from "./StylingComponents/BorderRadius";
import Color from "./StylingComponents/Color";
import Font from "./StylingComponents/Font";
import Height from "./StylingComponents/Height";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";


const ResultsTemperatureScaleWidget = (props) => {

  console.log("ResultsTemperatureScaleWidget props", props);

  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  console.log("currentContentBlock", currentContentBlock);
  
  const currentBlock = contentBlocks?.find(block => block.contentType === "ResultsTemperatureScale");
  const blockId = contentBlocks?.find(block => block.contentType === "ResultsTemperatureScale")?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log(currentBlockStyles);
  console.log("currentBlock", currentBlock);

  return (
    <div>
      <p style={{ paddingLeft: "10px" }}>Results Temperature Scale Widget</p>
      {/* <Color blockId={blockId} />
      <BackgroundColor blockId={blockId} />
      <Height blockId={blockId} />
      <Border blockId={blockId} />
      <BorderRadius blockId={blockId} /> */}
      <DynamicStylingTool/>

    </div>
  );
}



export default ResultsTemperatureScaleWidget;

