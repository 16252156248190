import { useState, useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import BackgroundColor from "./StylingComponents/BackgroundColor";
import Color from "./StylingComponents/Color";
import Height from "./StylingComponents/Height";
import Width from "./StylingComponents/Width";
import Font from "./StylingComponents/Font";
import Padding from "./StylingComponents/Padding";
import Margin from "./StylingComponents/Margin";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const ProgressIndicatorWidget = (props) => {

  console.log("ProgressIndicatorWidget props", props);

  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  console.log("currentContentBlock", currentContentBlock);

  const currentBlock = contentBlocks?.find(block => block.contentType === "ProgressIndicator");
  const blockId = currentBlock?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log(currentBlockStyles);
  console.log("currentBlock", currentBlock);

  return (
    <div>
      {/* <Font blockId={blockId} />
      <Color blockId={blockId} />
      <Padding blockId={blockId} />
      <Margin blockId={blockId} /> */}
      <DynamicStylingTool/>
    </div>
  )
}



export default ProgressIndicatorWidget;

