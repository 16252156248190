import { useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import AddImage from "./AddImage/AddImage";
import BackgroundColor from './StylingComponents/BackgroundColor';
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const BackgroundWidget = () => {

  const builderCtx = useContext(BuilderContext);

  const { currentContentBlock } = builderCtx;

  // console.log("currentContentBlock", currentContentBlock);

  return (
    <div>
      {/* <p style={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "700", textAlign: "center", color: "#122845" }}>Background</p> */}
      {/* <BackgroundColor blockId={currentContentBlock?.id} />
      <AddImage blockId={currentContentBlock?.id} background={true} /> */}
      <DynamicStylingTool/>
    </div>
  )
}



export default BackgroundWidget;

