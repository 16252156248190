import { useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import AddImage from "./AddImage/AddImage";
import BackgroundColor from './StylingComponents/BackgroundColor';
import Width from "./StylingComponents/Width";
import BoxSizing from "./StylingComponents/BoxSizing";
import Padding from "./StylingComponents/Padding";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";
import ModalContext from "../../../store/ModalContext";

const ContainerWidget = () => {

  const builderCtx = useContext(BuilderContext);
  const modalCtx                = useContext(ModalContext);


  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };
  const { currentContentBlock } = builderCtx;


  return (
    <>
      <DynamicStylingTool widgetName={"ImageWidget"} propertiesArray={[
        <button className="add-image-btn" onClick={()=>{toggleModalHandler('image-modal',{blockId:currentContentBlock?.id, key:currentContentBlock?.id})}}>Add Image</button>
      ]} />
    </>
  )
}



export default ContainerWidget;

