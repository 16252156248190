import { MenuItem, TextField } from '@material-ui/core';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";
import { useContext, useEffect, useState } from "react";

export default function Margin(props) {
  const { blockId, screenType } = props;

  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;

  const getCurrentBlockStyles = () => {
    switch (screenType) {
      case "mobile":
        return currentContentBlock?.mobileStyling;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      default:
        return currentContentBlock?.styling;
    }
  }

  const separateValue = (input) => {
    const regex = /^(\d+)([a-zA-Z%]+)$/;
    const match = input?.match(regex);
    if (match) {
      return parseInt(match[1]);
    }
    else if (input === 'auto') {
      return 'auto';
    }
    return null;
  }

  const separateUnit = (input) => {
    const unitRegex = /[a-z%]+$/i;
    const match = input?.match(unitRegex);
    if (match) {
      return match[0];
    }
    return "unset";
  }

  const [currentBlockStyles, setCurrentBlockStyles] = useState(getCurrentBlockStyles());
  const [marginValues, setMarginValues] = useState({ "ml": separateValue(currentBlockStyles?.marginLeft), "mr": separateValue(currentBlockStyles?.marginRight), "mt": separateValue(currentBlockStyles?.marginTop), "mb": separateValue(currentBlockStyles?.marginBottom) });
  const [units, setUnits] = useState({ "ml": separateUnit(currentBlockStyles?.marginLeft), "mr": separateUnit(currentBlockStyles?.marginRight), "mt": separateUnit(currentBlockStyles?.marginTop), "mb": separateUnit(currentBlockStyles?.marginBottom) });

  const handleValues = (e, type) => {
    const value = e.target.value === "" ? null : e.target.value;
    switch (type) {
      case "ml":
        if (value) {
          setMarginValues({ ...marginValues, ml: value });
          if ( units.ml === "auto" || units.ml === "unset" ) {
            updateMarginInDb('marginLeft', value, "px");
            setUnits({ ...units, ml: "px" });
          } else {
            updateMarginInDb('marginLeft', value, units.ml);
          }
        } else {
          setMarginValues({ ...marginValues, ml: "" });
          setUnits({ ...units, ml: "unset" });
          updateMarginInDb('marginLeft', "", "unset");
        }
        break;
      case "mr":
        if (value) {
          setMarginValues({ ...marginValues, mr: value });
          if ( units.mr === "auto" || units.mr === "unset" ) {
            updateMarginInDb('marginRight', value, "px");
            setUnits({ ...units, mr: "px" });
          } else {
            updateMarginInDb('marginRight', value, units.mr);
          }
        } else {
          setMarginValues({ ...marginValues, mr: "" });
          setUnits({ ...units, mr: "unset" });
          updateMarginInDb('marginRight', "", "unset");
        }
        break;
      case "mt":
        if (value) {
          setMarginValues({ ...marginValues, mt: value });
          if ( units.mt === "auto" || units.mt === "unset" ) {
            updateMarginInDb('marginTop', value, "px");
            setUnits({ ...units, mt: "px" });
          } else {
            updateMarginInDb('marginTop', value, units.mt);
          }
        } else {
          setMarginValues({ ...marginValues, mt: "" });
          setUnits({ ...units, mt: "unset" });
          updateMarginInDb('marginTop', "", "unset");
        }
        break;
      case "mb":
        if (value) {
          setMarginValues({ ...marginValues, mb: value });
          if ( units.mb === "auto" || units.mb === "unset" ) {
            updateMarginInDb('marginBottom', value, "px");
            setUnits({ ...units, mb: "px" });
          } else {
            updateMarginInDb('marginBottom', value, units.mb);
          }
        } else {
          setMarginValues({ ...marginValues, mb: "" });
          setUnits({ ...units, mb: "unset" });
          updateMarginInDb('marginBottom', "", "unset");
        }
        break;
      default:
        break;
    }
  };
  
  const handleUnits = (e, type) => {
    const value = e.target.value;
    switch (type) {
      case "ml":
        if (value === 'auto') {
          setMarginValues({ ...marginValues, ml: "auto" });
          setUnits({ ...units, ml: "auto" });
          updateMarginInDb('marginLeft', "auto", "")
        }
        else if (value === 'unset') {
          setUnits({ ...units, ml: value });
          setMarginValues({ ...marginValues, ml: "" });
          updateMarginInDb('marginLeft', "unset", "")
        }
        else {
          if (marginValues.ml === 'auto') {
            setUnits({ ...units, ml: value });
            setMarginValues({ ...marginValues, ml: "0" });
            updateMarginInDb('marginLeft', 0, value)
          }
          else {
            setUnits({ ...units, ml: value });
            updateMarginInDb('marginLeft', marginValues.ml, value)
          }
        }
        break;
      case "mr":
        if (value === 'auto') {
          setMarginValues({ ...marginValues, mr: "auto" });
          setUnits({ ...units, mr: "auto" });
          updateMarginInDb('marginRight', "auto", "")
        }
        else if (value === 'unset') {
          setUnits({ ...units, mr: value });
          setMarginValues({ ...marginValues, mr: "" });
          updateMarginInDb('marginRight', "unset", "")
        }
        else {
          if (marginValues.mr === 'auto') {
            setUnits({ ...units, mr: value });
            setMarginValues({ ...marginValues, mr: "0" });
            updateMarginInDb('marginRight', 0, value)
          }
          else {
            setUnits({ ...units, mr: value });
            updateMarginInDb('marginRight', marginValues.mr, value)
          }
        }
        break;
      case "mt":
        if (value === 'auto') {
          setMarginValues({ ...marginValues, mt: "auto" });
          setUnits({ ...units, mt: "auto" });
          updateMarginInDb('marginTop', "auto", "")
        }
        else if (value === 'unset') {
          setUnits({ ...units, mt: value });
          setMarginValues({ ...marginValues, mt: "" });
          updateMarginInDb('marginTop', "unset", "")
        }
        else {
          if (marginValues.mt === 'auto') {
            setUnits({ ...units, mt: value });
            setMarginValues({ ...marginValues, mt: "0" });
            updateMarginInDb('marginTop', 0, value)
          }
          else {
            setUnits({ ...units, mt: value });
            updateMarginInDb('marginTop', marginValues.mt, value)
          }
        }
        break;
      case "mb":
        if (value === 'auto') {
          setMarginValues({ ...marginValues, mb: "auto" });
          setUnits({ ...units, mb: "auto" });
          updateMarginInDb('marginBottom', "auto", "")
        }
        else if (value === 'unset') {
          setUnits({ ...units, mb: value });
          setMarginValues({ ...marginValues, mb: "" });
          updateMarginInDb('marginBottom', "unset", "")
        }
        else {
          if (marginValues.mb === 'auto') {
            setUnits({ ...units, mb: value });
            setMarginValues({ ...marginValues, mb: "0" });
            updateMarginInDb('marginBottom', 0, value)
          }
          else {
            setUnits({ ...units, mb: value });
            updateMarginInDb('marginBottom', marginValues.mb, value)
          }
        }
        break;
    }
  };

  const updateMarginInDb = (type, value, unit) => {
    const payload = {
      id: blockId,
      value: value + unit,
      screenType: screenType
    };

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/' + [type], {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating margin payload", payload);
        }
      })
      .catch((err) => {
        console.error("Failed to update margin...", err);
      });

    switch (screenType) {
      case "mobile":
        updateContentBlocks(contentBlocks.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              mobileStyling: {
                ...block.mobileStyling,
                [type]: value + unit
              }
            };
          }
          return block;
        }));
        break;
      case "horizontal":
        updateContentBlocks(contentBlocks.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              horizontalStyling: {
                ...block.horizontalStyling,
                [type]: value + unit
              }
            };
          }
          return block;
        }));
        break;
      case "vertical":
        updateContentBlocks(contentBlocks.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              verticalStyling: {
                ...block.verticalStyling,
                [type]: value + unit
              }
            };
          }
          return block;
        }));
        break;
      default:
        updateContentBlocks(contentBlocks.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              styling: {
                ...block.styling,
                [type]: value + unit
              }
            };
          }
          return block;
        }));
        break;
    }
  }

  useEffect(() => {
    setCurrentBlockStyles(getCurrentBlockStyles());
  }, [units, marginValues, currentContentBlock]);

  useEffect(() => {
    setMarginValues({ "ml": separateValue(currentBlockStyles?.marginLeft), "mr": separateValue(currentBlockStyles?.marginRight), "mt": separateValue(currentBlockStyles?.marginTop), "mb": separateValue(currentBlockStyles?.marginBottom) });
    setUnits({ "ml": separateUnit(currentBlockStyles?.marginLeft), "mr": separateUnit(currentBlockStyles?.marginRight), "mt": separateUnit(currentBlockStyles?.marginTop), "mb": separateUnit(currentBlockStyles?.marginBottom) });
    console.log("currentBlockStyles", currentBlockStyles)
    console.log("currentBlockStyles marginLeft", separateValue(currentBlockStyles?.marginLeft))
  }, [currentBlockStyles]);

  const marginInput = () => {
    return (
      <div className="margin-input-container">
        <div className="mt-input margin-input">
          <div className="input-heading">Top</div>
          <div className="input-container">
            <input type="number" value={marginValues.mt} placeholder={marginValues.mt} onChange={(e) => handleValues(e, 'mt')} />
            <TextField className="margin-unit styling-select" select variant="standard" onChange={(e) => handleUnits(e, 'mt')} InputProps={{ disableUnderline: true }} placeholder={units.mt} value={units.mt}>
              <MenuItem value={'px'}>px</MenuItem>
              <MenuItem value={'rem'}>rem</MenuItem>
              <MenuItem value={'%'}>%</MenuItem>
              <MenuItem value={'vh'}>vh</MenuItem>
              <MenuItem value={'vw'}>vw</MenuItem>
              <MenuItem value={'auto'}>auto</MenuItem>
              <MenuItem value={'unset'}>unset</MenuItem>
            </TextField>
          </div>
        </div>
        <div className="mr-input margin-input">
          <div className="input-heading">Right</div>
          <div className="input-container">
            <input type="number" value={marginValues.mr} placeholder={marginValues.mr} onChange={(e) => handleValues(e, 'mr')} />
            <TextField className="margin-unit styling-select" select variant="standard" onChange={(e) => handleUnits(e, 'mr')} InputProps={{ disableUnderline: true }} placeholder={units.mr} value={units.mr}>
              <MenuItem value={'px'}>px</MenuItem>
              <MenuItem value={'rem'}>rem</MenuItem>
              <MenuItem value={'%'}>%</MenuItem>
              <MenuItem value={'vh'}>vh</MenuItem>
              <MenuItem value={'vw'}>vw</MenuItem>
              <MenuItem value={'auto'}>auto</MenuItem>
              <MenuItem value={'unset'}>unset</MenuItem>
            </TextField>
          </div>
        </div>
        <div className="mb-input margin-input">
          <div className="input-heading">Bottom</div>
          <div className="input-container">
            <input type="number" value={marginValues.mb} placeholder={marginValues.mb} onChange={(e) => handleValues(e, 'mb')} />
            <TextField className="margin-unit styling-select" select variant="standard" onChange={(e) => handleUnits(e, 'mb')} InputProps={{ disableUnderline: true }} placeholder={units.mb} value={units.mb}>
              <MenuItem value={'px'}>px</MenuItem>
              <MenuItem value={'rem'}>rem</MenuItem>
              <MenuItem value={'%'}>%</MenuItem>
              <MenuItem value={'vh'}>vh</MenuItem>
              <MenuItem value={'vw'}>vw</MenuItem>
              <MenuItem value={'auto'}>auto</MenuItem>
              <MenuItem value={'unset'}>unset</MenuItem>
            </TextField>
          </div>
        </div>
        <div className="ml-input margin-input">
          <div className="input-heading">Left</div>
          <div class="input-container">
            <input type="number" value={marginValues.ml} placeholder={marginValues.ml} onChange={(e) => handleValues(e, 'ml')} />
            <TextField className="margin-unit styling-select" select variant="standard" onChange={(e) => handleUnits(e, 'ml')} InputProps={{ disableUnderline: true }} placeholder={units.ml} value={units.ml}>
              <MenuItem value={'px'}>px</MenuItem>
              <MenuItem value={'rem'}>rem</MenuItem>
              <MenuItem value={'%'}>%</MenuItem>
              <MenuItem value={'vh'}>vh</MenuItem>
              <MenuItem value={'vw'}>vw</MenuItem>
              <MenuItem value={'auto'}>auto</MenuItem>
              <MenuItem value={'unset'}>unset</MenuItem>
            </TextField>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='tool'>
      {props.deleteModal}
      {marginInput()}
    </div>
  );
}
