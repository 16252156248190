import React, { useContext } from 'react'
import BuilderContext from '../../../store/BuilderContext';
import BackgroundColor from './StylingComponents/BackgroundColor';
import Color from './StylingComponents/Color';
import Font from './StylingComponents/Font';
import Margin from './StylingComponents/Margin';
import MaxWidth from './StylingComponents/MaxWidth';
import Padding from './StylingComponents/Padding';
import TextAlign from './StylingComponents/TextAlign';
import Width from './StylingComponents/Width';
import DynamicStylingTool from './DynamicStylingTool/DynamicStylingTool';

const QuestionWidget = (props) => {
  console.log("SectionWidget props", props);
  
  // const blockId = props?.blockId;
  // console.log("SectionWidget blockId", blockId);

  const builderCtx = useContext (BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  console.log("currentContentBlock", currentContentBlock);
  
  const currentBlock = contentBlocks?.find(block => block.contentType === "Question");
  const blockId = contentBlocks?.find(block => block.contentType === "Question")?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log("currentBlock", currentBlock);

  return (
    <div>
      <p style={{ textAlign: "center", fontSize: "16px", fontWeight: 700 }}>Questions Widget</p>
      {/* <BackgroundColor blockId={blockId} />
      <Color blockId={blockId} />
      <Padding blockId={blockId} />
      <Margin blockId={blockId} />
      <Width blockId={blockId} />
      <MaxWidth blockId={blockId} />
      <Font blockId={blockId} />
      <TextAlign blockId={blockId} /> */}
      <DynamicStylingTool/>
    </div>
  );
}



export default QuestionWidget;
