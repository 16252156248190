import React, { useContext, useEffect, useState } from "react";
import { Color, Display, Background, BackgroundColor, Height, Gap, BorderRadius, Border, BackgroundPosition, BackgroundRepeat, BackgroundSize, Font, FontSize, FontWeight, TextAlign, Width, BoxSizing, LetterSpacing, LineHeight, MaxWidth, MaxHeight, MinWidth, Position, TextTransform, Padding, Margin, JustifyContent, AlignItems, FlexDirection, MinHeight } from "../StylingComponents";
import BuilderContext from "../../../../store/BuilderContext";
import allCssJson from '../../../../assets/json/AllCssJson.json'
import ModalContext from "../../../../store/ModalContext";
import { Add, Delete } from "@material-ui/icons";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import desktopIcon from "../../../../assets/img/builder-images/desktop-icon.svg";
import horizontalTabIcon from "../../../../assets/img/builder-images/horizontal-tab.svg";
import verticalTabIcon from "../../../../assets/img/builder-images/vertical-tab.svg";
import phoneIcon from "../../../../assets/img/builder-images/phone-icon.svg";
import ImageWidgetTabs from "../ImageWidget/ImageWidgetTabs";
import { useParams } from "react-router-dom";

const DynamicStylingTool = (props) => {
  const builderCtx = useContext(BuilderContext);
  const modalCtx = useContext(ModalContext);

  const { contentBlocks, currentContentBlock, isCtrlPressed, setIsCtrlPressed, currentDeviceView } = builderCtx;
  console.log(contentBlocks);
  console.log(currentContentBlock);

  const [currentBlock, setCurrentBlock] = useState(() => contentBlocks?.find(block => block.id === currentContentBlock?.id));
  const [blockId, setBlockId] = useState(() => currentBlock?.id);

  const [currentBlockStyles, setCurrentBlockStyles] = useState(() => currentBlock?.styling);
  const [currentBlockMobileStyles, setCurrentBlockMobileStyles] = useState(() => currentBlock?.mobileStyling);
  const [currentBlockStylesArray, setCurrentBlockStylesArray] = useState(() => currentBlockStyles ? Object?.keys(currentBlockStyles) : []);
  const [currentBlockMobileStylesArray, setCurrentBlockMobileStylesArray] = useState(() => currentBlockMobileStyles ? Object?.keys(currentBlockMobileStyles) : []);
  const [componentHistory, setComponentHistory] = useState([]);
  const [tabValue, setTabValue] = useState('1');


  const { id } = useParams();

  const componentsMap = {
    Color,
    Background,
    BackgroundColor,
    Height,
    Width,
    BorderRadius,
    Border,
    BackgroundPosition,
    BackgroundRepeat,
    BackgroundSize,
    FontSize,
    FontWeight,
    TextAlign,
    BoxSizing,
    LetterSpacing,
    LineHeight,
    MaxWidth,
    MaxHeight,
    MinWidth,
    MinHeight,
    Position,
    TextTransform,
    FlexDirection,
    Display,
    JustifyContent,
    AlignItems,
    Margin,
    Padding,
    Gap
  };

  const capitalize = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const toggleModalHandler = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue)
  }

  const deleteBtn = (name, screenType) => {
    let filteredName = allCssJson.find(properties => properties.name === name);

    if (name.startsWith('padding')) {
      filteredName = allCssJson.find(properties => properties.name.startsWith('padding'));
    }
    else if (name.startsWith('margin')) {
      filteredName = allCssJson.find(properties => properties.name.startsWith('margin'));
    }

    if (name.startsWith('border')) {
      return (
        <>
          <div className="styling-text-delete-icon-container">
            <div className="delete-icon" onClick={() => { toggleModalHandler('page-builder-delete-styles-modal', { name, screenType }) }}><Delete /></div>
          </div>
        </>
      );
    }
    else {
      return (
        <>
          <div className="styling-text-delete-icon-container">
            <p className="styling-text dynamic-styling-text">{filteredName?.userFriendlyName}</p>
            <div className="delete-icon" onClick={() => { toggleModalHandler('page-builder-delete-styles-modal', { name, screenType }) }}><Delete /></div>
          </div>
        </>
      );
    }
  }

  const stylingTab = () => {

    let widgetEdgeCases = null;

    switch (props?.widgetName) {
      case 'ImageWidget':
        widgetEdgeCases = <ImageWidgetTabs>
          {{
            imageTab: props?.imageTab,
            positioningTab: props?.positioningTab,
          }}
        </ImageWidgetTabs>
        break;
      default:
        widgetEdgeCases = null;
    }

    return <>
      {widgetEdgeCases}
      {
        currentBlockStylesArray.map((componentName, index) => {
          return renderComponent(componentName, index, 'desktop');
        })
      }
      {/* {
        currentBlockMobileStylesArray.length !== 0 ? (
          <>
            <p style={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "700", textAlign: "center", color: "#122845" }}>Mobile Styles</p>
            {
              currentBlockMobileStylesArray.map((componentName, index) => {
                return renderComponent(componentName, index, 'mobile');
              })
            }
          </>
        ) : null
      } */}
      <button className="add-default-styles-btn" onClick={() => {
        toggleModalHandler("page-builder-add-new-style-modal", { id, "styling": "default" });
      }}>
        <Add />
        <span>Add Styling</span>
      </button>
    </>
  }

  const currentDeviceNameAndIcon = () => {
    switch (currentDeviceView) {
      case "Horizontal":
        return <>
          <img src={horizontalTabIcon} alt="" srcset="" />
          <span>Horizontal Tablet Styling</span>
        </>;
      case "Vertical":
        return <>
          <img src={verticalTabIcon} alt="" srcset="" />
          <span>Vertical Tablet Styling</span>
        </>
      case "Mobile":
        return <>
          <img src={phoneIcon} alt="" srcset="" />
          <span>Mobile Styling</span>
        </>
      default:
        return <>
          {/* <img src={desktopIcon} alt="" srcset="" />
          <span>Desktop Styling</span> */}
        </>
    }
  }


  const renderComponent = (componentName, index, screenType) => {
    const Component = componentsMap?.[capitalize(componentName)];
    const DeleteBtn = deleteBtn(componentName, screenType)
    // console.log("Are we rendering");
    if (Component) {
      return <Component key={index} blockId={blockId} screenType={screenType} deleteModal={DeleteBtn} />;
    }

    // if ( componentName.startsWith('padding')) {
    //   return <Padding blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
    // }
    // else if ( componentName.startsWith("margin")) {
    //   return <Margin blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
    // }

    switch (componentName) {
      // case 'alignItems':
      //   if ( !currentBlockStylesArray.includes('justifyContent') ) {
      //     return <HoriVertiPositioning key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      //   }
      //   return;
      // break;
      // case 'justifyContent':
      //   return <HoriVertiPositioning key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      // break;
      // case 'paddingLeft':
      //   return <Padding blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      // break;
      // case 'marginLeft':
      //   return <Margin blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      // break;
      // case 'fontSize':
      //   if ( !currentBlockStylesArray.includes('fontWeight') ) {
      //     return <Font key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      //   } 
      //   return;
      // break;
      // case 'fontWeight':
      //   return <Font key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      // break;
      case 'borderColor':
        return <Border key={index} blockId={blockId} screenType={screenType} deleteModal={DeleteBtn} />;
        break;
      default:
        return null;
    }

  };

  const updateCurrentBlockStylesArray = (currentBlockStyles) => {
    const tempArray = Object.keys(currentBlockStyles);

    const allPaddings = ['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom'];
    const allMargins = ['marginLeft', 'marginRight', 'marginTop', 'marginBottom'];

    const currentBlockStylesTemp = new Set(tempArray);

    const hasAnyPadding = allPaddings.some(paddingKey => currentBlockStylesTemp.has(paddingKey));

    if (hasAnyPadding) {
      allPaddings.forEach(paddingKey => currentBlockStylesTemp.delete(paddingKey));
      currentBlockStylesTemp.add('padding');
    }

    const hasAnyMargin = allMargins.some(marginKey => currentBlockStylesTemp.has(marginKey));

    if (hasAnyMargin) {
      allMargins.forEach(marginKey => currentBlockStylesTemp.delete(marginKey));
      currentBlockStylesTemp.add('margin');
    }

    setCurrentBlockStylesArray(currentBlockStylesTemp ? [...currentBlockStylesTemp] : []);
  };


  const updateCurrentBlockMobileStylesArray = (currentBlockMobileStyles) => {

    console.log('currentContentBlock+', currentContentBlock, "currentBlockMobileStyles", currentBlockMobileStyles)

    const tempArray = Object.keys(currentBlockMobileStyles);

    const allPaddings = ['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom'];
    const allMargins = ['marginLeft', 'marginRight', 'marginTop', 'marginBottom'];

    const currentBlockMobileStylesTemp = new Set(tempArray);

    const hasAnyPadding = allPaddings.some(paddingKey => currentBlockMobileStylesTemp.has(paddingKey));

    if (hasAnyPadding) {
      allPaddings.forEach(paddingKey => currentBlockMobileStylesTemp.delete(paddingKey));
      currentBlockMobileStylesTemp.add('padding');
    }

    const hasAnyMargin = allMargins.some(marginKey => currentBlockMobileStylesTemp.has(marginKey));

    if (hasAnyMargin) {
      allMargins.forEach(marginKey => currentBlockMobileStylesTemp.delete(marginKey));
      currentBlockMobileStylesTemp.add('margin');
    }

    setCurrentBlockMobileStylesArray(currentBlockMobileStylesTemp ? [...currentBlockMobileStylesTemp] : []);
  };




  useEffect(() => {
    // console.log('currentContentBlock from dynamicstyling',currentContentBlock)
    setCurrentBlock(currentContentBlock);
  }, [currentContentBlock]);

  useEffect(() => {
    setCurrentBlockStyles(currentBlock?.styling);
    setCurrentBlockMobileStyles(currentBlock?.mobileStyling);
    // setCurrentBlockMobileStylesArray(Object?.keys(currentBlockMobileStyles));
    // setCurrentBlockStylesArray(currentBlockStyles ? Object.keys(currentBlockStyles) : []);
    setBlockId(currentBlock?.id);
  }, [currentBlock, currentBlockStyles, currentContentBlock]);

  useEffect(() => {
    updateCurrentBlockStylesArray(currentBlockStyles)
    updateCurrentBlockMobileStylesArray(currentBlockMobileStyles)
  }, [currentBlockStyles, currentBlockMobileStyles, currentContentBlock])


  // useEffect(()=>{ console.log('currentBlockStylesArray',currentBlockStylesArray) },[currentBlockStylesArray])
  useEffect(() => { console.log('currentBlockMobileStylesArray', currentBlockMobileStylesArray) }, [currentBlockMobileStylesArray])


  return (
    <div className="dynamic-tools-container">
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="Styling" value="1" />
            <Tab label="Properties" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          {
            currentDeviceView !== 'Desktop' && <div className="add-styles-btn-container">
              <div className="current-device-view">
                {currentDeviceNameAndIcon()}
              </div>
              {
                <>
                  {/* <p style={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "700", textAlign: "center", color: "#122845" }}>Mobile Styles</p> */}
                  {
                    currentBlockMobileStylesArray?.map((componentName, index) => {
                      return renderComponent(componentName, index, 'mobile');
                    })
                  }
                </>
              }
              <button className="add-styles-btn" onClick={() => {
                toggleModalHandler("page-builder-add-new-style-modal", { id });
              }}>
                <Add />
                <span>Add Styling</span>
              </button>
            </div>
          }
          {stylingTab()}
        </TabPanel>
        <TabPanel value="2">
          {/* rendering properties from props here */}
          {
            props?.propertiesArray?.map((el) => {
              return el
            })
          }
        </TabPanel>
      </TabContext>

    </div>
  );
}

export default DynamicStylingTool;
