import { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, margin: "auto" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ImageWidgetTabs = ({ children }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} className='image-widget-tabs'>
      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="image tabs"
          variant="fullWidth"
          indicatorColor="none"
        >
          <Tab label="IMAGE" {...a11yProps(0)} />
          <Tab label="POSITIONING" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        {children.imageTab}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {children.positioningTab}
      </TabPanel> */}
        {children.imageTab}
    </Box>
  );
};

export default ImageWidgetTabs;