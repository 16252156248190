import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';

const TextAlign = (props) => {

  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  } 

  const currentBlockStyles = getCurrentBlockStyles()
  const currentAlignment   = currentBlockStyles?.textAlign;
  // console.log("currentAlignment", currentAlignment);
  
  const [alignment, setAlignment]   = useState(currentAlignment || "left");
  
  const alignments = [
    { value: 'left',   label: 'left'   },
    { value: 'center', label: 'center' },
    { value: 'right',  label: 'right'  },
  ];

  const handleAlignChange = (e) => setAlignment(e.target.value);

  useEffect(() => {
    
    // updateContentBlocks(contentBlocks?.map(block => {
    //   if (block.id === blockId) {
    //     let newStyles = { ...block.styling };
    //     newStyles.textAlign = alignment;
    //     block.styling = { ...newStyles };
    //     return block;
    //   } else return block;
    // }))

    switch ( screenType ) {
      case "mobile" : 
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              mobileStyling: {
                ...block.mobileStyling,
                textAlign: alignment
              }
            };
          }
          return block;
        }));
      break;
      case "horizontal" :
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              horizontalStyling: {
                ...block.horizontalStyling,
                textAlign: alignment
              }
            };
          }
          return block;
        }));
      break;
      case "vertical" :
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              verticalStyling: {
                ...block.verticalStyling,
                textAlign: alignment
              }
            };
          }
          return block;
        }));
      break;
      default:
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              styling: {
                ...block.styling,
                textAlign: alignment
              }
            };
          }
          return block;
        }));
      break;
    }

  }, [blockId, alignment]);


  // console.log("blockId", blockId);
  // console.log("alignment", alignment);

  return (
    <div className="font-styles tool">

      {props.deleteModal}
      <TextField id="select-style-unit" className="textalign-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }} placeholder={currentContentBlock.styling?.textAlign + ""} value={alignment} onChange={(e) => { handleAlignChange(e); }} >
        {alignments?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>
  )
}


export default TextAlign;
