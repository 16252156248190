import React, { useContext, useState } from "react";
import BuilderContext from "../../../store/BuilderContext";
import { Button } from "@mui/material";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const StartPageTextWidget = () => {
  const builderCtx = useContext(BuilderContext);
  const { showStartPageImgVidSection, setShowStartPageImgVidSection } = builderCtx;

  return (
    <div>
      <p style={{ paddingLeft: "10px" }}>Start Page Text Widget</p>

      <DynamicStylingTool/>

      <div style={{ width: "100%", textAlign: "center" }}>
        {!showStartPageImgVidSection && 
          <Button onClick={() => setShowStartPageImgVidSection(true)}
            style={{
              backgroundColor: "#d1d1d1",
              color: "#343333",
              fontWeight: 600,
              fontFamily: `Montserrat, sans-serif`,
              padding: "7px 15px",
              margin: "15px auto",
              textTransform: "capitalize",
              border: "2px solid black"
            }}
          >
            Add Image / Video Section
          </Button>
        }
      </div>
    </div>
  );
}

export default StartPageTextWidget;
