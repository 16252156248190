import { useContext, useEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

import tempLogo from "../assets/img/temp-logo.png";
import bleatLogo from "../assets/img/logo-bleat.svg";
import useWindowDimensions from "../Hooks/useWindowDimensions";

import AuthContext from "../store/AuthContext";
import BuilderContext from "../store/BuilderContext";


import { Button } from "@mui/material";
import { hideSidebar } from "../globalFunctions";

import Tools from "../Components/AssessmentBuilder/Tools/Tools";
import Builder from "../Components/AssessmentBuilder/Builder";
// const  Tools = lazy(() => import("../Components/AssessmentBuilder/Tools/Tools"));
// const  Builder = lazy(() => import("../Components/AssessmentBuilder/Builder"));

const AssessmentBuilder = () => {

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const { windowWidth } = useWindowDimensions();

  const authCtx = useContext(AuthContext);
  const builderCtx = useContext(BuilderContext);

  const { startPage, setStartPage, activePages, setActivePages, globalProps, currentPage, changeCurrentPage, pagesStatus } = builderCtx;

  useEffect(() => {

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/builder/check', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        if(data.isStarted) {
          setIsLoading(false);
        } else {
          createStartPage();
        }
      } else {
        // setOpenSnackbar(true);
      }
    });

  }, [authCtx.token, id]);

  const createStartPage = () => {

    let payload = {pageType: 'start'}

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/page', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);      
      if(data.status == "success") {
        setIsLoading(false);
      }
    });

  }

  const displayCurrentPage = () => {

    // switch (activePages) {
    //   case value:
    //     break;
    //   default:
    //     break;
    // }
  }

  // console.log("builderCtx Builder Page:", builderCtx);

  // console.log("\n\ncurrPageIndex", pagesStatus?.findIndex((page) => page.active === true), pagesStatus, "\n\n");

  useEffect(() => {
    // hide sidebar on initial load of builder page 
    hideSidebar();
  }, []);

  if (isLoading) {
		return <section><CircularProgress /></section>
	}


  return (
    <div className="builder-container">
      <div className="builder">
        <div className="tools">
          {/* <Suspense fallback={<div>Error loading Tools...</div>}> */}
            <Tools />
          {/* </Suspense> */}
        </div>
        <div className="build">
          {/* <Suspense fallback={<div>Error loading Builder...</div>}> */}
            <Builder />
          {/* </Suspense> */}
        </div>
      </div>
    </div>
  )
}



export default AssessmentBuilder;
