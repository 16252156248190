import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { Button } from '@mui/material';
import { Add } from '@material-ui/icons';

import BuilderContext from '../../store/BuilderContext';
import temperature from "../../assets/img/temperature.png"
import barchart from "../../assets/img/barchart.png"
import spiderweb from "../../assets/img/spiderweb.png"

const Dashboard = () => {


  const { id } = useParams();
  const builderCtx = useContext(BuilderContext);

  const { contentBlocks, updateContentBlocks, accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, changeCurrentPage } = builderCtx;

  const [selectedContentBlockId, setSelectedContentBlockId] = useState(0);

  // const accessToolsById = (e, id) => {
  //   e.stopPropagation();
  //   let contentBlock = builderCtx?.contentBlocks?.find(obj => { return obj.id == id });
  //   setSelectedContentBlockId(contentBlock?.id);
  //   builderCtx?.updateCurrentBlock(contentBlock);
  // }

  // const setStyle = (id) => {
  //   let contentBlock = builderCtx?.contentBlocks?.find(obj => { return obj.id == id });
  //   if (contentBlock && contentBlock.id == selectedContentBlockId) {
  //     let styling = { ...contentBlock.styling, border: "solid 1px #7FB6BE" };
  //     return styling;
  //   } else if (contentBlock && contentBlock.styling) {
  //     return contentBlock.styling;
  //   }
  //   return null;
  // }

  // const updateContentText = (e, id) => {
  //   builderCtx.updateContentBlocks(builderCtx.contentBlocks?.map(block => {
  //     if (block.id == id) {
  //       block.properties.text = e.target.value;
  //       return block;
  //     }
  //     return block;
  //   }))
  // }

  const parentId = contentBlocks?.find(block => block?.contentType === "DashboardContainer")?.id;
  const dashboardContainer = contentBlocks?.find(block => block?.contentType === "DashboardContainer");
  const resultsHeading = contentBlocks?.find(block => block?.contentType === "ResultsHeading");
  const resultsText = contentBlocks?.find(block => block?.contentType === "ResultsText");

  // console.log("currentContentBlock", currentContentBlock);

  // const updateResultsType = (type) => {
  //   updateContentBlocks(contentBlocks?.map(block => {
  //     if (block.id === resultsContainer.id) {
  //       block.properties.resultsType = type;
  //       return block;
  //     } else return block;
  //   }))
  // }

  let properties = {
    text: "Select your result page type:",
    resultsTypesArray: [
      { img: temperature, type: "temperature", title: "Gradient Line Bar", info: "Display the results on a gradient scale based on the calculation. Default is the percentage average" },
      { img: barchart, type: "barchart", title: "Category bars", info: "Display each category as a bar in relation to the entire represented categories" },
      { img: spiderweb, type: "spiderweb", title: "Spiderweb chart", info: "Display each category represented on a spoke of the spiderweb chart" },
    ],
  };

  const selectedTypeStyle = (type) => {
    if (dashboardContainer?.properties?.resultsType === type) {
      return { width: "31%", borderRadius: "26px", textAlign: "center", display: "inline-block", cursor: "pointer", border: "4px solid #70707070", boxShadow: "0 0 10px 1px #70707070" }
    } else return { width: "31%", borderRadius: "26px", textAlign: "center", display: "inline-block", cursor: "pointer", border: "4px dashed #70707070" }
  }

  return (
    <div onClick={(e) => accessToolsByPageArea("dashboard", e)} style={setSectionStyle("dashboard")}>
      <h1>Dashboard</h1>
      {
        builderCtx.contentBlocks?.map((data, i) => {
          // console.log(data); console.log(parentId);
          if(data?.parentContainer === parentId && data?.pageArea === "invite") {
            
            
          }
        })
      }
      
    </div>
  );

  // return <div className="results-page">
    
  //   <div className="results-header-container">

  //     <div className="results-header" style={{ border: "2px dashed #77777777", display: "flex", alignItems: "center", position: "relative" }}>

  //       <div>
  //         <TextWidgetQuill className="editable-text" />
  //       </div>
  //       <div className="info" style={{ position: "absolute", right: "10px", fontSize: "12px", fontWeight: 600 }}>
  //         UPDATE COPY BASED ON SCORE
  //       </div>
  //     </div>

  //   </div>

  //   <h3 style={{ fontSize: "18px", color: "#212121", opacity: "0.5", textAlign: "center", margin: "60px auto 15px" }}>SELECT YOUR RESULT PAGE TYPE:</h3>

  //   <div className="results-types" style={{ display: "flex", justifyContent: "space-between", marginTop: "15px", marginRight: "auto", marginBottom: 0, marginLeft: "auto", }}>
  //     {properties?.resultsTypesArray?.map(type => {
  //       return (<div className="result-type" style={selectedTypeStyle(type.type)}
  //         onClick={() => updateResultsType(type.type)} >

  //         <div className="img-container" style={{ height: "120px", width: "300px", margin: "0 auto" }} >
  //           <img src={type.img} alt={type.title} style={{ height: "auto", width: "auto", maxWidth: "100%", maxHeight: "100%", marginTop: "10px" }} />
  //         </div>

  //         <h3 style={{ color: "#212121", opacity: "0.5", fontSize: "23px" }}>{type.title}</h3>
  //         <p style={{ color: "#212121", opacity: "0.5", fontSize: "18px", fontWeight: 600, maxWidth: "90%", margin: "15px auto 30px" }}>{type.info}</p>

  //       </div>)
  //     })}

  //   </div>

  //   <div className="results-text" style={{ border: "2px dashed #77777777", display: "flex", alignItems: "center", position: "relative", marginTop: "60px" }}>

  //     <div >
  //       <TextWidgetQuill className="editable-text" />
  //     </div>
  //     <div className="info" style={{ position: "absolute", right: "10px", fontSize: "12px", fontWeight: 600 }}>
  //       UPDATE COPY BASED ON SCORE
  //     </div>
  //   </div>



  // </div>;



}



export default Dashboard;
