import React, { useState, useContext, useEffect } from 'react'
import { Button, Box, Snackbar, CircularProgress } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import { ArrowBack } from '@material-ui/icons';
import AuthContext from '../../store/AuthContext';

const AssessmentChurchInsights = () => {

  const { id } = useParams();
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [assessDetails, setAssessDetails] = useState({});
  const [tableData, setTableData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [isShowNoUserDataSnakbar, setIsShowNoUserDataSnakbar] = useState(false);
  const [dioceseInputValue, setDioceseInputValue] = useState('');
  const [UserValue, setUserValue] = useState('');
  const [filterInputValue, setFilterInputValue] = useState('');
  const [filteredRows, setFilteredRows] = useState('');
  const [counts, setCounts] = useState()

  let replace = /Assessment/gi;
  let assessmentName = title.toString().replace(replace, "").trim();

  useEffect(() => {
    getAssessmentDetails();
    getChurchDetails();
  }, [id]);

  const envURI = process.env.REACT_APP_API_URI;
  const getAssessmentDetails = () => {
    let options = {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    fetch(`${envURI}/v1/admin/assessment/${id}`, options)
      .then(response => response.json())
      .then((data) => {
        if (data.status === "success") {
          setTitle(data?.details?.title);
          setAssessDetails(data?.details);
        }
      }).catch((err) => {
        console.log("Failed to fetch assessment details...", err);
      })
  }

  const getChurchDetails = () => {
    let options = {
      mathod :'GET', mode: 'cors',
      header: { 'Content-type': 'application/json', 'Authorization': 'Bearer' + authCtx.token}
    }

    fetch(`${envURI}/v4/user/assessment/${id}/trellisInsights`, options)
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setTableData(data?.data)
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Failed to fetch Parish details...", err);
        setIsLoading(false);
      })
  }

  const getUsersDetails = (id) => {

    var options = { method:'GET', mode: 'cors', headers: { 'Content-type': 'application/json' } }

    fetch(`${envURI}/v4/user/assessment/church/${id}`, options)
    .then(res => res.json())
    .then(data => {
      if (data.status === 'success'){
        console.log("data?.data",data?.data)
        console.log("data?.data?.length",data?.data?.length)
        setUsersData(data?.data)
        setCounts(data?.counts)
        if(data?.data?.length == 0) {
          setIsShowNoUserDataSnakbar(true)
        }
      }
    }).catch((err) => console.error(err))
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 75 },
    { field: 'church', headerName: 'Church', width: 120, renderCell: (params) => <span className="custom-cell" onClick={(e) => {e.stopPropagation(); getUsersDetails(params.id)}}>{params.value}</span>},
    { field: 'boardMember', headerName: 'Board Member', width: 180, headerClassName: 'diocese-header', cellClassName: 'custom-diocese'},
    { field: 'internCoordinator', headerName: 'Intern Coordinator', width: 170, headerClassName: 'parish-header', cellClassName: 'custom-parish' },
    { field: 'churchStaff', headerName: 'Church Staff', width: 150 },
    { field: 'leadPastor', headerName: 'Lead Pastor', width: 150 },
    { field: 'congregant', headerName: 'Congregant', width: 150 },
  ];

  const usersColumns = [
    {field: 'userId', headerName: 'User ID', width: 300},
    {field: 'firstName', headerName: 'FirstName', width: 300},
    {field: 'lastName', headerName: 'LastName', width: 200},
    {field: 'email', headerName: 'Email', width: 200},
    {field: 'assessmentId', headerName: 'Assessment ID', width: 200},
    {field: 'uniqueId', headerName: 'Unique ID', width: 200},
    {field: 'parentId', headerName: 'Parent ID', width: 200},
    {field: 'designation', headerName: 'Designation', width: 200},
    {field: 'status', headerName: 'Status', width: 200},
    {field: 'score', headerName: 'score', width: 100},
    {field: 'date', headerName: 'Date', width: 300},
  ]

  const handleDioceseClick = (val) => {
    setDioceseInputValue(val);
    setFilterInputValue('')
    setFilteredRows(tableData.filter((item) => (item['diocese'].toLowerCase().includes(val.toLowerCase()))))
  }

  const handleFilterInputChange = (val) => {
    setDioceseInputValue('')
    setFilterInputValue(val)
    var inputValue = val.toLowerCase();
    var newRows = tableData.filter((item) => (item['country']?.toLowerCase()?.includes(inputValue) || item['diocese']?.toLowerCase()?.includes(inputValue) || item['parish']?.toLowerCase()?.includes(inputValue)))
    console.log('newRows: ',newRows)
    setFilteredRows(newRows);
  };

  const getLatestUsersId = () => {
    const users = usersData.filter(user => user.designation === 'lead pastor');
    console.log('users: ',users)
    console.log('usersData: ',usersData)
    users.sort((a, b) => new Date(b.date) - new Date(a.date));
    return users.length > 0 ? users[0].uniqueId : null;
  }
  
  const filterUserDate = (type) => {
    
    if(UserValue===type){
      setUserValue("all");
    }
    else{
      setUserValue(type);
      // setUsersDate(usersData.filter(row => row.designation.toLowerCase() === type.toLowerCase())); 
    }
  };


  return (
    <>
      <div className='assessment-page' style={{padding:'0'}}>
        <div className="assessment-name">
          <div data-aos="fade-right">
            <h1><a style={{ textDecoration: "none", }} href={"/assessments/" + id + "/dashboard"}>{assessmentName} Assessment</a></h1>
            {assessDetails?.url && <p className="assessment-link-text">Link to assessment: <a className="assessment-link" href={assessDetails?.url} target="_blank" rel="noopener noreferrer">{assessDetails?.url}</a></p>}
          </div>
        </div>

        <div className="parish-table">
          { usersData?.length === 0 ? <div>
            { isLoading ? <CircularProgress className='circular-progress' size={60} /> : <Box >
               <div className='filter-container'>
                 <div className="search-bar">
                   <input type="search" name="" id="search-field" className="search-field" placeholder="Type to search..." value={filterInputValue} onChange={(e) => handleFilterInputChange(e.target.value)}/>
                   {filterInputValue && <Clear className="clear-search-icon" onClick={() => setFilterInputValue("")} />}
                 </div>
                 { dioceseInputValue && <div className='diocese-chip'>
                   <p>{dioceseInputValue}</p>
                   <Clear onClick={() => setDioceseInputValue('')} sx={{color:'#fff', fontSize:'18px', cursor:'pointer'}}/>
                 </div> }
               </div>
               <DataGrid rows={(filterInputValue.length > 0 || dioceseInputValue.length > 0) ? filteredRows  : tableData} columns={columns} pageSize={50} rowsPerPageOptions={[5]} checkboxSelection />
             </Box> }
            
          </div>
          : <Box sx={{ height:'78vh !important' }}>
            <div className='users-table-top'>
              <div className="back-arrow">
                <ArrowBack onClick={() => setUsersData([])} sx={{ fontSize:'32px', cursor:'pointer' }}/>
                <p className='dashboard-link'>Link to the dashboard: <a className="assessment-link" href={`${assessDetails?.url}/dashboard/${getLatestUsersId()}`} target="_blank" rel="noopener noreferrer">{`${assessDetails?.url}/dashboard/${getLatestUsersId()}`}</a></p>
              </div>
              {/* <div className='counts'> */}
              {/* </div> */}
              
              <div className="statistics-cards">
                    <div className={`card ${UserValue==="Leaders"?"active":"inactive"}`} onClick={(e) => filterUserDate("Leaders")}>
                      <p className="big-text">{counts?.boardMember}</p>
                      <p className="info-text">Board Members</p>
                    </div>
                    <div className={`card ${UserValue==="Parishioner"?"active":"inactive"}`} onClick={(e) => filterUserDate("Parishioner")}>
                      <p className="big-text">{counts?.internCoordinator}</p>
                      <p className="info-text">Intern Coordinator</p>
                    </div>
                    <div className={`card ${UserValue==="Priest"?"active":"inactive"}`} onClick={(e) => filterUserDate("Priest")}>
                      <p className="big-text">{counts?.churchStaff}</p>
                      <p className="info-text">Church Staff</p>
                    </div>
                    <div className={`card ${UserValue==="Priest"?"active":"inactive"}`} onClick={(e) => filterUserDate("Priest")}>
                      <p className="big-text">{counts?.leadPastor}</p>
                      <p className="info-text">Lead Pastor</p>
                    </div>
                    <div className={`card ${UserValue==="Priest"?"active":"inactive"}`} onClick={(e) => filterUserDate("Priest")}>
                      <p className="big-text">{counts?.congregant}</p>
                      <p className="info-text">Congregant</p>
                    </div>
              </div>
            </div>
              <DataGrid rows={usersData} columns={usersColumns} pageSize={50} getRowId={(row) => row.userId} />
            </Box> }
          <Snackbar open={isShowNoUserDataSnakbar} autoHideDuration={4000} onClose={() => setIsShowNoUserDataSnakbar(false)} message="No users data for this Parish"/>
        </div>
      </div>
    </>
  )
}

export default AssessmentChurchInsights;