import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';

const TextTransform = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);

  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  } 

  const currentBlockStyles = getCurrentBlockStyles()
  const currentTextTransform = currentBlockStyles?.textTransform;

  // CSS text-transform property options
  const transformOptions = [
    { value: 'none',       label: 'None'       },
    { value: 'capitalize', label: 'Capitalize' },
    { value: 'uppercase',  label: 'Uppercase'  },
    { value: 'lowercase',  label: 'Lowercase'  }
  ];

  const [textTransform, setTextTransform] = useState(currentTextTransform || "none");

  const handleChange = (e) => {
    setTextTransform(e.target.value);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (textTransform && textTransform !== currentTextTransform) {
        // updateContentBlocks(contentBlocks?.map(block => {
        //   if (block.id === blockId) {
        //     let newStyles = { ...block.styling };
        //     newStyles.textTransform = textTransform;
        //     block.styling = { ...newStyles };
        //     return block;
        //   } else {
        //     return block;
        //   }
        // }));

        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    textTransform
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    textTransform
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    textTransform
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    textTransform
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }
      }
    }, 1000);

    return () => clearTimeout(timer);

  }, [blockId, textTransform]);

  return (
    <div className="text-transform tool">
      <div className="text-transform-select">
        {props.deleteModal}
        <TextField className="position-unit styling-select"
          select variant="standard" style={{ width: "120px" }}
          InputProps={{ disableUnderline: true }}
          value={textTransform} onChange={handleChange}
        >
          {transformOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
}

export default TextTransform;
