import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const Width = (props) => {

  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;

  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  }

  const currentBlockStyles = getCurrentBlockStyles()
  const currentWidth = currentBlockStyles?.width?.match(/[a-zA-Z]+|[0-9]+|[%]/g);

  const units = [
    { value: 'px', label: 'px' },
    { value: 'rem', label: 'rem' },
    { value: '%', label: '%' },
    { value: 'vh', label: 'vh' },
    { value: 'vw', label: 'vw' },
  ];

  const [width, setWidth] = useState(currentWidth?.[0] || "auto");
  const [unit, setUnit] = useState(currentWidth?.[1] || "px");
  const [finalValue, setFinalValue] = useState("");

  const [onChangeFlag, setOnChangeFlag] = useState(false);

  if (width !== "auto") localStorage.setItem("prevWidth", width);

  const handleUnitChange = (e) => {
    setOnChangeFlag(true);

    if (!e.target.value) {
      setWidth("auto");
    } else {
      setUnit(e.target.value);
      if (localStorage.getItem("prevWidth")) {
        setWidth(localStorage.getItem("prevWidth"));
      }
    }
  };

  const updateWidth = (widthValue, unit) => {
    let payload = {
      id: blockId,
      value: widthValue !== 'auto' ? widthValue + unit : widthValue,
      screenType: props?.screenType
    }

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/width', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating width payload", payload);
        }
      }).catch((err) => {
        console.error("Failed to update width...", err);
      });
  }

  useEffect(() => {
    if (width === "auto") {
      setFinalValue("auto");
    } else if (width && width !== "auto" && unit) {
      if (width !== currentWidth?.[0] || unit !== currentWidth?.[1]) {
        setFinalValue(`${width}${unit}`);
      }
    }
  }, [currentWidth, width, unit]);

  useEffect(() => {
    let lastFinalVal = "";
    if (!currentWidth?.[1]) {
      lastFinalVal = "auto";
    } else {
      lastFinalVal = currentWidth?.[0] + currentWidth?.[1];
    }

    if (onChangeFlag) {
      updateWidth(width, unit);
    }

    const timer = setTimeout(() => {
      if (finalValue && finalValue !== lastFinalVal) {
        // updateContentBlocks(contentBlocks?.map(block => {
        //   if (block.id === blockId) {
        //     let newStyles = { ...block.styling };
        //     newStyles.width = finalValue;
        //     block.styling = { ...newStyles };
        //     return block;
        //   } else {
        //     return block;
        //   }
        // }))

        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    width: finalValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    width: finalValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    width: finalValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    width: finalValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }
      }

      setOnChangeFlag(false);
    }, 500);

    return () => clearTimeout(timer);

  }, [blockId, finalValue, unit]);

  return (
    <div className="width tool">
      {props.deleteModal}
      <TextField id="width-number" className="width-input styling-input" type="number" variant="filled"
        InputLabelProps={{ shrink: true }} placeholder={width + ""} value={width}
        onChange={(e) => {
          if (e.target.value) {
            setWidth(e.target.value);
          } else {
            setWidth("auto");
          }
          setOnChangeFlag(true)
        }} />

      <TextField className="width-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
        placeholder={currentContentBlock.styling?.width + ""} value={unit} onChange={(e) => {
          handleUnitChange(e);
        }}>
        {units?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>
  )
}

export default Width;
