import { useState, useEffect, createContext } from "react";
import tempLogo from "../assets/img/temp-logo.png";
import bleatLogo from "../assets/img/logo-bleat.svg";
import playBtn from "../assets/img/play-button.png";
import { useLocation, useParams } from "react-router-dom";

const BuilderContext = createContext({
  currentPage: '',
  currentBlockId: 0,
  currentContentBlock: {},
  contentBlocks: [],
  currentPageId:0,
  updateContentBlocks: () => {},
  updateCurrentBlock: () => {},
  setSectionMobileStyling: () => {},
  getStylesAccordingToDevice: () => {},
});

export function BuilderContextProvider(props) {

  const [pagesStatus, setPagesStatus] = useState([
    { name: "start", optional: false, active: true },
    { name: "explainer", optional: false, active: false },
    { name: "assessment", optional: false, active: false },
    { name: "additional", optional: false, active: false },
    { name: "details", optional: false, active: false },
    { name: "results", optional: false, active: false },
    { name: "invite", optional: false, active: false },
    { name: "invitesuccess", optional: false, active: false },
    { name: "dashboard", optional: false, active: false },
  ]);

  const [currentPage, setCurrentPage]     = useState(pagesStatus?.filter(pg => pg.active === true)?.[0]);
  const [assessments, setAssessments]     = useState([])
  const [currentPageId, setCurrentPageId] = useState(0);

  useEffect(() => {
    setCurrentPage(pagesStatus?.filter(pg => pg.active === true)?.[0]);
  }, [pagesStatus]);


  const location = useLocation();
  const [assessmentId, setAssessmentId] = useState(null);
  
  useEffect(() => {
    let assessId = location?.pathname?.split("/builder/")?.[1];
    setAssessmentId(assessId);
  }, [location?.pathname]);

  const [currentBlockId, setCurrentBlockId]                         = useState(0);
  const [currentContentBlock, setCurrentContentBlock]               = useState({});
  const [selectMultipleBlocks, setSelectMultipleBlocks]             = useState([])
  const [isCtrlPressed, setIsCtrlPressed]                           = useState(false);
  
  const [contentBlocks, setContentBlocks]                           = useState([]);
  
  const [selectedContentBlockId, setSelectedContentBlockId]         = useState(0);

  const [refreshPagesAndContent, setRefreshPagesAndContent]         = useState(false);

  const [showStartPageImgVidSection, setShowStartPageImgVidSection] = useState(true);
  const [numOfQuestions, setNumOfQuestions]                         = useState(0);
  const [startPageVideoURL, setStartPageVideoURL]                   = useState("");
  const [startPageImageURL, setStartPageImageURL]                   = useState("");
  const [lastUploadedMedia, setLastUploadedMedia]                   = useState({ type: "", url: "" });
  const [currentDeviceView, setCurrentDeviceView]                   = useState('Desktop');
  const [pages, setPages]                                           = useState([])

  const updateContentBlocksHandler = (contents) => {
    setContentBlocks(contents);
  }

  const updateCurrentBlockHandler = (content) => {
    setCurrentContentBlock(content);
  }

  const newUpdateCurrentBlock = (property, value, screenType) => {
    switch (screenType) {
      case "mobile":
        const mobileStyling = { ...currentContentBlock.mobileStyling, [property]: value };
        const updatedCurrentContentBlockMobile = {
          ...currentContentBlock,
          mobileStyling: { ...currentContentBlock.mobileStyling, ...mobileStyling }
        };
        console.log('current block added mobile', updatedCurrentContentBlockMobile);
        setCurrentContentBlock(updatedCurrentContentBlockMobile);
  
        const updatedContentBlocksMobile = contentBlocks.map(block => {
          if (block.id === updatedCurrentContentBlockMobile.id) {
            return updatedCurrentContentBlockMobile;
          } else {
            return block;
          }
        });
  
        updateContentBlocksHandler(updatedContentBlocksMobile);
        break;
      default:
        const desktopStyling = {
          ...currentContentBlock,
          styling: { ...currentContentBlock.styling, [property]: value }
        };
        console.log('current block added desktop', desktopStyling);
        setCurrentContentBlock(desktopStyling);
  
        const updatedDesktopContentBlocks = contentBlocks.map(block => {
          if (block.id === desktopStyling.id) {
            return desktopStyling;
          } else {
            return block;
          }
        });
  
        updateContentBlocksHandler(updatedDesktopContentBlocks);
        break;
    }
  };

  const selectMultipleBlocksHandler = ( block  ) => {
    const [recentBlock] = selectMultipleBlocks.slice(-1);;
     if (recentBlock) {
      if ( recentBlock.parentContainer === currentContentBlock.parentContainer ) {
        setSelectMultipleBlocks([...selectMultipleBlocks, block])
       }
       else { 
        setSelectMultipleBlocks([])
       }
      console.log('selectMultipleBlocks Recent block:', recentBlock);
      console.log('selectMultipleBlocks currentContentBlock block:', currentContentBlock);
    } else {
      setSelectMultipleBlocks([...selectMultipleBlocks, block])
      console.log('selectMultipleBlocks No blocks selected.');
    }
  }

  // useEffect(()=>{
  //   console.log("selectMultipleBlocks: ",selectMultipleBlocks)
  // },[selectMultipleBlocks])

  useEffect(()=>{

    if ( isCtrlPressed && Object.keys(currentContentBlock).length !== 0 && !selectMultipleBlocks.includes(currentContentBlock) ) {
      selectMultipleBlocksHandler(currentContentBlock)
    }
    else if ( selectMultipleBlocks.includes(currentContentBlock) ) {
      if ( isCtrlPressed ) {
        setSelectMultipleBlocks(selectMultipleBlocks)
      }
    }
    else {
      const [recentBlock] = selectMultipleBlocks.slice(-1);;
      if (recentBlock) {
        if ( recentBlock.parentContainer !== currentContentBlock.parentContainer ) {
          setSelectMultipleBlocks([])
        }
      }
    }
  },[currentContentBlock,isCtrlPressed])
  

  const changeCurrentPage = (pageName) => {
    setPagesStatus(pagesStatus?.map(page => {
      if(page.name === pageName) {
        return { ...page, active: true }
      } else return { ...page, active: false }
    }));
  }

  const setCurrentObjStyles = (e, id, property, unit) => {
    setContentBlocks(contentBlocks?.map(block => {
      if(block.id === id) {
        let newStyles = { ...block.styling };
        newStyles[property] = e.target.value;
        block.styling = { ...newStyles };
        return block;
      } else return block;
    }))
  }

  const accessToolsByPageArea = (section, e) => {
    if(e) {e.stopPropagation();}
    let contentBlock = contentBlocks?.find(obj => { return obj?.pageArea == section && obj?.parentContainer == 0});
    setSelectedContentBlockId(contentBlock?.id);
    updateCurrentBlockHandler(contentBlock);
  }

  const setSectionStyle = (section) => {
    let contentBlock = contentBlocks?.find(obj => { return obj?.pageArea == section && obj?.parentContainer == 0 });
    if(contentBlock && contentBlock.id == selectedContentBlockId) {
      let styling = {...contentBlock.styling, borderWidth: "1px", borderStyle: "solid", borderColor: "#7FB6BE", border: "solid 1px #7FB6BE", };
      return styling;
    } else if(contentBlock && contentBlock.styling) {
      return contentBlock.styling;
    }
    return null;
  }

  const setSectionMobileStyling = (section) => {
    let contentBlock = contentBlocks?.find(obj => { return obj?.pageArea === section && obj?.parentContainer === 0 });
    if (contentBlock && contentBlock.id === selectedContentBlockId) {
      let mobileStyling = { ...contentBlock.mobileStyling, borderWidth: "1px", borderStyle: "solid", borderColor: "#7FB6BE", border: "solid 1px #7FB6BE" };
      return mobileStyling;
    } else if (contentBlock && contentBlock.mobileStyling) {
      return contentBlock.mobileStyling;
    }
    return null;
  }

  const getStylesAccordingToDevice = (section) => {
    const desktopStyle = setSectionStyle(section);
    const mobileStyle = setSectionMobileStyling(section);
  
    const view = currentDeviceView;

    switch ( view ) {
      case "Mobile":
        return { ...desktopStyle, ...mobileStyle };
      default:
        return desktopStyle;
    }
  }

  const accessToolsById = (e, id) => {
    e.stopPropagation();
    let contentBlock = contentBlocks?.find(obj => { return obj.id == id});
    setSelectedContentBlockId(contentBlock?.id);
    updateCurrentBlockHandler(contentBlock);
  }

  const setStyle = (id) => {
    let contentBlock = contentBlocks?.find(obj => obj.id === id);
    
    // rendering style according to current device view
    switch (currentDeviceView) {
      case 'Mobile': 
        if(contentBlock && contentBlock.id === selectedContentBlockId) {
          if(contentBlock.styling?.border || contentBlock.styling?.borderWidth) {
            return contentBlock.styling;
          } else {
            let styling = { ...contentBlock.styling, borderWidth: "1px", borderStyle: "solid", borderColor: "#7FB6BE", border: "solid 1px #7FB6BE", ...contentBlock.mobileStyling };
            // console.log('Mobilestyling from builderctx',styling)
            return styling;
          }
        } else if(contentBlock && contentBlock.styling) {
          // console.log('Mobilestyling from builderctx',{...contentBlock.styling,...contentBlock.mobileStyling})
          return {...contentBlock.styling,...contentBlock.mobileStyling};
        }
        break;
      default: 
        if(contentBlock && contentBlock.id === selectedContentBlockId) {
          if(contentBlock.styling?.border || contentBlock.styling?.borderWidth) {
            return contentBlock.styling;
          } else {
            let styling = { ...contentBlock.styling, borderWidth: "1px", borderStyle: "solid", borderColor: "#7FB6BE", border: "solid 1px #7FB6BE" };
            return styling;
          }
        } else if(contentBlock && contentBlock.styling) {
          return contentBlock.styling;
        }
        break;
    }
  
    return null;
  }

  const showMultipleBoxStyle = (contentBlockId) => {
    const tempBlock = contentBlocks.find( block => block.id === contentBlockId )
    if ( selectMultipleBlocks.includes( tempBlock) ) {
      return { cursor: "pointer", borderWidth: "2px", borderStyle: "solid", borderColor: "red", border: "solid 1px red" };
    }
  }
  

  const updateContentText = (e, id) => {
    updateContentBlocksHandler(contentBlocks?.map(block => {
      if(block.id == id) {
        block.properties.text = e.target.value;
        return block;
      }
      return block;
    }))
  }

  const updatePagesHandler = ( pages ) => {
    setPages(pages)
  }

  const contextValue = {
    assessmentId: assessmentId,
    assessments: assessments,
    updateAllAssessments: setAssessments,
    currentPage: currentPage,
    currentBlockId: currentBlockId,
    currentPageId: currentPageId,
    setSelectedContentBlockId: setSelectedContentBlockId,
    selectedContentBlockId: selectedContentBlockId,
    currentContentBlock: currentContentBlock,
    contentBlocks: contentBlocks,
    updateContentBlocks: updateContentBlocksHandler,
    updateCurrentBlock: updateCurrentBlockHandler,
    newUpdateCurrentBlock: newUpdateCurrentBlock,
    setContentBlocks: setContentBlocks,
    changeCurrentPage: changeCurrentPage,
    setCurrentPageId: setCurrentPageId,
    wrapContainerAround: selectMultipleBlocksHandler,
    isCtrlPressed: isCtrlPressed,
    setIsCtrlPressed: setIsCtrlPressed,
    pagesStatus: pagesStatus,
    setSelectMultipleBlocks: setSelectMultipleBlocks,
    selectMultipleBlocks: selectMultipleBlocks,
    accessToolsByPageArea: accessToolsByPageArea,
    updateContentText: updateContentText,
    setStyle: setStyle,
    showMultipleBoxStyle: showMultipleBoxStyle,
    setSectionStyle: setSectionStyle,
    accessToolsById: accessToolsById,
    setCurrentBlockId: setCurrentBlockId,
    setCurrentObjStyles: setCurrentObjStyles,
    refreshPagesAndContent: refreshPagesAndContent,
    setRefreshPagesAndContent: setRefreshPagesAndContent,
    showStartPageImgVidSection: showStartPageImgVidSection,
    setShowStartPageImgVidSection: setShowStartPageImgVidSection,
    numOfQuestions: numOfQuestions,
    setNumOfQuestions: setNumOfQuestions,
    startPageVideoURL: startPageVideoURL, 
    setStartPageVideoURL: setStartPageVideoURL,
    startPageImageURL: startPageImageURL, 
    setStartPageImageURL: setStartPageImageURL,
    lastUploadedMedia: lastUploadedMedia, 
    setLastUploadedMedia: setLastUploadedMedia,
    currentDeviceView: currentDeviceView,
    pages: pages,
    setPages: setPages,
    updatePages: updatePagesHandler,
    setCurrentDeviceView: setCurrentDeviceView,
    setSectionMobileStyling: setSectionMobileStyling,
    getStylesAccordingToDevice: getStylesAccordingToDevice
  }

  return (
    <BuilderContext.Provider value={contextValue}>
      {props.children}
    </BuilderContext.Provider>
  );
}

export default BuilderContext;
