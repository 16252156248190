import React, { useEffect, useState, useContext, Suspense, lazy, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Image from "./Components/Image";
import SideBar from "./Components/SideBar";
// import DetailsDrawer from "./Components/DetailsDrawer";
// import DetailsSubDrawer from './Components/DetailsSubDrawer';
// import DetailsModal from "./Components/DetailsModal";


// Pages
import Login from "./Pages/Login";
// import Register from "./Pages/Register";
// import Overview from "./Pages/Overview";
// import Users from "./Pages/Users";
// import Clients from "./Pages/Clients";
// import Email from './Pages/Email';
// import ClientsAdmin from "./Pages/ClientsAdmin";
// import Assessments from "./Pages/Assessments";
// import AssessmentsLeaders from "./Pages/AssessmentsLeaders";
// import AssessmentsMembers from "./Pages/AssessmentsMembers";
// import AssessmentFields from './Pages/AssessmentFields';
// import AssessmentAutomation from './Pages/AssessmentAutomation';




import AuthContext from './store/AuthContext.js';
import { DrawerContextProvider } from './store/DrawerContext.js';
import { SubDrawerContextProvider } from './store/SubDrawerContext';
import { ModalContextProvider } from './store/ModalContext.js';
import { BuilderContextProvider } from './store/BuilderContext';
import { DataGridContextProvider } from './store/DataGridContext';
import { UsersContextProvider } from './store/UsersContext';
import SidebarContext from './store/SidebarContext.js';
// import AssessmentsQuestions from './Pages/AssessmentsQuestions';
// import AssessmentsSections from './Pages/AssessmentsSections';
// import AssessmentPage from './Pages/AssessmentPage';
// import AssessmentPageV2 from './Pages/AssessmentPageV2/AssessmentPageV2';
// import Engagement from './Pages/Engagement';
import AssessmentBuilder from './Pages/AssessmentBuilder';
import AssessmentChurchInsights from "./Pages/AssessmentChurchInsights/AssessmentChurchInsights.jsx";
import { LanguageContextProvider } from "./store/LanguageContext.jsx";
// import DataMining from './Pages/Datamining';
// import Pages from './Pages/Page';
// import AssesmentPage from './Pages/AssesmentPage.jsx';
// import SetupAssessment from './Pages/SetupAssessment.jsx';
// import AssessmentDashboardPage from './Pages/AssessmentDashboardPage';


const DetailsDrawer = lazy(() => import("./Components/DetailsDrawer"));
const DetailsSubDrawer = lazy(() => import("./Components/DetailsSubDrawer"));
const DetailsModal = lazy(() => import("./Components/DetailsModal"));

const Register = lazy(() => import("./Pages/Register"));
const Overview = lazy(() => import("./Pages/Overview"));
const Users = lazy(() => import("./Pages/Users"));
const Clients = lazy(() => import("./Pages/Clients"));
const Email = lazy(() => import("./Pages/Email"));
const ClientsAdmin = lazy(() => import("./Pages/ClientsAdmin"));
const Assessments = lazy(() => import("./Pages/Assessments"));
const AssessmentsLeaders = lazy(() => import("./Pages/AssessmentsLeaders"));
const AssessmentsMembers = lazy(() => import("./Pages/AssessmentsMembers"));
const AssessmentFields = lazy(() => import("./Pages/AssessmentFields"));
const AssessmentAutomation = lazy(() => import("./Pages/AssessmentAutomation"));

const AssessmentsQuestions = lazy(() => import("./Pages/AssessmentsQuestions"));
const AssessmentsSections = lazy(() => import("./Pages/AssessmentsSections"));
const AssessmentPage = lazy(() => import("./Pages/AssessmentPage"));
const AssessmentPageV2 = lazy(() => import("./Pages/AssessmentUsers/AssessmentPage/AssessmentPage"));
const Engagement = lazy(() => import("./Pages/Engagement"));
// const AssessmentBuilder = lazy(() => import("./Pages/AssessmentBuilder"));
const DataMining = lazy(() => import("./Pages/Datamining"));
const Pages = lazy(() => import("./Pages/Page"));
const AssesmentPage = lazy(() => import("./Pages/AssesmentPage.jsx"));
const SetupAssessment = lazy(() => import("./Pages/SetupAssessment.jsx"));
const AssessmentParishInsights = lazy(() => import("./Pages/AssessmentParish/AssessmentParishInsights/AssessmentParishInsights.jsx"));
const ParishInsightsSyncScreen = lazy(() => import("./Pages/AssessmentParish/AssessmentParishInsights/ParishInsightsSyncSceen.jsx"));
const ParishDuplicateCheck = lazy(() => import("./Pages/AssessmentParish/AssessmentParishInsights/ParishDuplicateCheck.jsx"));


function App() {

  const authCtx = useContext(AuthContext);
  const sidebarCtx = useContext(SidebarContext);
  const [name, setName] = useState('');
  console.log(authCtx);

  const memoizedSideBar = useMemo(() => <SideBar />, []);

  const env = process.env.NODE_ENV;
  const API = process.env.REACT_APP_API_URI;

  useEffect(() => {
    if (env === "development" && API === "https://api.bleat.church") {
      console.log("%c\nYou are in development mode using the production API.\n", "color: red; font-size: 16px;");
    }
  }, [env, API]);

  async function memoryCheck() {
    if (!window.crossOriginIsolated) {
      console.warn('performance.measureUserAgentSpecificMemory() is only available in cross-origin-isolated pages');
    } else if (!performance.measureUserAgentSpecificMemory) {
      console.warn('performance.measureUserAgentSpecificMemory() is not available in this browser');
    } else {
      let result;
      try {
        result = await performance.measureUserAgentSpecificMemory();
      } catch (error) {
        if (error instanceof DOMException && error.name === 'SecurityError') {
          console.warn('The context is not secure.');
        } else {
          throw error;
        }
      }
      console.warn("memory check:", result);
    }
  }

  memoryCheck();


  return (
    <div className="App">

      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <UsersContextProvider>
            <DrawerContextProvider>
              <SubDrawerContextProvider>
                <ModalContextProvider>
                  <BuilderContextProvider>
                    <DataGridContextProvider>
                      <LanguageContextProvider>
                        {authCtx.isLoggedIn ? memoizedSideBar : null}
                        <main className={sidebarCtx.isOpen ? "main main-closed" : "main"}>

                          <Routes>
                            <Route path="/" exact index element={!authCtx.isLoggedIn ? <Navigate to='/login' /> : <Navigate to='/overview' />} />
                            <Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login setName={setName} /> : <Navigate to='/overview' />} />
                            <Route path="/register" exact element={!authCtx.isLoggedIn ? <Register /> : <Navigate to='/login' />} />
                            <Route path="/overview" exact element={authCtx.isLoggedIn ? <Overview /> : <Navigate to='/login' />} />
                            <Route path="/clients" exact element={authCtx.isLoggedIn ? <Clients /> : <Navigate to='/login' />} />
                            <Route path="/clients/:id/administrators" exact element={authCtx.isLoggedIn ? <ClientsAdmin /> : <Navigate to='/login' />} />
                            <Route path="/assessmentsPage" exact element={authCtx.isLoggedIn ? <Assessments /> : <Navigate to='/login' />} />
                            <Route path="/emails/:id/" exact element={authCtx.isLoggedIn ? <Email /> : <Navigate to='/login' />} />
                            <Route path="/assessments/:id/" exact element={authCtx.isLoggedIn ? <AssessmentPage /> : <Navigate to='/login' />} />
                            <Route path="/assessmentsv2/:id/" exact element={authCtx.isLoggedIn ? <AssessmentPageV2 /> : <Navigate to='/login' />} />
                            <Route path="/assessmentsparishinsights/:id" element={authCtx.isLoggedIn ? <AssessmentParishInsights /> : <Navigate to='/login' />} />
                            <Route path="/parishinsightssync/:id" element={authCtx.isLoggedIn ? <ParishInsightsSyncScreen /> : <Navigate to='/login' />} />
                            <Route path="/parishduplicatecheck/:id" element={authCtx.isLoggedIn ? <ParishDuplicateCheck /> : <Navigate to='/login' />} />
                            <Route path="/assessmentschurchinsights/:id" element={authCtx.isLoggedIn ? <AssessmentChurchInsights /> : <Navigate to='/login' />} />
                            <Route path="/assessments/:id/leaders" exact element={authCtx.isLoggedIn ? <AssessmentsLeaders /> : <Navigate to='/login' />} />
                            {/* <Route path="/assessments/:id/dashboard" exact element={authCtx.isLoggedIn ? <AssessmentDashboardPage/> : <Navigate to='/login'/>} /> */}
                            <Route path="/assessments/:id/members" exact element={authCtx.isLoggedIn ? <AssessmentsMembers /> : <Navigate to='/login' />} />
                            <Route path="/assessments/:id/questions" exact element={authCtx.isLoggedIn ? <AssessmentsQuestions /> : <Navigate to='/login' />} />
                            <Route path="/assessments/:id/sections" exact element={authCtx.isLoggedIn ? <AssessmentsSections /> : <Navigate to='/login' />} />
                            <Route path="/assessments/:id/fields" exact element={authCtx.isLoggedIn ? <AssessmentFields /> : <Navigate to='/login' />} />
                            <Route path="/assessments/:id/automation" exact element={authCtx.isLoggedIn ? <AssessmentAutomation /> : <Navigate to='/login' />} />
                            <Route path="/assessments/:id/pages" exact element={authCtx.isLoggedIn ? <Pages /> : <Navigate to='/login' />} />

                            <Route path="/engagement" exact element={authCtx.isLoggedIn ? <Engagement /> : <Navigate to='/login' />} />

                            <Route path="/datamining" exact element={authCtx.isLoggedIn ? <DataMining /> : <Navigate to='/login' />} />

                            <Route path="/users" exact element={authCtx.isLoggedIn ? <Users /> : <Navigate to='/login' />} />

                            <Route path="/builder/:id/" exact element={authCtx.isLoggedIn ? <AssessmentBuilder /> : <Navigate to='/login' />} />
                            <Route path="/assessments" exact element={authCtx.isLoggedIn ? <AssesmentPage /> : <Navigate to='/login' />} />
                            <Route path="/setup/:id" exact element={authCtx.isLoggedIn ? <SetupAssessment /> : <Navigate to='/login' />} />


                          </Routes>
                        </main>

                        <DetailsDrawer />
                        <DetailsSubDrawer />
                        <DetailsModal />
                      </LanguageContextProvider>
                    </DataGridContextProvider>
                  </BuilderContextProvider>
                </ModalContextProvider>
              </SubDrawerContextProvider>
            </DrawerContextProvider>
          </UsersContextProvider>
        </Suspense>
      </BrowserRouter>

    </div>
  );
}

export default App;
