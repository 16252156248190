import { useEffect, useContext } from "react";

import BuilderContext from '../../store/BuilderContext';

import HeadingWidget from "./Tools/HeadingWidget";
import ParagraphWidget from "./Tools/ParagraphWidget";


const StartV2 = () => {


  const builderCtx = useContext(BuilderContext);

  const { accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, contentBlocks, changeCurrentPage, showStartPageImgVidSection, setShowStartPageImgVidSection, startPageVideoURL, setStartPageVideoURL, startPageImageURL, setStartPageImageURL, lastUploadedMedia, setLastUploadedMedia } = builderCtx;

  const backgroundBlock = contentBlocks.find( block => block.contentType === 'Background' )

  console.log('backgroundBlock',backgroundBlock)

  const getBtnText = (blockId) => {
    const block = contentBlocks.find((el)=> el.id === blockId );

    console.log('block',block)

    return block?.properties?.text;
  }

  useEffect(() => {
    changeCurrentPage("start");
  }, []);

  console.log("start contentBlocks", contentBlocks);

  const getContent = (contentType, blockId, data) => {

    // console.log('contentType---',contentType)

    switch (contentType) {
      
      // case "Image": return <img src={getByBlockId(blockId)?.properties?.img} style={getByBlockId(blockId)?.styling} alt="Logo" />;
      
      case "heading": return <div style={setStyle(blockId)} onClick={(e) => accessToolsById(e, blockId)}>
        <HeadingWidget key={blockId} blockId={blockId} className="editable-text" />
      </div>;

      case "paragraph": return <div style={setStyle(blockId)} onClick={(e) => accessToolsById(e, blockId)}>
        <ParagraphWidget key={blockId} blockId={blockId} className="editable-text" />
      </div>;

      case "button": return <button style={setStyle(blockId)} onClick={(e) => accessToolsById(e, blockId)}>
        {/* <ButtonWidget key={blockId} blockId={blockId} /> */}
        {getBtnText(blockId)}
      </button>;

      // case "BleatLogo": return <img src={getStylesByContentType("BleatLogo")?.properties?.img} width="auto" style={getStylesByContentType("BleatLogo")?.styling} alt="Bleat" />
      
      case "Container": return <div style={setStyle(blockId)} onClick={(e) => accessToolsById(e, blockId)}>
        {
          builderCtx.contentBlocks?.sort((a,b) => a.orderNo - b.orderNo).map((el,index)=>{
            if ( el.parentContainer == blockId ) {
              return <>{getContent(el.contentType, el.id)}</>
            }
          })
        }
      </div>

      default: return null;

    }

  }
   
  return (
    
    // <>
    //   {builderCtx.contentBlocks.sort((a,b) => a.orderNo - b.orderNo).map((data, index) => {
    //     if(data.pageArea == "content" && data.parentContainer == 0) {
    //       return <>{ getContent(data.contentType, data.id, data) }</>
    //     }
    //   })}
    // </>

    <>
      {builderCtx.contentBlocks.sort((a,b) => a.orderNo - b.orderNo).map((data, index) => {
        if(data.pageArea == "content" && data.parentContainer === backgroundBlock?.id ) {
          return <>{ getContent(data.contentType, data?.id, data) }</>
        }
      })}
    </>

  );
}



export default StartV2;