import { useState, useEffect, useContext } from "react";
import { TextField } from '@mui/material';
import BuilderContext from "../../../../store/BuilderContext";

const LineHeight = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  } 

  const currentBlockStyles = getCurrentBlockStyles()
  const currentLineHeight = currentBlockStyles?.lineHeight;

  const [lineHeight, setLineHeight] = useState(currentLineHeight || "normal");

  const handleLineHeightChange = (e) => {
    const newLineHeight = e.target.value.trim();
    setLineHeight(newLineHeight);
    updateLineHeight(newLineHeight);
  };

  const updateLineHeight = (newLineHeight) => {
    const payload = {
      id: blockId,
      lineHeight: newLineHeight,
      screenType: props?.screenType
    };

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/line-height', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        console.log("Updated line height successfully:", payload);
      }
    })
    .catch(error => {
      console.error("Error updating line height:", error);
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (lineHeight !== currentLineHeight) {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            let newStyles = { ...block.styling };
            newStyles.lineHeight = lineHeight;
            block.styling = { ...newStyles };
            return block;
          } else return block;
        }));

        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    lineHeight
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    lineHeight
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    lineHeight
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    lineHeight
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [blockId, currentLineHeight, lineHeight, updateContentBlocks]);

  return (
    <div className="line-height font-styles tool">
      {props.deleteModal}
      <TextField style={{ width: "100px" }}
        className="line-height-unit styling-input"
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={lineHeight === "normal" ? "normal" : lineHeight}
        onChange={handleLineHeightChange}
      />
    </div>
  );
};

export default LineHeight;
