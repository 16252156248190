import { createContext, useState } from "react";

const UsersContext = createContext({
  allUsers: [],
});

export function UsersContextProvider(props) {

  const [assessmentDetails, setAssessmentDetails] = useState();
  const [assessmentId, setAssessmentId]           = useState(1);
  const [pageUpdate, setPageUpdate]               = useState({});
  const [allUsers, setAllUsers]                   = useState([]);
  const [filteredAllUsers, setFilteredAllUsers]   = useState([]);
  const [totalLeaders, setTotalLeaders]           = useState(0);
  const [filteredLeaders, setFilteredLeaders]     = useState([]);
  const [totalMembers, setTotalMembers]           = useState(0);
  const [filteredMembers, setFilteredMembers]     = useState([]);
  const [totalRecords, setTotalRecords]           = useState(0);

  // console.log("usersCtx allUsers", allUsers);
  // console.log("usersCtx assessmentDetails", assessmentDetails);
  // console.log("usersCtx assessmentId", assessmentId);

  function setPageUpdateHandler(data) {
    setPageUpdate(data);
  }

  function updateUsersAfterDelete(data) {
    setAllUsers((prev) => prev.filter((user) => !data.includes(user.id)));
  }  

  const context = {
    updateUsersAfterDelete,
    allUsers, setAllUsers,
    assessmentDetails, setAssessmentDetails,
    assessmentId, setAssessmentId,
    totalRecords, setTotalRecords,
    filteredAllUsers, setFilteredAllUsers,
    totalLeaders, setTotalLeaders,
    filteredLeaders, setFilteredLeaders,
    totalMembers, setTotalMembers,
    filteredMembers, setFilteredMembers,
  };

  return (
    <UsersContext.Provider value={context}>
      {props.children}
    </UsersContext.Provider>
  );
}


export default UsersContext;
