import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const MinWidth = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;

  const getCurrentBlockStyles = () => {
    switch (screenType) {
      case "mobile":
        return currentContentBlock?.mobileStyling;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      default:
        return currentContentBlock?.styling;
    }
  };

  const currentBlockStyles = getCurrentBlockStyles();
  const currentMinWidth = currentBlockStyles?.minWidth?.match(/[a-zA-Z]+|[0-9]+|[%]/g);

  const units = [
    { value: 'px', label: 'px' },
    { value: 'rem', label: 'rem' },
    { value: '%', label: '%' },
    { value: 'vh', label: 'vh' },
    { value: 'vw', label: 'vw' },
  ];

  const [minWidth, setMinWidth] = useState(currentMinWidth?.[0] || "auto");
  const [unit, setUnit] = useState(currentMinWidth?.[1] || "px");
  const [finalValue, setFinalValue] = useState("");

  const [onChangeFlag, setOnChangeFlag] = useState(false);

  if (minWidth !== "auto") localStorage.setItem("prevMinWidth", minWidth);

  const handleUnitChange = (e) => {
    setOnChangeFlag(true);

    if (!e.target.value) {
      setMinWidth("auto");
    } else {
      setUnit(e.target.value);
      if (localStorage.getItem("prevMinWidth")) {
        setMinWidth(localStorage.getItem("prevMinWidth"));
      }
    }
  };

  const updateMinWidth = (minWidthValue, unit) => {
    let payload = {
      id: blockId,
      value: minWidthValue !== 'auto' ? minWidthValue + unit : minWidthValue,
      screenType: props?.screenType,
    };

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/assessment/styling/minWidth`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token,
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating minWidth payload", payload);
        }
      })
      .catch(err => {
        console.error("Failed to update minWidth...", err);
      });
  };

  useEffect(() => {
    if (minWidth === "auto") {
      setFinalValue("auto");
    } else if (minWidth && minWidth !== "auto" && unit) {
      if (minWidth !== currentMinWidth?.[0] || unit !== currentMinWidth?.[1]) {
        setFinalValue(`${minWidth}${unit}`);
      }
    }
  }, [currentMinWidth, minWidth, unit]);

  useEffect(() => {
    let lastFinalVal = "";
    if (!currentMinWidth?.[1]) {
      lastFinalVal = "auto";
    } else {
      lastFinalVal = currentMinWidth?.[0] + currentMinWidth?.[1];
    }

    if (onChangeFlag) {
      updateMinWidth(minWidth, unit);
    }

    const timer = setTimeout(() => {
      if (finalValue && finalValue !== lastFinalVal) {
        switch (screenType) {
          case "mobile":
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    minWidth: finalValue,
                  },
                };
              }
              return block;
            }));
            break;
          case "horizontal":
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    minWidth: finalValue,
                  },
                };
              }
              return block;
            }));
            break;
          case "vertical":
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    minWidth: finalValue,
                  },
                };
              }
              return block;
            }));
            break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    minWidth: finalValue,
                  },
                };
              }
              return block;
            }));
            break;
        }
      }

      setOnChangeFlag(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [blockId, finalValue, unit]);

  return (
    <div className="minWidth tool">
      {props.deleteModal}
      <TextField
        id="minWidth-number"
        className="minWidth-input styling-input"
        type="number"
        variant="filled"
        InputLabelProps={{ shrink: true }}
        placeholder={minWidth + ""}
        value={minWidth}
        onChange={e => {
          if (e.target.value) {
            setMinWidth(e.target.value);
          } else {
            setMinWidth("auto");
          }
          setOnChangeFlag(true);
        }}
      />

      <TextField
        className="minWidth-unit styling-select"
        select
        variant="standard"
        InputProps={{ disableUnderline: true }}
        placeholder={currentContentBlock.styling?.minWidth + ""}
        value={unit}
        onChange={handleUnitChange}
      >
        {units?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default MinWidth;
