import { useContext, useEffect, useState } from "react";
import { TextField } from '@mui/material';
import ColorPicker from 'react-best-gradient-color-picker';
import AuthContext from '../../../../store/AuthContext';
import BuilderContext from '../../../../store/BuilderContext';

const BackgroundColor = (props) => {
  const { blockId, propertyName, screenType, name = "" } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  }

  const currentBlock = contentBlocks?.find(block => block.id === blockId);
  const currentBlockStyles = getCurrentBlockStyles()
  const currentBgColor = currentBlockStyles?.background || "#ffffff";

  const [bgColorHex, setBgColorHex] = useState(currentBlockStyles?.background || "#ffffff");
  const [finalValue, setFinalValue] = useState(currentBlockStyles?.background || "#ffffff");
  const [pickerOpen, setPickerOpen] = useState(false);
  const [color, setColor] = useState(currentBlockStyles?.background || "#ffffff");

  useEffect(() => {
    const timer = setTimeout(() => {
      setFinalValue(color);
    }, 200);
    return () => clearTimeout(timer);
  }, [color]);

  useEffect(() => {
    const lastFinalVal = currentBgColor || "#ffffff";
    if (finalValue !== lastFinalVal) {
      updateContentBlocks(prevBlocks => prevBlocks?.map(block => {
        if (block.id === blockId) {
          if (propertyName) {
            return { ...block, properties: { ...block.properties, [propertyName]: finalValue } };
          } else {
            const payload = {
              id: blockId,
              value: finalValue,
              screenType: screenType
            };

            fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/background', {
              method: 'PUT',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authCtx.token
              },
              body: JSON.stringify(payload)
            })
            .then(response => response.json())
            .then(data => {
              if (data.status === "success") {
              }
            }).catch((err) => {
              console.error("Failed to update backgroundColor...", err);
            });

            switch (screenType) {
              case "mobile":
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    background: finalValue
                  }
                };
              case "horizontal":
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    background: finalValue
                  }
                };
              case "vertical":
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    background: finalValue
                  }
                };
              default:
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    background: finalValue
                  }
                };
            }
          }
        } else {
          return block;
        }
      }));
    }
  }, [authCtx.token, blockId, finalValue, propertyName, currentBgColor, screenType]);

  return (
    <div className="bgcolor tool">
      {props.deleteModal}
      <TextField
        className="bgcolor-input styling-input"
        type="text"
        variant="filled"
        InputLabelProps={{ shrink: true }}
        placeholder="Type in hex value or select color"
        value={finalValue}
        onChange={(e) => {
          setBgColorHex(e.target.value);
          setFinalValue(e.target.value);
        }}
      />
      <button style={{height:"45px",width:"10%",border:"1px solid #707070"}} type="color"
				className="color-box"
				value={finalValue}
				onClick={() => setPickerOpen(!pickerOpen)}>-</button>
			{pickerOpen && <ColorPicker className={'bg-color-picker'} value={finalValue} onChange={setFinalValue} />}
    </div>
  );
};

export default BackgroundColor;
