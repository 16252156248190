import { useContext } from "react";

import BuilderContext from "../../../store/BuilderContext";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const TextWidget = () => {

  const builderCtx = useContext(BuilderContext);
  const { currentContentBlock, updateContentBlocks, contentBlocks, setCurrentObjStyles } = builderCtx;


  return (
    <>
      {/* <p style={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "700", textAlign: "center", color: "#122845" }}>Header</p> */}
      <DynamicStylingTool/>
    </>
  )
}

export default TextWidget;

