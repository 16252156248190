import React, { useState, useContext, useEffect } from "react";
import BuilderContext from "../../../store/BuilderContext";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";
import AuthContext from "../../../store/AuthContext";

const ButtonWidget = () => {
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);

  const { currentContentBlock, setContentBlocks } = builderCtx;

  const [inputText, setInputText] = useState(currentContentBlock?.properties?.text);

  useEffect(() => {
    const updateButtonText = () => {
      const payload = {
        id: currentContentBlock.id,
        text: inputText
      };

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/content/button', {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(payload),
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            setContentBlocks(prevBlocks => {
              return prevBlocks.map(block => {
                if (block.id === currentContentBlock.id) {
                  return {
                    ...block,
                    properties: {
                      ...block.properties,
                      text: inputText
                    }
                  };
                }
                return block;
              });
            });
          }
        })
        .catch(err => {
          console.error("Failed to update button text...", err);
        });
    };

    updateButtonText();

  }, [inputText, currentContentBlock.id, setContentBlocks, authCtx.token]);

  const buttonTextProperty = () => { 
    return <>
      <p className="styling-text">Button Text:</p>
      <input
        type="text"
        placeholder="Enter button text"
        value={inputText}
        onChange={e => setInputText(e.target.value)}
      />
    </>
  }

  return (
    <div>
      <DynamicStylingTool propertiesArray={[
        buttonTextProperty()
      ]} />
    </div>
  );
};

export default ButtonWidget;
