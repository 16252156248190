import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const LetterSpacing = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch (screenType) {
      case "mobile":
        return currentContentBlock?.mobileStyling;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      default:
        return currentContentBlock?.styling;
    }
  };

  const letterSpacingUnitValue = (type, size) => {
    const sizeString = String(size);
    const value = parseFloat(sizeString);
    const unit = sizeString.replace(value, '').trim();
    if (type === 'value') return value;
    return unit;
  };

  const currentBlockStyles = getCurrentBlockStyles();
  const currentLetterSpacing = currentBlockStyles?.letterSpacing;

  const [letterSpacing, setLetterSpacing] = useState(letterSpacingUnitValue("value", currentLetterSpacing) || "0");
  const [unit, setUnit] = useState(letterSpacingUnitValue("unit", currentLetterSpacing) || "px");

  const [onChangeFlag, setOnChangeFlag] = useState(false);

  const units = [
    { value: 'px', label: 'px' },
    { value: 'rem', label: 'rem' },
    { value: 'em', label: 'em' },
    { value: '%', label: '%' },
  ];

  const handleUnitChange = (e) => {
    setOnChangeFlag(true);
    setUnit(e.target.value);
  };

  const handleLetterSpacingChange = (e) => {
    setOnChangeFlag(true);
    setLetterSpacing(e.target.value);
  };

  const updateLetterSpacing = (letterSpacingValue) => {
    const payload = {
      id: blockId,
      value: letterSpacingValue + unit,
      screenType: props?.screenType
    };

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/letterSpacing', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating letter spacing payload", payload);
        }
      }).catch((err) => {
        console.error("Failed to update letter spacing...", err);
      });
  };

  useEffect(() => {
    if (onChangeFlag) {
      updateLetterSpacing(letterSpacing);
    }
  }, [letterSpacing, unit, onChangeFlag]);

  useEffect(() => {
    const timer = setTimeout(() => {
      switch (screenType) {
        case "mobile":
          updateContentBlocks(contentBlocks?.map(block => {
            if (block.id === blockId) {
              return {
                ...block,
                mobileStyling: {
                  ...block.mobileStyling,
                  letterSpacing: letterSpacing + unit
                }
              };
            }
            return block;
          }));
          break;
        case "horizontal":
          updateContentBlocks(contentBlocks?.map(block => {
            if (block.id === blockId) {
              return {
                ...block,
                horizontalStyling: {
                  ...block.horizontalStyling,
                  letterSpacing: letterSpacing + unit
                }
              };
            }
            return block;
          }));
          break;
        case "vertical":
          updateContentBlocks(contentBlocks?.map(block => {
            if (block.id === blockId) {
              return {
                ...block,
                verticalStyling: {
                  ...block.verticalStyling,
                  letterSpacing: letterSpacing + unit
                }
              };
            }
            return block;
          }));
          break;
        default:
          updateContentBlocks(contentBlocks?.map(block => {
            if (block.id === blockId) {
              return {
                ...block,
                styling: {
                  ...block.styling,
                  letterSpacing: letterSpacing + unit
                }
              };
            }
            return block;
          }));
          break;
      }
      setOnChangeFlag(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [blockId, letterSpacing, unit]);

  return (
    <div className="letter-spacing tool">
      {props.deleteModal}
      <TextField
        id="letter-spacing-number"
        className="letter-spacing-input styling-input"
        type="number"
        variant="filled"
        InputLabelProps={{ shrink: true }}
        placeholder={currentContentBlock?.styling?.letterSpacing}
        value={letterSpacing}
        onChange={handleLetterSpacingChange}
      />
      <TextField
        className="letter-spacing-unit styling-select"
        select
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={unit}
        onChange={handleUnitChange}
      >
        {units.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

export default LetterSpacing;
