import { ArrowLeft, ArrowRight, NearMeOutlined } from "@material-ui/icons";
import { Button } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router";
import DrawerContext from "../../../store/DrawerContext";
import bulletPointImg from "../../../assets/img/bullet-points.png"
import ModalContext from "../../../store/ModalContext";

const DefaultSelectedWidget = (props) => {

  const { id }    = useParams();
  const drawerCtx = useContext(DrawerContext);
  const modalCtx  = useContext(ModalContext);

  const [isLoading, setIsLoading] 						= useState(false);
	const [title, setTitle] 										= useState("");
	
  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  return (
    <div className="default-tools-widget">
      <div className="info">
        <ArrowLeft className="info-icon" />
        <p>SELECT A CONTENT AREA FOR OPTIONS FOR EDITING</p>
        <ArrowRight className="info-icon" />
      </div>
      {/* <div className="question-builder">
        <p>Get a birds eye view of your questions:</p>
        <Button className="question-builder-btn" onClickCapture={() => {toggleDrawerHandler("open-questions-builder", { id })}}>
          <img src={bulletPointImg} alt="question builder button icon" />
          <span>Question<br/>Builder</span>
        </Button>
      </div> */}
      <Button className="navigator-btn" onClick={()=>{toggleModalHandler("page-builder-navigator-modal")}}>
        <NearMeOutlined/>
        <span>Navigator</span>
      </Button>
    </div>
  )
}


export default DefaultSelectedWidget;
