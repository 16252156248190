import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const JustifyContent = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  } 

  const currentBlockStyles = getCurrentBlockStyles()
  const currentJustifyContent = currentBlockStyles?.justifyContent;

  const [justifyContent, setJustifyContent] = useState(currentJustifyContent || "flex-start");
  const [onChangeFlag, setOnChangeFlag] = useState(false);

  const justifyContentOptions = [
    { value: 'flex-start', label: 'Flex Start' },
    { value: 'flex-end', label: 'Flex End' },
    { value: 'center', label: 'Center' },
    { value: 'space-between', label: 'Space Between' },
    { value: 'space-around', label: 'Space Around' },
    { value: 'space-evenly', label: 'Space Evenly' },
  ];

  const handleJustifyContentChange = (e) => {
    setOnChangeFlag(true);
    setJustifyContent(e.target.value);
  };

  const updateJustifyContent = (justifyContentValue) => {
    const payload = {
      id: blockId,
      value: justifyContentValue,
      screenType: props?.screenType
    };

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/justifyContent', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating justify content payload", payload);
        }
      }).catch((err) => {
        console.error("Failed to update justify content...", err);
      });
  };

  useEffect(() => {
    if (onChangeFlag) {
      updateJustifyContent(justifyContent);
    }
  }, [justifyContent, onChangeFlag]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (justifyContent !== currentJustifyContent) {
        // updateContentBlocks(contentBlocks?.map(block => {
        //   if (block.id === blockId) {
        //     return {
        //       ...block,
        //       styling: {
        //         ...block.styling,
        //         justifyContent: justifyContent
        //       }
        //     };
        //   }
        //   return block;
        // }));

        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    justifyContent
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    justifyContent
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    justifyContent
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    justifyContent
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }
      }
      setOnChangeFlag(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [blockId, justifyContent, currentJustifyContent]);

  return (
    <div className="justify-content tool">
      {props.deleteModal}
      <TextField
        className="justify-content-select styling-select"
        select
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={justifyContent}
        onChange={handleJustifyContentChange}
      >
        {justifyContentOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default JustifyContent;
