import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const MaxWidth = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;

  const getCurrentBlockStyles = () => {
    switch (screenType) {
      case "mobile":
        return currentContentBlock?.mobileStyling;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      default:
        return currentContentBlock?.styling;
    }
  };

  const currentBlockStyles = getCurrentBlockStyles();
  const currentMaxWidth = currentBlockStyles?.maxWidth?.match(/[a-zA-Z]+|[0-9]+|[%]/g);

  const units = [
    { value: 'px', label: 'px' },
    { value: 'rem', label: 'rem' },
    { value: '%', label: '%' },
    { value: 'vh', label: 'vh' },
    { value: 'vw', label: 'vw' },
  ];

  const [maxWidth, setMaxWidth] = useState(currentMaxWidth?.[0] || "auto");
  const [unit, setUnit] = useState(currentMaxWidth?.[1] || "px");
  const [finalValue, setFinalValue] = useState("");

  const [onChangeFlag, setOnChangeFlag] = useState(false);

  if (maxWidth !== "auto") localStorage.setItem("prevMaxWidth", maxWidth);

  const handleUnitChange = (e) => {
    setOnChangeFlag(true);

    if (!e.target.value) {
      setMaxWidth("auto");
    } else {
      setUnit(e.target.value);
      if (localStorage.getItem("prevMaxWidth")) {
        setMaxWidth(localStorage.getItem("prevMaxWidth"));
      }
    }
  };

  const updateMaxWidth = (maxWidthValue, unit) => {
    let payload = {
      id: blockId,
      value: maxWidthValue !== 'auto' ? maxWidthValue + unit : maxWidthValue,
      screenType: props?.screenType,
    };

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/assessment/styling/maxWidth`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token,
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating maxWidth payload", payload);
        }
      })
      .catch(err => {
        console.error("Failed to update maxWidth...", err);
      });
  };

  useEffect(() => {
    if (maxWidth === "auto") {
      setFinalValue("auto");
    } else if (maxWidth && maxWidth !== "auto" && unit) {
      if (maxWidth !== currentMaxWidth?.[0] || unit !== currentMaxWidth?.[1]) {
        setFinalValue(`${maxWidth}${unit}`);
      }
    }
  }, [currentMaxWidth, maxWidth, unit]);

  useEffect(() => {
    let lastFinalVal = "";
    if (!currentMaxWidth?.[1]) {
      lastFinalVal = "auto";
    } else {
      lastFinalVal = currentMaxWidth?.[0] + currentMaxWidth?.[1];
    }

    if (onChangeFlag) {
      updateMaxWidth(maxWidth, unit);
    }

    const timer = setTimeout(() => {
      if (finalValue && finalValue !== lastFinalVal) {
        switch (screenType) {
          case "mobile":
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    maxWidth: finalValue,
                  },
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
            break;
          case "horizontal":
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    maxWidth: finalValue,
                  },
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
            break;
          case "vertical":
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    maxWidth: finalValue,
                  },
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
            break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    maxWidth: finalValue,
                  },
                };
              }
              if (block.id === blockId ) {
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
            break;
        }
      }

      setOnChangeFlag(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [blockId, finalValue, unit]);

  // useEffect(() => {
  //   console.log('contentBlocks mw',contentBlocks)
  // }, [contentBlocks])
  

  return (
    <div className="maxWidth tool">
      {props.deleteModal}
      <TextField
        id="maxWidth-number"
        className="maxWidth-input styling-input"
        type="number"
        variant="filled"
        InputLabelProps={{ shrink: true }}
        placeholder={maxWidth + ""}
        value={maxWidth}
        onChange={e => {
          if (e.target.value) {
            setMaxWidth(e.target.value);
          } else {
            setMaxWidth("auto");
          }
          setOnChangeFlag(true);
        }}
      />

      <TextField
        className="maxWidth-unit styling-select"
        select
        variant="standard"
        InputProps={{ disableUnderline: true }}
        placeholder={currentContentBlock.styling?.maxWidth + ""}
        value={unit}
        onChange={handleUnitChange}
      >
        {units?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default MaxWidth;
