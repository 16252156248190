import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const MinHeight = (props) => {
  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  }

  const currentBlockStyles = getCurrentBlockStyles()
  const currentMinHeight = currentBlockStyles?.minHeight;

  const [minHeight, setMinHeight] = useState(props?.screenType === 'desktop' ? currentContentBlock?.styling?.minHeight : currentContentBlock?.mobileStyling?.minHeight );
  const [unit, setUnit] = useState("px");

  const units = [
    { value: 'px', label: 'px' },
    { value: 'rem', label: 'rem' },
    { value: '%', label: '%' },
    { value: 'vh', label: 'vh' },
    { value: 'vw', label: 'vw' },
    { value: 'auto',  label: 'auto'   },
  ];

  const updateMinHeight = (minHeightValue) => {
    let payload = {
      id: blockId,
      value: minHeightValue,
      screenType: props?.screenType
    };

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/minHeight', {
      method: 'PUT', 
      mode: 'cors',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer ' + authCtx.token 
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        console.log("Updating minHeight payload", payload);
        // updateContentBlocks(contentBlocks?.map(block => {
        //   if (block.id === blockId) {
        //     let newStyles = { ...block.styling };
        //     newStyles.minHeight = minHeightValue;
        //     block.styling = { ...newStyles };
        //     return block;
        //   } else return block;
        // }));

        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    minHeight: minHeightValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    minHeight: minHeightValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    minHeight: minHeightValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    minHeight: minHeightValue
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }
      }
    }).catch((err) => {
      console.error("Failed to update minHeight...", err);
    });
  };

  useEffect(() => {
    if (minHeight && minHeight !== currentMinHeight) {
      updateMinHeight(minHeight + unit);
    }
  }, [minHeight, unit]);

  const handleUnitChange = (e) => {
    if (!e.target.value) setMinHeight("auto");
    else {
      setUnit(e.target.value);
      if(localStorage.getItem("preMinHeight")) {
        setMinHeight(localStorage.getItem("preMinHeight"));
      }
    }
  };

  return (
    <div className="min-height tool">
      {props.deleteModal}
      <TextField 
        id="min-height-number" 
        className="min-height-input styling-input" 
        type="number" 
        variant="filled"
        InputLabelProps={{ shrink: true }}
        placeholder={minHeight + ""}
        value={minHeight} 
        onChange={(e) => setMinHeight(e.target.value)}
      />

      <TextField 
        className="min-height-unit styling-select" 
        select 
        variant="standard" 
        InputProps={{ disableUnderline: true }} 
        value={unit} 
        onChange={handleUnitChange}
      >
        {units.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default MinHeight;
