import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const BoxSizing = (props) => {

  const { blockId, screenType } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  }
  
  const currentBlockStyles = getCurrentBlockStyles()
  const currentBoxSizing = currentBlockStyles?.boxSizing;

  const boxSizingOptions = [
    { value: 'border-box', label: 'Border Box' },
    { value: 'content-box', label: 'Content Box' }
  ];

  // console.log('currentBlockStyles',currentBlockStyles)

  const [boxSizing, setBoxSizing] = useState(currentBoxSizing);

  const updateBoxSizing = (selectedBoxSizing) => {
    let payload = {
      id: blockId,
      value: selectedBoxSizing,
      screenType: props?.screenType
    }

    // console.log('selectedBoxSizing payload',payload)
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/boxSizing', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        console.log("updating box sizing payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update box sizing...", err);
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (boxSizing && boxSizing !== currentBoxSizing) {
        switch ( screenType ) {
          case "mobile" : 
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  mobileStyling: {
                    ...block.mobileStyling,
                    boxSizing: boxSizing
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "horizontal" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  horizontalStyling: {
                    ...block.horizontalStyling,
                    boxSizing: boxSizing
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          case "vertical" :
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  verticalStyling: {
                    ...block.verticalStyling,
                    boxSizing: boxSizing
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
          default:
            updateContentBlocks(contentBlocks?.map(block => {
              if (block.id === blockId) {
                return {
                  ...block,
                  styling: {
                    ...block.styling,
                    boxSizing: boxSizing
                  }
                };
                              }
              if (block.id === blockId ) {
                updateCurrentBlock(block)
              }
              return block;
            }));
          break;
        }

        updateBoxSizing(boxSizing)
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [blockId, boxSizing, currentBoxSizing]);

  return (
    <div className="box-sizing tool">
      {props.deleteModal}
      <TextField id="select-box-sizing" className="box-sizing-select styling-select" select variant="standard" InputProps={{ disableUnderline: true }} value={boxSizing} onChange={(e) => setBoxSizing(e.target.value)}>
        {boxSizingOptions?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export default BoxSizing;
