import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import AuthContext from "../../../../store/AuthContext";
import BuilderContext from '../../../../store/BuilderContext';

const BackgroundRepeat = (props) => {

  const { blockId, screenType } = props;
  const authCtx     = useContext(AuthContext);
  const builderCtx  = useContext(BuilderContext);

  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;


  const getCurrentBlockStyles = () => {
    switch ( screenType ) {
      case "mobile" :
        return currentContentBlock?.mobileStyling;
      break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
      break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
      break;
      default:
        return currentContentBlock?.styling;
      break;
    }
  }

  const currentBlockStyles = getCurrentBlockStyles()
  
  const currentBgRepeat = currentBlockStyles?.backgroundRepeat;

  // css image background-repeat property 
  const repeatVals = [
    { value: 'repeat',    label: 'repeat'    },
    { value: 'repeat-x',  label: 'repeat-x'  },
    { value: 'repeat-y',  label: 'repeat-y'  },
    { value: 'no-repeat', label: 'no-repeat' },
    { value: 'initial',   label: 'initial'   },
    { value: 'space',     label: 'space'     },
    { value: 'round',     label: 'round'     },
  ];

  const [bgRepeat, setBgRepeat] = useState(currentBgRepeat || "no-repeat");


  const handleChange = (e) => {
    setBgRepeat(e.target.value);
  }

  useEffect(() => {

    let timer;
    if (bgRepeat && bgRepeat !== currentBgRepeat) {
      timer = setTimeout(() => {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            let payload = {
              id: blockId,
              value: bgRepeat,
              screenType: props?.screenType
            }
            fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/backgroundRepeat', {
              method: 'PUT', mode: 'cors',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
              body: JSON.stringify(payload),
            })
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                switch (screenType) {
                  case "mobile":
                    return {
                      ...block,
                      mobileStyling: {
                        ...block.mobileStyling,
                        backgroundRepeat: bgRepeat 
                      }
                    };
                  case "horizontal":
                    return {
                      ...block,
                      horizontalStyling: {
                        ...block.horizontalStyling,
                        backgroundRepeat: bgRepeat 
                      }
                    };
                  case "vertical":
                    return {
                      ...block,
                      verticalStyling: {
                        ...block.verticalStyling,
                        backgroundRepeat: bgRepeat 
                      }
                    };
                  default:
                    return {
                      ...block,
                      styling: {
                        ...block.styling,
                        backgroundRepeat: bgRepeat 
                      }
                    };
                }              }
            }).catch((err) => {
              console.error("Failed to update backgroundRepeat...", err);
            });

            return { ...block, backgroundRepeat: bgRepeat };
          } else return block;
        }))
      }, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };

  }, [authCtx.token, blockId, bgRepeat]);



  // console.log("blockId", blockId);
  // console.log("bgRepeat", bgRepeat);

  return (
    <div className="backgroundimg tool">

      <div className="background-repeat">
        {props.deleteModal}
        <TextField className="position-unit styling-select" select variant="standard"
          InputProps={{ disableUnderline: true }}
          // placeholder={currentContentBlock?.styling?.backgroundRepeat + ""} 
          value={bgRepeat} onChange={handleChange} >
          {repeatVals?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>

    </div>
  )
}


export default BackgroundRepeat;


