import { MenuItem, TextField } from '@material-ui/core';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";
import { useContext, useEffect, useState } from "react";


export default function Padding(props) {
  const { blockId, screenType } = props;

  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock, updateCurrentBlock } = builderCtx;

  const getCurrentBlockStyles = () => {
    switch (screenType) {
      case "mobile":
        return currentContentBlock?.mobileStyling;
        break;
      case "horizontal":
        return currentContentBlock?.horizontalStyling;
        break;
      case "vertical":
        return currentContentBlock?.verticalStyling;
        break;
      default:
        return currentContentBlock?.styling;
        break;
    }
  }

  const separateValue = (input) => {
    const regex = /^(\d+)([a-zA-Z%]+)$/;
    const match = input?.match(regex);
    if (match) {
      return parseInt(match[1]);
    }
    else if (input === 'auto') {
      return 'auto';
    }

    return null;
  }

  const seperateUnit = (input) => {
    const unitRegex = /[a-z%]+$/i;

    const match = input?.match(unitRegex);

    if (match) {
      return match[0];
    }

    return "unset";
  }

  const [currentBlockStyles, setCurrentBlockStyles] = useState(getCurrentBlockStyles())
  const [paddingValues, setPaddingValues] = useState({ "pl": separateValue(currentBlockStyles?.paddingLeft), "pr": separateValue(currentBlockStyles?.paddingRight), "pt": separateValue(currentBlockStyles?.paddingTop), "pb": separateValue(currentBlockStyles?.paddingBottom) });
  const [units, setUnits] = useState({ "pl": seperateUnit(currentBlockStyles?.paddingLeft), "pr": seperateUnit(currentBlockStyles?.paddingRight), "pt": seperateUnit(currentBlockStyles?.paddingTop), "pb": seperateUnit(currentBlockStyles?.paddingBottom) });

  const handleValues = (e, type) => {
    const value = e.target.value === "" ? null : e.target.value;
    switch (type) {
      case "pl":
        if (value) {
          setPaddingValues({ ...paddingValues, pl: value });
          if ( units.pl === "auto" || units.pl === "unset" ) {
            updatePaddingInDb('paddingLeft', value, "px");
            setUnits({ ...units, pl: "px" });
          } else {
            updatePaddingInDb('paddingLeft', value, units.pl);
          }
        } else {
          setPaddingValues({ ...paddingValues, pl: "" });
          setUnits({ ...units, pl: "unset" });
          updatePaddingInDb('paddingLeft', "", "unset");
        }
        break;
      case "pr":
        if (value) {
          setPaddingValues({ ...paddingValues, pr: value });
          if ( units.pr === "auto" || units.pr === "unset" ) {
            updatePaddingInDb('paddingRight', value, "px");
            setUnits({ ...units, pr: "px" });
          } else {
            updatePaddingInDb('paddingRight', value, units.pr);
          }
        } else {
          setPaddingValues({ ...paddingValues, pr: "" });
          setUnits({ ...units, pr: "unset" });
          updatePaddingInDb('paddingRight', "", "unset");
        }
        break;
      case "pt":
        if (value) {
          setPaddingValues({ ...paddingValues, pt: value });
          if ( units.pt === "auto" || units.pt === "unset" ) {
            updatePaddingInDb('paddingTop', value, "px");
            setUnits({ ...units, pt: "px" });
          } else {
            updatePaddingInDb('paddingTop', value, units.pt);
          }
        } else {
          setPaddingValues({ ...paddingValues, pt: "" });
          setUnits({ ...units, pt: "unset" });
          updatePaddingInDb('paddingTop', "", "unset");
        }
        break;
      case "pb":
        if (value) {
          setPaddingValues({ ...paddingValues, pb: value });
          if (units.pb === "auto" || units.pb === "unset" ) {
            updatePaddingInDb('paddingBottom', value, "px");
            setUnits({ ...units, pb: "px" });
          } else {
            updatePaddingInDb('paddingBottom', value, units.pb);
          }
        } else {
          setPaddingValues({ ...paddingValues, pb: "" });
          setUnits({ ...units, pb: "unset" });
          updatePaddingInDb('paddingBottom', "", "unset");
        }
        break;
      default:
        break;
    }
  };

  const handleUnits = (e, type) => {
    const value = e.target.value;
    switch (type) {
      case "pl":
        if (value === 'auto') {
          setPaddingValues({ ...paddingValues, pl: "auto" });
          setUnits({ ...units, pl: "auto" });
          updatePaddingInDb('paddingLeft', "auto", "")
        }
        else if (value === 'unset') {
          setUnits({ ...units, pl: value });
          setPaddingValues({ ...paddingValues, pl: "" });
          updatePaddingInDb('paddingLeft', "unset", "")
        }
        else {
          if (paddingValues.pl === 'auto') {
            setUnits({ ...units, pl: value });
            setPaddingValues({ ...paddingValues, pl: "0" });
            updatePaddingInDb('paddingLeft', 0, value)
          }
          else {
            setUnits({ ...units, pl: value });
            updatePaddingInDb('paddingLeft', paddingValues.pl, value)
          }
        }
        break;
      case "pr":
        if (value === 'auto') {
          setPaddingValues({ ...paddingValues, pr: "auto" });
          setUnits({ ...units, pr: "auto" });
          updatePaddingInDb('paddingRight', "auto", "")
        }
        else if (value === 'unset') {
          setUnits({ ...units, pr: value });
          setPaddingValues({ ...paddingValues, pr: "" });
          updatePaddingInDb('paddingRight', "unset", "")
        }
        else {
          if (paddingValues.pr === 'auto') {
            setUnits({ ...units, pr: value });
            setPaddingValues({ ...paddingValues, pr: "0" });
            updatePaddingInDb('paddingRight', 0, value)
          }
          else {
            setUnits({ ...units, pr: value });
            updatePaddingInDb('paddingRight', paddingValues.pr, value)
          }
        }
        break;
      case "pt":
        if (value === 'auto') {
          setPaddingValues({ ...paddingValues, pt: "auto" });
          setUnits({ ...units, pt: "auto" });
          updatePaddingInDb('paddingTop', "auto", "")
        }
        else if (value === 'unset') {
          setUnits({ ...units, pt: value });
          setPaddingValues({ ...paddingValues, pt: "" });
          updatePaddingInDb('paddingTop', "unset", "")
        }
        else {
          if (paddingValues.pt === 'auto') {
            setUnits({ ...units, pt: value });
            setPaddingValues({ ...paddingValues, pt: "0" });
            updatePaddingInDb('paddingTop', 0, value)
          }
          else {
            setUnits({ ...units, pt: value });
            updatePaddingInDb('paddingTop', paddingValues.pt, value)
          }
        }
        break;
      case "pb":
        if (value === 'auto') {
          setPaddingValues({ ...paddingValues, pb: "auto" });
          setUnits({ ...units, pb: "auto" });
          updatePaddingInDb('paddingBottom', "auto", "")
        }
        else if (value === 'unset') {
          setUnits({ ...units, pb: value });
          setPaddingValues({ ...paddingValues, pb: "" });
          updatePaddingInDb('paddingBottom', "unset", "")
        }
        else {
          if (paddingValues.pb === 'auto') {
            setUnits({ ...units, pb: value });
            setPaddingValues({ ...paddingValues, pb: "0" });
            updatePaddingInDb('paddingBottom', 0, value)
          }
          else {
            setUnits({ ...units, pb: value });
            updatePaddingInDb('paddingBottom', paddingValues.pb, value)
          }
        }
        break;
    }
  };


  const updatePaddingInDb = (type, value, unit) => {

    let payload = {
      id: blockId,
      value: value + unit,
      screenType: screenType
    }

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/' + [type], {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Updating padding payload", payload);
        }
      }).catch((err) => {
        console.error("Failed to update padding...", err);
      });

    switch (screenType) {
      case "mobile":
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              mobileStyling: {
                ...block.mobileStyling,
                [type]: value + unit
              }
            };
          }
          if (block.id === blockId) {
            updateCurrentBlock(block)
          }
          return block;
        }));
        break;
      case "horizontal":
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              horizontalStyling: {
                ...block.horizontalStyling,
                [type]: value + unit
              }
            };
          }
          if (block.id === blockId) {
            updateCurrentBlock(block)
          }
          return block;
        }));
        break;
      case "vertical":
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              verticalStyling: {
                ...block.verticalStyling,
                [type]: value + unit
              }
            };
          }
          if (block.id === blockId) {
            updateCurrentBlock(block)
          }
          return block;
        }));
        break;
      default:
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return {
              ...block,
              styling: {
                ...block.styling,
                [type]: value + unit
              }
            };
          }
          if (block.id === blockId) {
            updateCurrentBlock(block)
          }
          return block;
        }));
        break;
    }

  }

  useEffect(() => {
    setCurrentBlockStyles(getCurrentBlockStyles())
  }, [units, paddingValues, currentContentBlock])

  useEffect(() => {
    setPaddingValues({ "pl": separateValue(currentBlockStyles?.paddingLeft), "pr": separateValue(currentBlockStyles?.paddingRight), "pt": separateValue(currentBlockStyles?.paddingTop), "pb": separateValue(currentBlockStyles?.paddingBottom) })
    setUnits({ "pl": seperateUnit(currentBlockStyles?.paddingLeft), "pr": seperateUnit(currentBlockStyles?.paddingRight), "pt": seperateUnit(currentBlockStyles?.paddingTop), "pb": seperateUnit(currentBlockStyles?.paddingBottom) })
  }, [currentBlockStyles])


  const paddingInput = () => {
    return <div className="padding-input-container">
      <div className="pt-input padding-input">
        <div className="input-heading">Top</div>
        <div className="input-container">
          <input type="number" value={paddingValues.pt} placeholder={paddingValues.pt} onChange={(e) => {
            handleValues(e, 'pt')
          }} />
          <TextField className="padding-unit styling-select" select variant="standard" onChange={(e) => { handleUnits(e, "pt") }} InputProps={{ disableUnderline: true }} placeholder={units.pt} value={units.pt}>
            <MenuItem value={'px'}>px</MenuItem>
            <MenuItem value={'rem'}>rem</MenuItem>
            <MenuItem value={'%'}>%</MenuItem>
            <MenuItem value={'vh'}>vh</MenuItem>
            <MenuItem value={'vw'}>vw</MenuItem>
            <MenuItem value={'auto'}>auto</MenuItem>
            <MenuItem value={'unset'}>unset</MenuItem>
          </TextField>
        </div>
      </div>
      <div className="pr-input padding-input">
        <div className="input-heading">Right</div>
        <div className="input-container">
          <input type="number" value={paddingValues.pr} placeholder={paddingValues.pr} onChange={(e) => {
            handleValues(e, 'pr')
          }} />
          <TextField className="padding-unit styling-select" select variant="standard" onChange={(e) => { handleUnits(e, 'pr') }} InputProps={{ disableUnderline: true }} placeholder={units.pr} value={units.pr}>
            <MenuItem value={'px'}>px</MenuItem>
            <MenuItem value={'rem'}>rem</MenuItem>
            <MenuItem value={'%'}>%</MenuItem>
            <MenuItem value={'vh'}>vh</MenuItem>
            <MenuItem value={'vw'}>vw</MenuItem>
            <MenuItem value={'auto'}>auto</MenuItem>
            <MenuItem value={'unset'}>unset</MenuItem>
          </TextField>
        </div>
      </div>
      <div className="pb-input padding-input">
        <div className="input-heading">Bottom</div>
        <div className="input-container">
          <input type="number" value={paddingValues.pb} placeholder={paddingValues.pb} onChange={(e) => {
            handleValues(e, 'pb')
          }} />
          <TextField className="padding-unit styling-select" select variant="standard" onChange={(e) => { handleUnits(e, 'pb') }} InputProps={{ disableUnderline: true }} placeholder={units.pb} value={units.pb}>
            <MenuItem value={'px'}>px</MenuItem>
            <MenuItem value={'rem'}>rem</MenuItem>
            <MenuItem value={'%'}>%</MenuItem>
            <MenuItem value={'vh'}>vh</MenuItem>
            <MenuItem value={'vw'}>vw</MenuItem>
            <MenuItem value={'auto'}>auto</MenuItem>
            <MenuItem value={'unset'}>unset</MenuItem>
          </TextField>
        </div>
      </div>
      <div className="pl-input padding-input">
        <div className="input-heading">Left</div>
        <div className="input-container">
          <input type="number" value={paddingValues.pl} placeholder={paddingValues.pl} onChange={(e) => {
            handleValues(e, 'pl')
          }} />
          <TextField className="padding-unit styling-select" select variant="standard" onChange={(e) => { handleUnits(e, "pl") }} InputProps={{ disableUnderline: true }} placeholder={units.pl} value={units.pl}>
            <MenuItem value={'px'}>px</MenuItem>
            <MenuItem value={'rem'}>rem</MenuItem>
            <MenuItem value={'%'}>%</MenuItem>
            <MenuItem value={'vh'}>vh</MenuItem>
            <MenuItem value={'vw'}>vw</MenuItem>
            <MenuItem value={'auto'}>auto</MenuItem>
            <MenuItem value={'unset'}>unset</MenuItem>
          </TextField>
        </div>
      </div>
    </div>
  }

  return (
    <div className='tool'>
      {props.deleteModal}
      {paddingInput()}
    </div>
  )
}